@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.navigation {
  background-color: #EFE4E4;
  width: 100%;
  position: relative;
  z-index: 0;
}

.fullWidthNavigation {
  composes: navigation;

  .container {
    max-width: none;
  }
}

.subNavigation {
  composes: fullWidthNavigation;
  background-color: white;

  @media (min-width: $widthDesktop) {
    background-color: unset;
  }

  padding-bottom: 0 !important;

  .container {
    background-color: white;

    &:after {
      content: '';
      border-bottom: 3px solid #e2e2e2e3;
      width: 100%;
      display: block;
      position: absolute;
      bottom: 0px;
      z-index: 1;

      //height: 0.5rem;
      //display: block;
      //width: 100%;
      //border-bottom: 4px solid #E2E2E2;
      //content: "";
      //bottom: 0px;

      //@include mobileLandscape {
      //  //bottom: -0.2rem;
      //}
    }
  }
}

.container {
  max-width: 100%;
  padding-top: 0;
  margin: 0 auto;
  height: 100%;
}