.label {
  float: left;
  font-size: 0.7rem;
  color: $colour-shade-2;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05rem;
  margin-bottom: 0.25rem;
}

.input {
  border-radius: 0;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $colour-495057;
  background-color: $colour-shade-0;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s;
}

.button {
  background-color: $colour-2f5cff;
  width: 100%;
  border-radius: 0;
  padding: 0.75rem;
  font-weight: 600;
  margin-top: 0.5rem;
}

.h1 {
  text-align: left;
}

.close {
  position: absolute;
  right: 10px;
  top: 5px;
}
