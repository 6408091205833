.multiselectItem {
    background: #A3E9FF;
    color: #fff;
    padding: 8px 18px;
    border-radius: 50%;
    font-size: 30px;
    text-align: center;
    cursor: pointer;
    
    &.selected {
        background-color: #84B36E;
    }

    &.disabled {
        cursor: not-allowed;
    }
}

.text {
    margin-top: .5rem;
    font-size: 12px;
    text-align: center;
}

.itemContainer {
    text-align: center;
}