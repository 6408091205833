@import '../../../global/stylesheets/colours';
@import '../../../global/stylesheets/mediaConfig';

.container {
    min-height: 100vh;
    width: 100%;

    .infoBox {
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 2px solid #707070;
        border-radius: 7px;
        position: relative;
        max-width: 80%;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
        text-align: left;
        padding: 1rem;

        ul {
            list-style: none;
            margin: 0;
            margin-block-start: 0;
            margin-block-end: 0;
            margin-inline-start: 0;
            margin-inline-end: 0;
            padding-inline-start: 0;

            li {
                font-size: 1rem;

                img {
                    margin-right: 0.25rem;
                }
            }
        }
    }

    .eLeft {
        width: 40%;
        position: fixed;
        left: 0;
        top: 0;
        background: url('../../../images/account/account.image.png');
        background-size: cover;
        height: 100%;
        display: none;

        @include desktop {
            display: block;
        }
    }

    h1 {
        font-size: 2rem;
        text-transform: none;
        margin-bottom: 0.75rem;
        text-align: center;

        @include tablet {
            font-size: 2rem;
        }
    }

    h2 {
        font-size: 1.25rem;
        text-transform: none;
        margin: 0 auto;
        margin-bottom: 0.75rem;
        text-align: center;

        @include tablet {
            font-size: 18px;
            max-width: 80%;
        }
    }
}

.logo {
    margin-bottom: 1rem;
    height: 4rem;
    text-align: center;
}

.row {
    position: relative;
    display: flex;
    height: 100%;
    align-items: stretch;

    .column {
        flex: auto;
        align-self: center;
    }

    .left,
    .right {
        align-self: center;
    }

    .left {
        height: 100%;
        flex: 0 40%;
        display: none;

        @include desktop {
            display: block;
        }
    }

    .right {
        flex: 60%;
        margin: 1rem;

        > div {
            @include tablet {
                max-width: 70%;
                margin: 0 auto;
            }

            @include fullHD {
                max-width: 50%;
            }
        }
    }
}

.mainRow {
    composes: row;
    min-height: 100vh !important;
}
