@import "../../../../global/stylesheets/colours";

.generalPage {
  background-color: $colour-shade-0;
  padding: 1rem 2rem 0 1rem;
  margin-bottom: 1rem;
}

.list {
  padding-top: 1rem;
}

.addIcon {
  margin-right: 0.5rem;
  float: right;
}
