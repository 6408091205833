@import "../../../global/stylesheets/colours";

.page {
  color: $colour-shade-5;
}

.right {
  min-height: 100vh;
  width: 55%;
  background-color: $colour-shade-5;
  float: left;
  background-image: url("../../../images/rent-chief-login-signin-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-position-y: 0;

  @media (max-width: 500px) {
    display: none;
  }
}
.left {
  @media (max-width: 500px) {
    width: 100%;
  }

  min-height: 100vh;
  width: 45%;
  background-color: $colour-shade-0;
  float: left;
  color: $colour-shade-0;
  font-family: sans-serif;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;

  align-items: center;
}
.flexContainer {
  width: 70%;
  margin: 0 15%;
  text-align: center;
}
h1 {
  font-size: 26px;
  margin-bottom: 40px;
  text-transform: uppercase;
}
p {
  font-size: 15px;
  line-height: 28px;
  color: $colour-shade-5;
}

.logo {
  display: none;

  @media (max-width: 500px) {
    display: block;
    margin: 0 auto;
    margin-bottom: 2rem;
  }
}

.activated,
.error {
  button {
    float: none;
    margin-left: 0;
    margin-top: 1rem;
  }
}
