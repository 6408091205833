@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.iframe {
  iframe {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    border: none;
  }
}