@import '../../../global/stylesheets/mediaConfig';
@import '../../../global/stylesheets/colours';

.form {
    color: $colour-shade-5;
    text-align: left;
    margin: 0 -15px;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column-reverse;
    padding: 0 2rem;

    @include tablet {
        flex-direction: row;
        padding: 0;
    }

    .content {
        padding: 0 15px;
        flex: auto;
        padding: 1rem;
        background-color: white;
    }

    .image {
        padding: 15px;
        flex: 0 30%;
    }
}

.errorMessage {
    color: red !important;
    font-size: 0.85rem;
    text-align: left;
}

textarea {
    height: auto !important;
}

textarea,
.input {
    border-radius: 5px;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $colour-shade-4;
    margin-bottom: 0.4rem;
    background-color: $color-input-bg;
    background-clip: padding-box;
    border: 1px solid #f0f3ff;
    transition: border-color 0.15s;
    @media (max-width: $max-widthPhone) {
        width: 100%;
    }

    .input {
        height: calc(1.5em + 0.75rem + 2px);
    }

    &::placeholder {
        color: $colour-shade-2;
    }
}

:global {
    .react-datepicker-wrapper {
        width: 100%;
    }
}

.row {
    display: flex;
    flex-direction: column;
    margin: 0 -15px;

    @include tablet {
        margin-bottom: 0.4rem;
    }

    @include desktop {
        flex-direction: row;
    }
}

.column {
    flex: 0 100%;
    margin-bottom: 0.5rem;

    @include tablet {
        padding: 0 15px;
        flex: 0%;
        margin: 0;
    }
}

.columnHalf {
    flex: 0 100%;
    padding: 0 15px;
    margin-bottom: 0.5rem;

    @include tablet {
        flex: 0 50%;
        margin: 0;
    }
}

.columnSmall {
    flex: 0 28%;
}

.label {
    line-height: 0.7rem;
    font-size: 0.7rem;
    color: $colour-shade-2;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.05rem;
    margin-bottom: 0.25rem;
    display: block;
    width: 100%;
}

.btn {
    border-radius: 300px;
    border: none;
    font-weight: bold;
    margin: 0 0.4rem;
    padding: 0.4rem;
    width: 8rem;

    &.cancel {
        color: #3c85ca;
        border: 1px solid #3c85ca;
        background-color: white;
    }

    &.save {
        background-color: #3c85ca;
        color: white;
        border: #3c85ca solid 1px;
    }
}

.buttons {
    display: flex;
    justify-content: center;
    font-size: smaller;

    > div {
        width: 100%;
    }

    @include tablet {
        justify-content: flex-end;
    }

    .btnContainer {
        flex: 0 0 auto;

        @include tablet {
            flex: 0 0 auto;
        }
    }

    @include mobileLandscape {
        font-size: 0.65rem;
    }

    @include tablet {
        font-size: 0.7rem;
    }

    @include desktop {
        font-size: 0.8rem;
    }

    @include fullHD {
        font-size: 1rem;
    }
}

.modalBodyOutstandingChanges {
    color: $colour-shade-5;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.modalHeader {
    color: $colour-shade-2;
    font-weight: 500;
    margin-bottom: 0px !important;
}

.imageUploader {
    background-color: #fefefe;
}

.documents {
    display: flex;
    background-color: white;
    padding: 1rem 0;
    justify-content: space-between;

    @include tablet {
        padding: 1rem;
    }

    .document {
        padding: 0 5px;
        flex: 0 0 auto;
    }
}
