@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.addContact {
  color: $colour-blue-0;
  font-weight: bold;
  padding-left: 5px;
    
  &:hover {
    cursor: pointer;
    background-color: rgba(128, 128, 128, 0.13);
  }
}

.addButton {
  a {
    text-decoration: none;
  }

  text-align: center;
  padding: .2rem 0.5rem;
  border-radius: 300px;
  border: none;
  background-color: transparent;
  color: $colour-blue-0;
  width: auto;

  span {
    display: none;

    @include tablet {
      display: inline-block;
    }
  }

  &:hover {
    cursor: pointer;
  }
}