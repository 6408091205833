@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.councilInformation {
  display: inline-block;
  margin-left: .4rem;
}

  .tooltipContent {
    font-size: 0.8rem;
    text-align: left;
  
    .councilHeader {
      margin-bottom: 0.5rem;
    }
  
    h1 {
      font-size: 1rem;
    }
  
    h2 {
      font-size: 1.2rem;
      color: $colour-shade-4;
    }
  
    p {
      font-size: 0.8rem;
      color: $colour-shade-4;
      font-weight: 500;
      line-height: 0.75rem;
    }
  
    .links {
      display: flex;
      flex-direction: column;
      padding: 0.5rem;
  
      img {
        margin-right: 1rem;
      }
    }
  
    .gasIcon {
      height: 3rem;
    }

    .infoIcon {
      &:hover {
        cursor: pointer;
      }
    }
  }