@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.modalBodyOutstandingChanges {
  color: $colour-shade-5;
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.modalHeader {
  color: $colour-shade-2;
  font-weight: 500;
  margin-bottom: 0px !important;
}