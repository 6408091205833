@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/mediaConfig";

.modal {
  max-width: 100%;
}

.overlay {
  background: rgba(0, 0, 0, 0.25);
}

.h1 {
  color: $colour-shade-4;
  margin-bottom: 1rem !important;
  border-bottom: 1px solid $colour-shade-4;
  padding-bottom: 0.4rem;
  font-size: 1.4rem;
}

.addDocumentContent {
  width: 100%;

  @include desktop {
    width: 47.5rem;
  }
}

.form {
  margin: 0 auto;
  min-width: 70rem;
}

.label {
  text-align: left;
  font-size: 1rem;
  color: $colour-shade-5;
  margin-bottom: 0.25rem;
  width: 100%;
  letter-spacing: 0;
}

.title {
  text-align: left;
}

.tooBig {
  color: $colour-error;
}

.tooBig {
  opacity: 0.65;
}

.rotating {
  padding: 2rem;
  text-align: center;
}

.fileType {
  background-color: $colour-shade-0;
  color: $colour-shade-5;
  width: 100%;
  padding: 0.25rem;
}

.clearIcon {
  top: 0;
  right: 0;
  float: right;
  margin-right: 0 !important;
  padding-right: 0;

  &:hover {
    cursor: pointer;
  }
}

.fileList {
  padding-top: 0;
  margin-top: 0;
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0px;
  padding-inline-start: 0;
  width: 100%;

  align-items: center;
  border-width: 2px;
  border-radius: 2px;
  border-color: $colour-0c54602e;
  border-style: dashed;
  background-color: $colour-e4e4e4;
  color: $colour-shade-5;
  outline: none;
  transition: border 0.24s ease-in-out;

  img {
    max-width: 4rem;
    margin-right: 1rem;
  }

  span {
    img {
      padding-left: 1rem;
    }
  }

  li {
    margin: 0.5rem;
    font-size: 0.8rem;
    background-color: $colour-shade-0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

    svg {
      margin: 0;
      width: 25px;
      height: 25px;
      display: inline-block;
      margin-left: 0.25rem;
    }
  }
}

.formInputs {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0.5rem 1rem;
  padding: 0.5rem 0;
  flex-wrap: wrap;

  @include desktop {
    flex-wrap: nowrap;

    div {
      margin: auto 0;
    }
  }
}

.formInputChildSmall {
  flex-basis: 15%;

  @include desktop {
    flex: 0.5;
  }
}

.formInputChildLarge {
  flex-basis: 85%;

  @include desktop {
    flex: 3;
  }
}

.formFileLogo {
  img {
    @include square(2rem);
  }
}

.formInputFileName {
  font-size: 1rem;
  color: $colour-shade-4;
}

.documentType {
  margin: 0 !important;
}

.input {
  width: 100%;
  border-radius: 5px;
  background-color: $colour-shade-0;
  color: black;
  border: 1px solid #f0f3ff;
}

.addressFindButton:hover {
  cursor: pointer;
}

.button {
  background-color: $colour-blue-0;
  width: 100%;
  border-radius: 0;
  padding: 0.75rem;
  font-weight: 600;
  margin-top: 0.5rem;
}

.text {
  text-align: center;
  margin: 0 auto;
  color: $colour-shade-2;
  font-size: 0.85rem;
  margin-top: 1rem;
  justify-content: center;
}

.error {
  padding: 0.65rem;
  border: 1px solid $colour-error;
  margin-bottom: 1rem;
  color: $colour-error;
}

.loggedIn {
  word-break: break-all;
  color: $colour-shade-5;
}

.p {
  color: $colour-shade-3;
  text-align: left;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.link {
  color: $colour-blue-0;
}

.link:hover {
  cursor: pointer;
}

.emailExists {
  color: $colour-error;
  padding-top: 0.25rem;
}

.h2 {
  color: $colour-shade-3;
}

.addressToggle {
  text-align: right;
  color: $colour-blue-0;
  font-size: 0.75em;
}

.addressToggle:hover {
  cursor: pointer;
  font-weight: bold;
}

.sliderInput {
  padding: 0.5rem;
}

.inputAddress {
  composes: input;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.findAddress {
  color: $colour-shade-5;
}

.text {
  padding-top: 2rem;
  color: $colour-shade-5;
}

.inputHolder {
  position: relative;
}

.dropdown {
  position: absolute;
  background-color: $colour-shade-0;
  color: $colour-shade-5;
  max-height: 14em;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 99999999999999999999;
}

.input,
.submit {
  display: inline-block;
  border-radius: 0;
  -webkit-appearance: none;
}

.addressInput {
  composes: input;
  width: 80%;
  display: inline-block;
}

.selectedAddress {
  composes: input;
  width: 100%;
  display: inline-block;
}

.addressFindButton {
  composes: input;
  width: 20%;
  display: inline-block;
  background-color: $colour-2ecc71;
  color: $colour-shade-0;
  border: 0;
}

.addressResultsDropdown {
  composes: dropdown;
  color: $colour-shade-5;
  font-size: 0.8rem;
  text-align: left;
  overflow-y: scroll;
  box-shadow: 0 4px 8px 0 $colour-shade-5;
}

.addressResultsDropdown ul {
  list-style: none;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.addressResultsDropdown ul li {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.4rem;
  margin-left: 0;
  font-size: 0.8rem;
}

.addressResultsDropdown ul li:hover {
  background-color: $colour-shade-0;
  cursor: pointer;
}

.emailExists {
  color: $colour-error;
  padding-top: 0.25rem;
}

.close {
  position: absolute;
  top: -25px;
  right: 0px;
}

.sticky {
  position: sticky;
  bottom: 0;
  height: 2.5rem;
  width: 100%;
}

.submitButton {
  display: flex;
  justify-content: center;
}

.addUserIcon {
  margin-right: 0.5rem;
}

.label {
  color: $colour-b0bac9;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.8rem;

  &:hover {
    background-color: none !important;
  }
}

.associationContainer {
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: $colour-0c54602e;
  border-style: dashed;
  background-color: $colour-e4e4e4;

  .search {
    margin-bottom: 1rem;
    background-color: $colour-shade-0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px 0px;
    border-radius: 5px;

    input {
      padding: 0.8rem;
    }

    div,
    input,
    button {
      margin: 0;
      border: none;
    }

    div {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.input {
  width: 90%;
  border-radius: 5px;
  background-color: $colour-shade-0;
  color: black;
  border: 1px solid #f0f3ff;
  padding: 0.5rem;
}

.success {
  p {
    color: black;
  }
}

.list {
  background-color: white;
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(128, 128, 128, 0.13);
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
  position: absolute;
  z-index: 1;
  width: auto;
  min-width: 10rem;
  height: 14rem;
  overflow: auto;

  li {
    border-bottom: 1px solid rgba(128, 128, 128, 0.13);
    padding: 0.4rem;

    &:hover {
      cursor: pointer;
      background-color: rgba(128, 128, 128, 0.13);
    }
  }
}

.results {
  list-style: none;
  background-color: white;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
  padding: 1rem;

  li {
    margin-bottom: 0.4rem;
    border-bottom: 1px solid grey;

    &:hover {
      background-color: grey;
    }
  }
}

.success {
  text-align: center;
  color: black;
}
