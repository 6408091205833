@import "../../../../../global/stylesheets/mediaConfig";
@import "../../../../../global/stylesheets/colours";

.noDocuments {
  button {
    float: none;
    display: block;
    margin: 0 auto;
    margin-top: 1rem;
    width: 25%;
  }
}

iframe {
  height: 100vh;
}

.heading {
  text-align: left;
  padding-bottom: 1rem;
}

.backIcon {
  max-width: 1rem;
}

.label {
  text-align: left;
  font-size: 0.7rem;
  color: $colour-shade-2;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05rem;
  margin-bottom: 0.25rem;
  width: 100%;
}

.name {
  display: block;
}

.page {
  background-color: $colour-shade-0;
  padding: 2rem;
  .addButton {
    button {
      float: none !important;
      width: auto !important;
      padding: 0.65rem !important;
      margin-bottom: 1rem !important;

      @media (max-width: $max-widthPhone) {
        display: block !important;
        margin-left: auto !important;
        margin-right: auto !important;
        width: 16.5rem !important;
        padding: 0.65rem !important;
        padding-bottom: 2.5rem !important;
        margin-bottom: 1rem !important;
      }
    }
  }
}
