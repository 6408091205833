@import "../../../../global/stylesheets/mediaConfig";
@import "../../../../global/stylesheets/colours";

.valuationPopup {
  max-width: 15rem;

p{
  color: black;

  img {
    display: block;
    width: 8rem;
  }
}
}