@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/styling";

.subText {
  color: $colour-blue-0;
  font-size: 1.0rem;
  line-height: 0.8rem;
}

.value {
  color: #2e384d;
  font-size: 1.2rem;
}


