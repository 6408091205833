@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.plus {
  font-size: 2rem;
  display: inline-block;
}

.addProperty {
  border-radius: 300px !important;
  border: 1px solid $colour-blue-0 !important;
  color: $colour-blue-0 !important;
  background-color: $colour-shade-0;
  border: none;
  border-radius: 6px;

  @media (max-width: $max-widthPhone) {
    border: none !important;
  }

  &:hover {
    background-color: $colour-blue-0 !important;
    color: $colour-shade-0 !important;

    .addPropertyText {
      color: $colour-shade-0 !important;
    }
  }

  .addPropertyText {
    color: $colour-blue-0 !important;
    border: none;
    margin: 0;
    padding-right: 1rem;
  }
}

.addPropertySmall {
  composes: addProperty;
  width: 2.25rem;
  padding-right: 0;

  svg {
    width: 1rem;
    height: 1rem;
  }
}

.addPropertyIcon {
  margin-right: -1.2rem !important;
  margin-left: -1rem;
  @media (max-width: $max-widthPhoneSamasungGalaxy) {
    padding: 0.4rem 0 0.4rem 0 !important;
    margin: 0rem !important;
  }
  img {
    margin: 0;
    padding: 0;
  }
}

.addPropertyText {
  font-size: 1.2rem;
  color: $colour-blue-0;
  padding: 0.65rem;
  padding-right: 0rem;
  @media (max-width: $max-widthPhone) {
    display: none;

    svg {
      display: block;
    }
  }
}

.addPropertyTextFixed {
  composes: addPropertyText;

  @media (max-width: $max-widthPhone) {
    display: inline;

    svg {
      display: inline;
    }
  }
}
