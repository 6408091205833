@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/styling";

.syncfusionDropdown {
  background-color: transparent !important;
  padding: 0 !important;
  margin: 0 !important;
  font: inherit;

  ul {
    background-color: white !important;
  }
}