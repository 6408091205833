@import "../../../../global/stylesheets/mediaConfig";
@import "../../../../global/stylesheets/colours";

.sortDropdown {
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: $colour-e4e4e4;
  color: $colour-shade-5;
  margin-left: 15px;
  margin-right: 15px;

  .close {
    float: right;
    font-size: 0.85rem;

    &:hover {
      font-weight: 600;
      cursor: pointer;
    }
  }

  .title {
    font-size: 1rem;
    color: $colour-707070;
  }

  .marginBottom {
    margin-bottom: 1rem;
  }

  ul {
    list-style: none;
    li.active {
      color: $colour-blue-0;
    }

    li {
      display: inline;
      padding: 1rem;
    }
  }
}

.sortByIconModel {
  float: left;
  padding-top: 0.2rem;
}
.filterWindow {
  @media (max-width: $max-widthTablet) {
    text-align: left;
    padding-left: 2rem;
  }
}
