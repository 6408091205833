@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/mediaConfig";


.name {
  width: auto;
  min-width: 12vw;
  text-align: left;
  font-size: 1.5rem;
  display: flex;
  margin-bottom: 1rem;

  .column {
    flex: 100%;
    align-self: center;
  }

  .image {
    flex: 0 15%;
    
    img {
      max-width: 5rem;
    }
  }
}

.row {
  display: flex;

  .column {
    flex: 0 50%;
  }
}

.blueText {
  color: blue;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
}

.tooltip {
  display: inline !important;
  z-index: 2;
}

.link {
  &:hover {
    cursor: pointer;
  }
}

.tooltipBody {
  padding: .25rem;
  margin: .5rem;
  text-align: left;
  p {
    color: $colour-shade-5 !important;
    margin-bottom: 0;
    display: block;
  }
}

.nameText {
  margin-left: 1rem;
  &:hover {
    cursor: pointer;
  }
}

.action {
  text-align: right;

  p {
    color: #0085ca !important;

    &:hover {
      cursor: pointer;
    }
  }
}

.small {
  margin: 0;
  display: block;
  font-size: 0.65rem;
  line-height: 0.65rem;
}

.button {
  line-height: 0;
  width: 30%;
  height: 25px;
  font-weight: bold;
  color: white;
  background: #0085ca 0% 0% no-repeat padding-box;
  border-radius: 20px;
  border: none;
  font-size: .8rem;
  text-align: center;

  &:hover {
    background-color: gray;
    cursor: pointer;
  }
}