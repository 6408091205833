@import '../../../global/stylesheets/mediaConfig';
@import '../../../global/stylesheets/colours';

.sticky {
    position: absolute;
}

.stickyMobile {
    position: fixed;
    z-index: 99999999;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: 10px;
    background-color: white;

    @include tablet {
        right: 0;
        justify-content: flex-end;
    }
}
