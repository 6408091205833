@import "../../../global/stylesheets/colours";

.pureMaterialCheckbox {
  z-index: 0;
  position: relative;
  display: inline-block;
  color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
  font-family: var(
    --pure-material-font,
    "Roboto",
    "Segoe UI",
    BlinkMacSystemFont,
    system-ui,
    -apple-system
  );
  font-size: 16px;
  line-height: 1.5;
  margin-top: 1rem;
  margin-bottom: 1rem;

  input[type=checkbox] + label{
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -o-user-select:none;
    user-select:none;
}
}

/* Input */
.pureMaterialCheckbox > input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  z-index: -1;
  position: absolute;
  left: -10px;
  top: -8px;
  display: block;
  margin: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
  box-shadow: none;
  outline: none;
  opacity: 0;
  transform: scale(1);
  pointer-events: none;
  transition: opacity 0.3s, transform 0.2s;
}

/* Span */
.pureMaterialCheckbox > span {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  font-size: 0.95rem;
  white-space: nowrap;
}

/* Box */
.pureMaterialCheckbox > span::before {
  content: "";
  display: inline-block;
  box-sizing: border-box;
  margin: 3px 11px 3px 1px;
  border: solid 2px; /* Safari */
  border-color: $colour-e4e4e4;
  border-radius: 2px;
  width: 18px;
  height: 18px;
  vertical-align: top;
  transition: border-color 0.2s, background-color 0.2s;
}

.pureMaterialCheckbox > .rightSpan::before {
  content: "";
  box-sizing: border-box;
  margin: 3px 11px 3px 1px;
  border: solid 2px; /* Safari */
  border-color: $colour-e4e4e4;
  border-radius: 2px;
  width: 18px;
  height: 18px;
  vertical-align: top;
  transition: border-color 0.2s, background-color 0.2s;
  float: right;
  margin-left: 0.6rem;
}

/* Checkmark */
.pureMaterialCheckbox > span::after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 1px;
  width: 10px;
  height: 5px;
  border: solid 2px transparent;
  border-right: none;
  border-top: none;
  transform: translate(3px, 4px) rotate(-45deg);
}

/* Checked, Indeterminate */
.pureMaterialCheckbox > input:checked,
.pureMaterialCheckbox > input:indeterminate {
  background-color: rgb(33, 150, 243);
}

.pureMaterialCheckbox > input:checked + span::before,
.pureMaterialCheckbox > input:indeterminate + span::before {
  border-color: rgb(33, 150, 243);
  background-color: rgb(33, 150, 243);
}

.pureMaterialCheckbox > input:checked + span::after,
.pureMaterialCheckbox > input:indeterminate + span::after {
  border-color: rgb(255, 255, 255);
}

.pureMaterialCheckbox > input:indeterminate + span::after {
  border-left: none;
  transform: translate(4px, 3px);
}

/* Hover, Focus */
.pureMaterialCheckbox:hover > input {
  opacity: 0.04;
}

.pureMaterialCheckbox > input:focus {
  opacity: 0.12;
}

.pureMaterialCheckbox:hover > input:focus {
  opacity: 0.16;
}

/* Active */
.pureMaterialCheckbox > input:active {
  opacity: 1;
  transform: scale(0);
  transition: transform 0s, opacity 0s;
}

.pureMaterialCheckbox > input:active + span::before {
  border-color: rgb(33, 150, 243);
}

.pureMaterialCheckbox > input:checked:active + span::before {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.6);
}

/* Disabled */
.pureMaterialCheckbox > input:disabled {
  opacity: 0;
}

.pureMaterialCheckbox > input:disabled + span {
  color: rgba(0, 0, 0, 0.38);
  cursor: initial;
}

.pureMaterialCheckbox > input:disabled + span::before {
  border-color: currentColor;
}

.pureMaterialCheckbox > input:checked:disabled + span::before,
.pureMaterialCheckbox > input:indeterminate:disabled + span::before {
  border-color: transparent;
  background-color: currentColor;
}
