@import '../../../global/stylesheets/colours';
@import '../../../global/stylesheets/styling';

$bg-color: #424242;
$hl-color: #2196f3;
$muted-color: mix($colour-shade-0, $bg-color, 65%);
$trans-time: 300ms;
$width: 100%;

.p {
    color: black;
}

.formHeading {
    font-size: 2rem;
    text-transform: none;

    @include tablet {
        font-size: 40px;
    }
}

.form {
    margin: 0 auto;

    form {
        h5 {
            text-align: center;
            text-transform: uppercase;
            color: $muted-color;
        }
        hr.sep {
            background: $hl-color;
            box-shadow: none;
            border: none;
            height: 2px;
            width: 25%;
            margin: 0px auto 45px auto;
        }
        .emoji {
            font-size: 1.2em;
        }
    }

    .group {
        position: relative;
        margin: 1rem 0;
        margin-top: 0;

        &:last-of-type {
            margin-bottom: 0 !important;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px $colour-shade-0 inset !important;
        }

        textarea,
        .input {
            border-radius: 5px;
            display: block;
            width: 100%;
            height: calc(1.5em + 0.75rem + 2px);
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: $colour-495057;
            margin-bottom: 0.4rem;
            background-color: $color-input-bg;
            background-clip: padding-box;
            border: 1px solid #d5deff;
            transition: border-color 0.15s;
            @media (max-width: $max-widthPhone) {
                width: 100%;
            }

            &::placeholder {
                color: $colour-b0bac9;
            }
        }
    }
}

.label {
    line-height: 0.7rem;
    text-align: left;
    font-size: 0.7rem;
    color: $colour-shade-2;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.05rem;
    margin-bottom: 0.25rem;
    display: block;
    width: 100%;
}

.logo {
    margin-bottom: 1rem;
    height: 4rem;
}

.buttonContainer {
    width: 100%;
    margin: 0 auto;

    @include tablet {
        width: 80%;
    }
}

.keepMeLoggedIn {
    text-align: left;
}

.button {
    background-color: $colour-blue-0;
    color: $colour-shade-0;
    border: 1px solid $colour-blue-0;
    width: 100%;
    border-radius: 300px;
    padding: 0.55rem;
    font-weight: 600;
    font-size: 1.125rem;
}

.text {
    margin: 0 auto;
    //color: $colour-shade-2;
    //font-size: 0.7rem;
    margin-top: 1rem;
    justify-content: space-between;
    display: flex;
}

h1 {
    color: $colour-shade-5;
    margin-bottom: 1rem;
}

.errorMessage {
    margin-top: 0.2rem;
    color: $colour-error !important;
    font-size: 0.85rem;
    text-align: left;
}

.error {
    padding: 0.65rem;
    border-radius: 300px;
    border: 1px solid $colour-error;
    margin-bottom: 2rem;
    color: $colour-shade-0;
    background-color: $colour-error;
}

.errorMessage {
    color: $colour-error;
}

.loggedIn {
    word-break: break-all;
    color: $colour-shade-5;
}

.p {
    color: $colour-shade-5;
}

.link {
    color: $colour-blue-0;
    text-decoration: underline;
}

.link:hover {
    cursor: pointer;
}

.loadingText {
    padding-top: 2rem;
    color: $colour-shade-5;
}

.logout {
    padding: 0.5rem;
    border: 1px solid $colour-green-0;
    margin-bottom: 1rem;
    color: $colour-green-0;
    border-radius: 300px;

    svg {
        width: 25px;
        height: 25px;
        display: inline-block;
        margin: 0;
    }
}

.modal {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}

.modalBody {
    color: $colour-shade-5;
    padding: 1rem;

    .form {
        max-width: 100%;
        margin-top: 1rem;
    }

    .input,
    .button {
        margin-top: 1rem;
    }
}

textarea {
    resize: none;
}

.bar {
    position: relative;
    display: block;
    width: $width;
    &:before {
        content: '';
        height: 2px;
        width: 0;
        bottom: 0px;
        position: absolute;
        background: $hl-color;
        transition: $trans-time ease all;
        left: 0%;
    }
}

.noBottomMargin {
    composes: group;
    margin-bottom: 0;
}

.emailLabel {
    margin-top: 1rem;
}

.success {
    padding: 0.65rem;
    background-color: $colour-2ecc71;
    color: white;
    margin-bottom: 1rem;
    text-align: center;
}

.failure {
    padding: 0.65rem;
    background-color: #e74c3c;
    color: white;
    margin-bottom: 1rem;
    text-align: center;
}

.checkbox {
    @include checkbox;
}

.helpButton {
    margin: 1rem 0;
    background-color: transparent;
    border: 2px solid white;
    color: white;
    border-radius: 2rem;
    padding: 0.2rem 1rem;
    font-size: 0.8rem;

    img {
        text-align: left;
        padding: 0 0.5rem;
        max-width: 2.5rem;
    }

    a {
        text-transform: none;
        text-decoration: none;
        color: white;
    }
}
