@import '../../../global/stylesheets/mediaConfig';
@import '../../../global/stylesheets/colours';
@import '../../../global/stylesheets/styling';

.unitSummary {
    .totalUnits {
        background-color: #fff;
        border: 2px solid grey;
        height: 5rem;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        width: 5rem;
        font-size: 2rem;
        text-align: center;

        .number,
        .text {
            display: block;
        }

        .text {
            font-size: 1rem;
            text-transform: lowercase;
            margin-top: -0.5rem;
        }
    }

    .withUnits {
        .col {
            font-size: 0.65rem;
            text-transform: uppercase;
            color: #8798ad;
        }
    }
    
    .unitItem {
      @include hoverOver();
    }

    .noUnits {
        text-align: center;
        padding: 1rem;

        p {
            color: #ababab;
            margin: 0;
        }
    }

    .circle {
        position: relative;
        border-radius: 100px;
        background-color: $color-2e5bff;
        text-align: right;
        padding: 0.1rem 0.6rem;
        font-size: 0.7rem;
        color: white;

        &.rentable {
            background-color: #3c85ca;
        }

        &.occupied {
            background-color: #ef7734;
        }

        &.vacant {
            background-color: #ef3340;
        }
    }

    .row {
        display: flex;

        .col {
            flex: auto;
            color: #8798ad;
        }
    }

    .portrait {
        display: block;

        .col {
            margin: 0.5rem;
        }
    }
}
