@import "../../../../../../global/stylesheets/colours";

.generalPage {
  background-color: $colour-shade-0;
  padding: 2rem;
  margin-bottom: 1rem;
}

.label {
  text-align: left;
  font-size: 0.7rem;
  color: $colour-shade-2;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05rem;
  margin-bottom: 0.25rem;
  width: 100%;
}

.container {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.input {
  padding: 0.45rem;
  border-radius: 0;
  background-color: $colour-shade-0;
  width: 100%;
  text-align: left;
  font-weight: 500;
  color: $colour-shade-5;
  border: 1px solid #ced4da;
}

.label {
  text-align: left;
  font-size: 0.7rem;
  color: $colour-shade-2;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05rem;
  margin-bottom: 0 !important;
  width: 100%;
}

.title {
  margin-top: 1rem;
  font-size: 1.65rem;
}

.viewingAtom {
  padding-bottom: 1rem;

  p {
    color: $colour-shade-5;
    margin: 0;
    font-size: 0.85rem;
    text-align: left;
  }
}
