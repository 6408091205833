@import "../../../../global/stylesheets/colours";
@import "../../../../global/stylesheets/mediaConfig";
@import "../../../../global/stylesheets/styling";


.row {
    display: block;
    flex-wrap: wrap;
  
    @include tablet {
      display: flex;
      justify-content: space-between;
      margin: 0.5rem -1rem;
    }
  
    .column {
      flex: 0 100%;
      margin-bottom: 1rem;
      max-width: 25rem;
  
      @media (min-width: $widthTablet) {
        flex: 0%;
        padding: 0 15px;
        margin-bottom: 0 !important;
      }
    }
  
    .columnDetails {
      flex: 0 10%;
    }
  
    .columnDetailsSize {
      flex: 0 25%;
      min-width: 4rem;
    }
  
    .columnSmallMaxSize {
      flex: 0;
      min-width: 6.2rem;
      max-width: 6.2rem;
  
      @include desktop {
        min-width: 8rem;
        max-width: 8rem;
      }
    }
  
    .columnMinDropdown {
      min-width: 5rem;
    }
  
    .columnDetailsTickbox {
      flex: 0 50%;
      margin-bottom: 0.4rem;
      text-align: left;
  
      @include tablet {
        flex: 0 100%;
        max-width: 5rem;
        padding: 0 15px;
      }
    }
  
    .buttonContainer {
      margin-top: 1rem;
      float: right;
  
      button {
        margin-left: 0;
        margin-right: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        text-align: center;
        width: 100%;
        text-align: center;
        min-width: 10rem;
        justify-self: flex-end;
  
        span {
          padding: 0;
        }
  
        @media (min-width: $widthTablet) {
          width: auto;
        }
      }
    }
  
    .addButton {
      &:hover {
        cursor: pointer;
      }
    }
  
    .columnSmall {
      flex: 0 16%;
      padding: 0 15px;
      max-width: 12rem;
  
      span {
        white-space: nowrap !important;
      }
    }
  
    .columnShared {
      flex: 0 18%;
      padding: 0 15px;
      padding-right: 0;
      text-align: center;
  
      input {
        ::-webkit-input-placeholder {
          text-align: center;
        }
  
        :-moz-placeholder {
          /* Firefox 18- */
          text-align: center;
        }
  
        ::-moz-placeholder {
          /* Firefox 19+ */
          text-align: center;
        }
  
        :-ms-input-placeholder {
          text-align: center;
        }
      }
    }
  
    .columnXSmall {
      flex: 0 100% !important;
      margin-bottom: 1rem;
  
      @media (min-width: $widthTablet) {
        flex: 0 5%;
        padding: 0 15px;
      }
    }
  
    .checkbox {
      max-height: 2rem;
    }
  
    .checkboxPadding {
      margin: -1rem 0 -2rem 0;
    }
  
    .radioPadding {
      margin: -0.5rem 0 -2rem 1rem;
    }
  }

.rowJustifyLeft {
    composes: row;
    justify-content: flex-start;
  }

  .fullWidth {
    flex: 3;
    max-width: 20rem;
  }

  .column {
    flex: 0 100%;
    margin-bottom: 1rem;
    max-width: 25rem;

    @media (min-width: $widthTablet) {
      flex: 0%;
      padding: 0 15px;
      margin-bottom: 0 !important;
    }
  }

  .checkboxHeight {
    margin: 0;
    display: flow-root;
    min-width: 20rem;
  }

  .flexColumn {
    display: flex;
    flex-direction: column;
    margin-right: 0.5rem;
  }

  .smallText {
    //font-family: "Rubik", sans-serif !important;
    display: flex;
    flex-direction: row;
  
    span {
      white-space: nowrap;
    }
    img {
      align-self: center;
      margin: 0.5rem;
      margin-top: 1rem;
    }
  }

  .tooltipContent {
    font-size: 0.8rem;
    text-align: left;
  
    .councilHeader {
      margin-bottom: 0.5rem;
    }
  
    h1 {
      font-size: 1rem;
    }
  
    h2 {
      font-size: 1.2rem;
      color: $colour-shade-4;
    }
  
    p {
      font-size: 0.8rem;
      color: $colour-shade-4;
      font-weight: 500;
      line-height: 0.75rem;
    }
  
    .tooltipInfo {
      font-size: 0.8rem;
      color: $colour-blue-0;
    }
  
    .links {
      display: flex;
      flex-direction: column;
      padding: 0.5rem;
  
      img {
        margin-right: 1rem;
        max-width: 4rem;
      }
    }
  
    .gasIcon {
      height: 3rem;
    }
  }
  
  .regulationSmallText {
    display: flex;
    flex-direction: row;
    margin-top: -0.7rem;
    font-size: 0.7rem;
  
    span {
      white-space: nowrap;
    }
    img {
      align-self: center;
      margin: 0.5rem;
      margin-top: 1rem;
    }
  }

  .materialCheckbox {
    @include checkbox;
  }

  .infoIcon {
    height: 1rem;
  }