@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/mediaConfig";

.formControl {
  display: flex !important;
}

.radio {
  color: $colour-blue-0 !important;
}

.subText {
  color: $colour-shade-2;
  display: inline-flex;
  vertical-align: middle;
  font-size: 0.8rem;
}

.label {
  height: 1.5rem;
}
