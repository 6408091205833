@import '../../colours';
@import '../../mediaConfig';
@import '../../functions.scss';
@import '../../components/button/variables.scss';

@mixin baseButton($defaultWidth: $button-width) {
    font-size: px-to-rem($font-size);
    border-radius: px-to-rem($border-radius);
    width: auto;
    height: px-to-rem($button-height);
    position: relative;
    font-weight: bold;
    margin-right: 1rem;
    text-align: center;
    outline: 0;

    &:focus {
        outline: 0;
    }

    @include tablet {
        min-width: px-to-rem($defaultWidth);
    }

    &:last-of-type {
        margin-right: 0;
    }
}

@mixin saveButton() {
    border: $colour-blue-0 px-to-rem($border-width) solid;
    background-color: $colour-blue-0;
    color: $color-white-0;

    &:hover {
        background-color: $color-button-hover-0;
        border: $color-button-hover-0 px-to-rem($border-width) solid;
    }
}

@mixin cancelButton() {
    background-color: $color-white-0;
    border: $colour-blue-0 px-to-rem(1) solid;
    color: $colour-blue-0;

    &:hover {
        background-color: $color-button-hover-0;
        color: $color-white-0;
        border: $color-button-hover-0 px-to-rem($border-width) solid;
    }
}

@mixin actionButton() {
    background-color: $color-button-action-0;
    border: none;
    color: $color-white-0;

    &:hover {
        background-color: $color-button-hover-0;
    }
}
