@import "../../../../global/stylesheets/mediaConfig";
@import "../../../../global/stylesheets/colours";

.conductQuestion {
    .row {
        display: flex;
        margin: 0 -15px;
        text-align: left;
        margin-bottom: 1rem;
        flex-direction: column;

        @include tablet {
            flex-direction: row;
        }

        .questionText {
            flex: 0 20%;
            flex-wrap: wrap;
            padding: 0 15px;
            margin-bottom: 1rem;

            @include tablet {
                margin: 0;
            }
        }

        .column {
            flex: 0 0 auto;
            padding: 0 15px;
            margin-bottom: 1rem;

            @include tablet {
                margin: 0;
            }
        }

        .image {
            max-width: 2.5rem;
        }

        .photoVideoColumn {
            flex: 0 0 auto;
            padding: 0 15px;
            margin-bottom: 1rem;
            align-self: center;

            @include tablet {
                margin: 0;
            }

            > span {
                padding: 1rem;
                cursor: pointer;
            }
        }
    }
}

.images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    cursor: pointer;

    @include tablet {
        justify-content: start;
    }
    
    > span {
        display: block;
        flex: 0 5%;
        align-self: center;
        margin-top: -10px;
    }

    > div > img {
        flex: 0 20%;
        position: relative;
        width: 3.5rem;
        height: 3.5rem;
        min-height: 3.5rem;
        min-width: 3.5rem;
        max-width: 3.5rem;
        max-width: 3.5rem;
        border-radius: 0.8rem;
        margin: 0 .25rem;
        object-fit: cover;

        @include tablet {
            width: 3rem;
            height: 3rem;
            min-height: 3rem;
            min-width: 3rem;
            max-width: 3rem;
            max-width: 3rem;    
        }
    }

    .imageWrap {
        position: relative;
        margin: 0 .25rem;
    }

    .icon {
        color: darkgray;
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
        min-height: 1rem;
        min-width: 1rem;
        max-height: 1rem;
        max-width: 1rem;
        line-height: 0.6rem;
        padding: 2px;
        text-align: center;
        font-size: small;
        background: lightgray;
        position: absolute;
        top: -4px;
        right: -4px;
        cursor: pointer;
    }
}

.chevron {
    font-size: 1.5rem;
    vertical-align: bottom;
    padding-right: 1rem;
}

.chevron::before {
    border-style: solid;
    border-width: 0.1em 0.1em 0 0;
    content: "";
    display: inline-block;
    height: 0.45em;
    left: 0.15em;
    position: relative;
    top: 0.15em;
    transform: rotate(-45deg);
    width: 0.45em;
  }
  
  .chevron.right:before {
    left: 0;
    transform: rotate(45deg);
  }
  
  .chevron.left:before {
    left: 0.25em;
    transform: rotate(-135deg);
  }

  .photoRow {
    display: flex;
    margin: 0 -15px;
    text-align: left;
    margin-bottom: 1rem;
    flex-direction: row;

    .center {
        align-self: center;
    }

    .imagesColumn {
        flex: 0 0 auto;
        margin-bottom: 1rem;

        @include tablet {
            margin: 0;
        }
    }
  }

  .modal {
    @media (min-width: $widthTablet) {
      max-width: 50%;
      max-width: none;
      width: 50%;
    }
  
    width: 100%;
    padding: 0;
  
    button {
      top: 8px;
      right: 8px;
  
      svg {
        fill: $colour-close-button;
      }
    }
  }