@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.simpleRouterTabs {
  display: block;

  nav {
    width: auto;
    float: left;
    font-size: 0.8rem;
    font-weight: bold;
    
    @media (max-width: $max-widthPhone) {
      width: 100%;
    }

    .link {
      color: $colour-b0bac9;
      width: 100%;

      &:hover {
        text-decoration: none;
      }
    }

    .disabledLink {
      pointer-events: none;
      opacity: 0.54;
    }

    ul {
      overflow-y: hidden;
      overflow-x: auto;
      justify-content: flex-start;
      white-space: nowrap;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;

      a {
        position: relative;
        background-color: white;

        &.activeA {
          &:after {
            height: 0.5rem;
            display: block;
            width: 100%;
            border-bottom: 3px solid #0d8bcc;
            content: "";
            bottom: 0px;
            z-index: 9999999999999999999999999;
            position: relative;
          }
        }

        li {
          width: 100%;
          background-color: white;
          color: #6d6d6d;
          text-transform: capitalize;
          cursor: pointer;
          pointer-events: auto;
          list-style-type: none;
          padding: 0.2rem 0.45rem;
          letter-spacing: 0.085rem;
          text-align: center;

          border-right: 1px solid rgba(128, 128, 128, 0.32);

          &:hover {
            text-decoration: none;
            color: $color-tabHover;
          }
        }

        .fullWidthLi {
          max-width: 100%;
        }

        .activeLi {
          &:hover {
            background-color: $colour-shade-0;
          }

          font-weight: 800;

          &:after {
            content: "";
            width: 100%;
            top: 0;
            display: block;
            margin: 0 auto;
          }
        }

        .disabledLi {
          pointer-events: none;

          .link {
            pointer-events: none;
          }
        }
      }
    }
  }
}

.icon {
  padding: 0.4rem;
  padding-top: 0;
  padding-left: 0;
  @include square(25px);

  @media (max-width: $max-widthPhone) {
    display: block;
    margin: 0 auto;
    padding: 0;
    margin-bottom: 0.25rem;
  }
}

.tooltipContent {
  p,
  .dateBy {
    font-size: 0.8rem;
    color: black;
  }
  h1 {
    font-size: 1rem;
    padding: 0;
    margin-bottom: 1rem;
  }
  font-size: 0.8rem;
  text-align: left;

  .links {
    img {
      margin-right: 1rem;
    }
    display: inline-block;
    padding: 0.5rem;
  }
}
