@import ".././../../global/stylesheets/colours";

.userItem {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  text-align: left;
}

.itemRow {
  &:hover {
    cursor: pointer;
  }
}

.icons {
  img {
    padding-left: 2.5rem;
  }
}

.associatedDocuments {
  font-weight: bold;
  font-size: 0.75rem;
}

.label {
  text-align: left;
  font-size: 0.7rem;
  color: $colour-shade-2;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05rem;
  margin-bottom: 0.25rem;
  width: 100%;
}


.input {
  border-radius: 5px;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $colour-495057;
  background-color: $color-input-bg;
  background-clip: padding-box;
  border: 1px solid #F0F3FF;
  transition: border-color 0.15s;

  &::placeholder {
    color: $colour-b0bac9;
  }
}