@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.issuesStatus {
  display: block;
  width: 100%;
}

.title {
  width: 100%;
}

.columns {
  display: flex;
  flex-direction: row;

  .column {
    display: flex;
    flex-direction: column;
    flex: 1;
    font-size: 0.8rem;
  }
}

.number {
  font-size: 2rem;
  font-weight: 600;
}

.numberNew {
  color: $colour-orange-0;
}

.numberInProgress {
  color: $colour-green-0;
}

.numberTotal {
  color: $colour-blue-0;
}

.baseIcon {
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  background: #e6e7ed;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  margin: 0 auto;
}
