@import '../../../global/stylesheets/mediaConfig';
@import '../../../global/stylesheets/colours';

.box {
    height: 100%;
}

.chatBox {
    //margin-bottom: 1rem;
    padding-bottom: 1rem;
    background-color: white;
}

.input {
    width: 100%;
    position: sticky;
    //background-color: white;
    width: 100%;
    bottom: 0;
    //padding: 0.4rem;
    z-index: 1;
    padding-top: 1rem;

    @include tablet {
        bottom: 0;
    }
}

.btnHolder {
    button {
        padding: 0.6rem 1rem 0.6rem 1rem;
    }
}

.header {
    display: flex;

    .actions {
        flex: 0 100%;

        img {
            &:hover {
                cursor: pointer;
            }
        }

        .attach {
            flex: 0 50%;
            margin: 1rem;
        }

        .addUser {
            flex: 0 50%;
            margin: 1rem;
        }
    }
}

.viewUsers {
    cursor: pointer;
}

.title {
    font-weight: bold;
}

.inner {
    //padding: 1rem;
    //padding-left: 2rem;
    //width: 100%;
    //height: 100%;
    //position: relative;

    @include tablet {
        padding: 0;
    }

    .chat {
        display: block;
        width: 100%;
        //height: 100%;
        //margin: 0 auto;
        height: calc(100vh - 150.83px);
        padding-bottom: 10rem;
        overflow: auto;
        overflow-x: hidden;
    }

    input {
        background-color: white;
        border-radius: 5px;
    }
}

.chatNested {
    width: 100%;
    margin: 0 auto;
    height: 28rem;
    overflow: auto;
    overflow-x: hidden;
}

.inputFocussed {
    display: block;
    width: 100%;
    //height: 100%;
    //margin: 0 auto;
    height: 300px;;
    padding-bottom: 10rem;
    overflow: auto;
    overflow-x: hidden;
}

.navWrapper {
    display: block;
    width: 100%;
    //z-index: 1;

    @include tablet {
        //position: fixed;
        position: sticky;
    }
}

.navNestedWrapper {
    display: block;
    width: 100%;
    z-index: 1;
}

.sticky {
    //composes: navWrapper;
    margin-top: -2rem;
}

.nav {
    padding: 0.25rem;
    background-color: white;
    border-bottom: 4px solid #e2e2e2;
    display: flex;

    @include tablet {
        padding: 0.8rem;
    }

    .listWrapper {
        max-width: 95%;
        overflow: auto;
        margin-left: 2rem;
        margin-right: 1rem;

        @include tablet {
            margin-left: 1rem;
        }
    }

    ul {
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 0;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        flex-direction: row;
        justify-content: space-between;
        overflow: auto;
        list-style: none;

        @include tablet {
            justify-content: flex-start;
        }

        li {
            display: flex;
            flex: 0 0 auto;
            font-size: 0.8rem;
            padding: 0 1rem;
            align-content: center;

            .image {
                flex: 0;
            }

            .name {
                margin-left: 0.4rem;
                flex: 0%;
                align-self: center;

                .row {
                    display: flex;
                }
            }
        }

        nav {
            padding: 0;
        }

        @include tablet {
            padding: initial;
        }
    }
}

.navActions {
    text-align: left;
    img{
        height: 40px;
        width: 40px;
    }
}

.startedChat {
    background-color: #EFE4E4;
    text-transform: uppercase;
    font-size: 0.6rem;
    text-align: center;
    padding: 0.2rem;
}

.userIcon {
    height: 0.8rem;
}

.typeOrRole {
    span {
        padding-left: 0.6rem;
    }
}
