@import "../../../../global/stylesheets/colours";
@import "../../../../global/stylesheets/mediaConfig";

.container {
  margin: 0;
}

.navigation {
  flex: 0 100%;
  max-width: 100%;
}

.routedPage {
  margin: 1rem;
  background-color: $colour-shade-0;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  height: 100%;

  padding: 0;

  @include tablet {
    padding: 0 15px 0 15px;
  }
}

.routedPageNoMargin {
  background-color: $colour-shade-0;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  height: 100%;

  padding: 0;

  @include tablet {
    padding: 0 15px 0 15px;
  }
}

.tabs {
  padding: 0;

  @media (min-width: $widthTablet) {
    padding: 0 15px 0 15px;
  }
}

.content {
  height: 100%;
  width: 100%;
}

.navigation {
  padding: 0 1rem;

  @include tablet {
    padding: 0;
  }
}