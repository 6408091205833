@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.modal {
  position: relative;
  width: 100%;
  padding: .4rem;
  max-width: none;

  .header {
    color: #b8bcbd;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  @include tablet {
    padding: 1rem;
    width: 60%;
  }

  .mapWrapper {
    position: relative;
    height: 40vh;

    @include tablet {
      height: 30rem;
    }
    }
  }

  .actions {
    padding: .4rem;
    text-align: center;
  }