@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/mediaConfig";

.addAsset {
  height: 100%;

  .content {
    display: flex;
    
    @include tablet {
      background-color: #f3f3f3;
    }

    .form {
      flex: auto;
    }

    @include tablet {
      padding: 1rem 2rem;
    }
  }
}

.headerBar {
  background-color: white;
  width: 100%;
  padding: 0.25rem 2rem;
  text-align: left;
  display: flex;

  @include tablet {
    padding: 1rem;
  }

  .breadcrumbs {
    flex: 100%;
    align-self: center;

    @include tablet {
      padding-left: .5rem;
    }
  }

  .actionMenu {
    flex: 0 0 auto;
    align-self: center;

    .actionIcon {
      height: 2rem;
    }
  }
}