@import "../../../../global/stylesheets/mediaConfig";
@import "../../../../global/stylesheets/colours";

.loader {
  margin-top: 1rem;
}

.image {
  width: 100%;
  cursor: pointer;
}

.filterColumn {
  width: 100%;
  display: flex;
  flex: 0%;
  background-color: white;
}

.row {
  display: flex;
  justify-content: flex-end;
  background-color: white;
  .column {
    flex: 0%;
    //padding-top: 0px;
  }

  .iconColumn {
    justify-self: flex-end;
    flex: 0 0 auto;
    align-self: center;

    @include tablet {
      padding-right: 1rem;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.tabRow {
  justify-content: flex-start !important;
  margin-bottom: 0.5rem;
}

.icon {
  display: inline-block;

  .text {
    display: none;

    @include tablet {
      display: inline-block;
    }
  }

  img {
    margin-right: 0.2rem;
  }

  &:hover {
    cursor: pointer;
  }
}

.grid {
  width: 100%;
  padding-top: 1rem;
  //padding-right: 0;
  
  @include tablet {
    //padding-right: 1rem;
  }
}

.dropdown {
  display: flex;
  min-width: 5rem;
  flex-direction: column;
  justify-content: left;

  @include mobileLandscape {
    flex-direction: row;
    justify-content: left;
  }
}

.typeDropdown {
  flex: 0%;
  align-self: center;
  max-width: 85%;
  display: none;

  @include tablet {
    display: block;
    max-width: 18rem;
    flex: 0 40%;
  }
}

.mobileDropdown {
  margin-top: 1rem;
  
  @include tablet {
    display: none;
  }
}

.gridRow {
  margin-top: 0.25rem;
}

.gridColumn {
  width: 100%;
}

.tabNav {
  width: 100%;
  margin: 0 auto;

  @include mobileLandscape {
    margin: unset;
  }
}

.searchInput {
  min-width: 10rem;
  max-width: 10rem;
  display: flex;
  flex: 0 100%;
  margin-right: 1rem;

  @include mobileLandscape {
    flex: 0 50%;
    min-width: 12rem;
    max-width: 12rem;
  }

  @include tablet {
    min-width: 12rem;
    max-width: 15rem;
  }
}

.dateRangePicker {
  display: flex;
  flex: 0 100%;
  margin-left: 1rem;

  @include mobileLandscape {
    flex: 0 50%;
    min-width: 12rem;
    max-width: 12rem;
  }

  @include tablet {
    min-width: 12rem;
  }
}

.actionMenu {
  display: flex;
  flex-direction: row;
  margin-right: 0.25rem;
}

.marginTop {
  margin-top: 1rem;
}

.amount {
  font-size: 1.2rem;

  &.income {
    color: green;
  }

  &.expense {
    color: red;
  }
}

.subIcon {
  display: block;
  min-width: 1.2rem;
  min-height: 1.2rem;
  background-repeat: no-repeat;
  background-size: 100%;
  align-self: center;
}

.subIconAdd {
  composes: subIcon;
  background-image: url("../../../../images/icons/transactions/icon_contextmenu_addexpense.svg");
}

.subIconExportCsv {
  composes: subIcon;
  background-image: url("../../../../images/icons/transactions/icon_contextmenu_csv.svg");
}

.subIconExportPdf {
  composes: subIcon;
  background-image: url("../../../../images/icons/transactions/icon_contextmenu_pdf.svg");
}

.subIconExportExcel {
  composes: subIcon;
  background-image: url("../../../../images/icons/transactions/icon_contextmenu_excel.svg");
}

.subIconDelete {
  composes: subIcon;
  background-image: url("../../../../images/icons/transactions/icon_contextmenu_delete.svg");
}

.syncfusionDropdown {
  background-color: transparent !important;
  padding: 0;
  font: inherit;

  ul {
    background-color: white !important;
  }
}

.disabledActionButton {
  pointer-events: none;
  opacity: 0.5;
}

.actionColumn {
  padding: 0 !important;
}

.iconAction {
  display: block;
  min-width: 2rem;
  min-height: 2rem;
  background-repeat: no-repeat;
  background-size: 100%;
}

.actionText {
  padding-left: 0.25rem;
  font-size: 1rem;
  display: none;
  align-self: center;

  @include desktop {
    display: block;
  }
}

.tick{
  color: green;
  width: 10px;
  height: 10px;
  text-align: center;
}

.grid * {
  //font-size: 16px !important;
  padding: 0px;
}

.completedCheck{
  //padding: 0px !important;
  padding-bottom: 2px !important;
  padding-right: 2px !important;
  padding-left: 0px !important;
  padding-top: 0px !important;
}

.buttonGrp{
  background-color: red;
}

.button1{
  background-color: green;
}