@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.links {
  margin-top: 1rem;

  .inputs {
    margin: 3rem 0 0 0;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin: -15px;

    .column {
      padding: 0 15px 15px 15px;
      flex: 0 50%;
    }
  }

  .linkHeading {
    span {
      font-size: .8rem;
      color: blue;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.fileRow {
  display: flex;
  flex-wrap: wrap;
  margin: .25rem 0;

  label {
      margin-bottom: 0;
  }

  :global {
      .rc__control {
          border: 1px solid #eaeefd !important;
      }
      .rc__control, .rc__value-container {
          height: calc(1.5em + 0.75rem + 2px) !important;
      }
  }

  .clearIcon {
      padding: .5rem;

      &:hover {
          cursor: pointer;
      }

      &.disabled {
          cursor: not-allowed;
          pointer-events: none;
      }
  }

  .hidden {
      opacity: 0;
      visibility: hidden;
  }

  .fileItem {
      display: flex;
      flex: 100%;
      background-color: #f9f9f9;
      border: 1px solid #cbcbcb;
      margin-bottom: 1rem;

      .innerColumn {
          align-self: center;
          flex: auto;
      }

      .autoColumn {
          align-self: center;
          flex: 0;
      }

      .innerRow {
          display: block;
          margin: 0.25rem;

          @include tablet {
              display: flex;
          }
          
          input {
              width: 100%;
              border-radius: 5px;
              display: block;
              width: 100%;
              height: calc(1.5em + 0.75rem + 2px);
              padding: 0.375rem 0.75rem;
              font-size: 1rem;
              font-weight: 400;
              line-height: 1.5;
              color: $colour-495057;
              background-color: #f9faff;
              background-clip: padding-box;
              border: 1px solid #eaeefd;
              transition: border-color 0.15s;
          }

          .dropdown {
              flex: 25%;
              align-self: center;
          }

          .innerColumn {
              flex: auto;
              align-self: center;

              &.success {
                  color: $color-green-1;
              }

              &.failure {
                  color: red;
              }

              progress {
                  height: 1rem;
                  border-radius: 0;
                  border: 1px solid #3c85ca;
                }
                progress::-webkit-progress-bar {
                  background-color: white;
                }
                progress::-webkit-progress-value {
                  background-color: #3c85ca;
                }
                progress::-moz-progress-bar {
                  /* style rules */
                }
          }
      }
  }
}

.modal {
  @media (min-width: $widthTablet) {
    max-width: 50%;
    max-width: none;
    width: 50%;
  }

  width: 100%;
}

.overlay {
  background: rgba(0, 0, 0, 0.25);
  z-index: 2;
}

.buttons {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;

  button {
    float: none;
    position: relative;
    width: 8rem;
    padding: 0.4rem;
    font-weight: bold;
    margin-right: 1rem;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.modalBody {
  color: $colour-shade-5;
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.inner {
  border-width: 2px;
  border-radius: 2px;
  border-color: $colour-0c54602e;
  border-style: dashed;
  background-color: $colour-e4e4e4;
}

.content {
  background-color: white;
  margin: 0.4rem;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  padding: 1rem;
}

.formGroup {
  margin-bottom: 1rem !important;
}

.success {
  p {
    text-align: center;
    color: black;
    margin: 0;
  }
}

.column {
  flex: 0 100%;

  @media (min-width: $widthTablet) {
    flex: 0%;
  }
}

.label {
  line-height: 0.7rem;
  float: left;
  font-size: 0.7rem;
  color: $colour-shade-2;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05rem;
  margin-bottom: 0.25rem;
  display: contents;
}

.input {
  border-radius: 5px;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $colour-495057;
  background-color: $color-input-bg;
  background-clip: padding-box;
  border: 1px solid #f0f3ff;
  transition: border-color 0.15s;
  @media (max-width: $max-widthPhone) {
    width: 100%;
  }

  &::placeholder {
    color: $colour-b0bac9;
  }
}

.description {
  textarea {
    height: 6rem;
  }
}

.icon {
  display: none;
  align-self: center;
  justify-self: center;

  img {
    max-width: 2rem;
  }

  @media (min-width: $widthTablet) {
    display: block;
  }
}

.modelHeader {
  color: $colour-shade-4;
  font-weight: 500;
  margin-bottom: 0px !important;
  border-bottom: 2px solid $colour-shade-4;
  padding-bottom: 0.4rem;
  font-size: 1.4rem;
}

.buttonDiv {
  text-align: right;

  @media (min-width: $min-widthTablet) {
    width: 600px;
  }
}

.associationContainer {
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: $colour-0c54602e;
  border-style: dashed;
  background-color: #f3f3f3;

  .search {
    margin-bottom: 1rem;
    border-radius: 5px;
    background-color: #f9faff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 6px 0px;

    img {
      display: inline-block;
    }

    input {
      padding: 0.8rem;
    }

    div,
    input,
    button {
      margin: 0;
      border: none;
    }

    div {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
