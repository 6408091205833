@import "../../../../global/stylesheets/colours";

.generalPage {
  background-color: $colour-shade-0;
  padding: 2rem;
  margin-bottom: 1rem;
}

.addButtons {
  margin-bottom: 1rem;
}

.newItem {
  padding: 1rem;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
  border: 1px solid #b0bac942;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}

.label {
  text-align: left;
  font-size: 0.7rem;
  color: $colour-shade-2;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05rem;
  margin-bottom: 0.25rem;
  width: 100%;
}

.addIcon {
  margin-right: 0.5rem;
}

.filterBar {
  margin-left: 0.5rem;
}
