@import "../../../../global/stylesheets/mediaConfig";
@import "../../../../global/stylesheets/colours";
@import "../../../../global/stylesheets/styling";

.tile {
  min-height: 4rem;
  margin-bottom: 1rem;
  text-align: center;

  @include tablet {
    margin-bottom: 0;
  }

  .compliancyText {
    font-size: 0.7rem;
    border-radius: 300px;
    color: $colour-shade-0 !important;
    margin: 0 auto;
    max-width: 10rem;
    max-width: 6rem;

    &.nonCompliant {
      background-color: $colour-error;
      min-width: 5.5rem;
    }

    &.warning {
      background-color: $colour-orange-0;
    }

    &.compliant {
      background-color: $colour-green-0;
    }
  }

  .icon {
    @include square(2rem);
    margin: 0 auto;
  }

  .chart {
    margin: 0 auto;

    img {
      @include square(4rem);
    }
  }

  .name {
    font-size: 1rem;
  }

  .date {
    font-size: 0.7rem;
  }

  .nonCompliant {
    color: $colour-error;
  }

  .warning {
    color: $colour-orange-0;
  }

  .compliant {
    color: $colour-green-0;
  }
}

.chart {
  margin: 0 auto;

  img {
    @include square(4rem);
  }
}
