// Primary Branding
$colour-green-0: #008000;
$colour-blue-0: #0085ca;
$colour-cyan-0: #d8f5f8;
$colour-orange-0: #fcbd63;
$color-green-1: #2ecc71;
$color-button-1: #3c85ca;
$color-white-0: #FFFFFF;
$color-button-hover-0: #707070;
$color-button-action-0: #84B36E;
$colour-header-0: #808080;

// Secondary Branding
$colour-blue-1: #42a6fc;
$color-input-bg: #f9faff;

// Shades
$colour-shade-0: #fff;
$colour-shade-1: #d2cccc;
$colour-shade-2: #b8bcbd;
$colour-shade-3: #8798ad;
$colour-shade-4: #808080;
$colour-shade-5: #000;

// Specific Branding
$colour-dropzone-shade: #fafafa;
$colour-error: #c0392b;

$colour-close-button: #b5bfcd;

// Temporary colour variables
$colour-e4e4e4: #e4e4e4;
$colour-b0bac9: #b0bac9;
$colour-8c8c8c: #8c8c8c;
$colour-707070: #707070;
$colour-eeeeee4a: #eeeeee4a;
$colour-495057: #495057;
$colour-282c34: #282c34;
$colour-0c54602e: #0c54602e;

$colour-c4d0f6: #c4d0f6;
$colour-2f5cff: #2f5cff;
$color-2e5bff: #2e5bff;
$colour-6161ac: #6161ac;
$colour-3f64e899: #3f64e899;
$colour-8488f9: #8488f9;
$colour-2ecc71: #2ecc71;

$color-tabHover: #B5AFAF;
