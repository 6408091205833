@import "../../../../global/stylesheets/colours";
@import "../../../../global/stylesheets/mediaConfig";

.row {
    display: block;
    flex-wrap: wrap;
    margin: -15px;

    @include tablet {
      display: flex;
    }

    .column {
      padding: 0 15px 15px 15px;
      flex: 0 50%;
    }

    input {
    border-radius: 5px;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #f9faff;
    background-clip: padding-box;
    border: 1px solid #eaeefd;
    transition: border-color 0.15s;
    box-shadow: none;
    }
  }

  .input {
    width: 100%;
    border-radius: 5px;
    background-color: $colour-shade-0;
    color: black;
    border: 1px solid #f0f3ff;
  }

  .inputs {
    margin: 1rem 0;
  }

  .search {
    border-radius: 5px;
    background-color: #f9faff;
    border: 1px solid #eaeefd; 

    img {
      display: inline-block;
    }

    input {
      padding: 0.8rem;
    }

    div,
    input,
    button {
      margin: 0;
      border: none;
    }

    div {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .input {
    width: 100%;
    border-radius: 5px;
    background-color: $colour-shade-0;
    color: black;
    border: 1px solid #f0f3ff;
  }

  .input,
.submit {
  display: inline-block;
  border-radius: 0;
  -webkit-appearance: none;
}