
.pageBorder {
    padding: 1rem;
}

.excelButtonDiv {
    float: right;
    width: 150px;
}

.wrapperDiv {
    height: 50px;
}