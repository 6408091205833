@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.button {
  background-color: $colour-blue-0;
  width: 20%;
  border-radius: 0;
  padding: 0.75rem;
  font-weight: 600;
  margin-top: 0.5rem;
  border: none;
  margin-left: 1rem;
  border-radius: 6px;

  @media (max-width: $max-widthPhone) {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.cancelButton {
  composes: button;
  background-color: #3f64e899;
}

.modalBody {
  color: $colour-shade-5;
  text-align: left;
  padding-bottom: 0;
  margin-bottom: 0.65rem;
  line-height: 2rem;

  @include tablet {
    height: 25px;
    line-height: auto;
  }
}

.modelHeader {
  color: $colour-shade-2;
  font-weight: 500;
  margin-bottom: 0px !important;
}

.buttonDiv {
  text-align: right;
}

.checkboxes {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.address {
  color: $colour-blue-0;
  font-weight: bold;
}

.overlay {
  z-index: 999999999999999999999999 !important;
}