@import "../../../global/stylesheets/colours";

#modal {
  background-color: $colour-shade-0;
  border-radius: 2rem;
  padding: 2rem;
  width: 100%
}

.alert {
  border: none !important;
  background-color: $colour-shade-0;
}

.text {
  color: $colour-shade-5;
}

.overlay {
  background: rgba(0, 0, 0, 0.2);
}

.loading {
  margin: 0 auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;
}

.h1 {
  font-size: 3rem;
  text-transform: capitalize;
  letter-spacing: inherit;
  text-align: center;
  font-weight: bold;
}

.tasks {
  list-style: none;
  text-align: left;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: auto;

  li {
    text-align: center;
    padding-bottom: .4rem;
    color: rgba(128, 128, 128, 0.65);
    font-weight: 500;

    img {
      max-width: 1.4rem;
      margin-right: 1rem;
    }
  }
}

.h3 {
  font-size: 1.2rem;
  color: rgba(128, 128, 128, 0.65);
  font-weight: 500;
  text-align: center;
}

.buttons {
  padding-top: 1rem;
  button {
    width: 100%;
  }
}