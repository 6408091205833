@import '../../../global/stylesheets/mediaConfig';
@import '../../../global/stylesheets/colours';

.input {
    border-radius: 0;
    background-color: #f9faff;
    border: 1px solid #f0f3ff;
}

.label {
    float: left;
    font-size: 0.7rem;
    color: $colour-shade-2;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.05rem;
    margin-bottom: 0.25rem;
    display: block;
    width: 100%;
    text-align: left;
}

.dropdown {
    position: absolute;
    background-color: $colour-shade-0;
    color: $colour-shade-5;
    max-height: 14em;
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 99999999999999999999;
}

.addressToggle {
    text-align: right;
    color: $colour-blue-0;
    font-size: 0.75em;

    &:hover {
        cursor: pointer;
        font-weight: bold;
    }
}

.inputAddress {
    composes: input;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.findAddress {
    color: $colour-shade-5;
}

.addressInput {
    composes: input;
    width: 80%;
    display: inline-block;
    border-top-left-radius: 0.35rem;
    border-bottom-left-radius: 0.35rem;

    @media (max-width: $max-widthTablet) {
        width: 70%;
    }
}

.selectedAddress {
    composes: input;
    width: 100%;
    display: inline-block;
}

.addressFindButton {
    composes: input;
    width: 20%;
    cursor: pointer;
    margin-top: 1rem;
    margin-bottom: 0.2rem;
    display: inline-block;
    background-color: $color-button-action-0;
    color: $colour-shade-0;
    border: 0;
    padding: 7px;
    margin-top: 0;
    border-top-right-radius: 0.35rem;
    border-bottom-right-radius: 0.35rem;

    &:hover {
        background-color: $color-button-hover-0;
    }

    @media (max-width: $max-widthTablet) {
        width: 30%;
    }
}

.addressResultsDropdown {
    composes: dropdown;
    color: $colour-shade-5;
    font-size: 0.8rem;
    text-align: left;
    overflow-y: scroll;
    box-shadow: 0 4px 8px 0 $colour-shade-5;
}

.addressResultsDropdown ul {
    list-style: none;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;

    -webkit-margin-before: 0;
    margin-block-start: 0;
    -webkit-margin-after: 0;
    margin-block-end: 0;
    -webkit-margin-start: 0;
    margin-inline-start: 0;
    -webkit-margin-end: 0;
    margin-inline-end: 0;
    -webkit-padding-start: 0;
    padding-inline-start: 0;

    li {
        padding: 0.65rem;
        margin-left: 0;
        font-size: 0.75rem;

        &:nth-child(odd) {
            background-color: $colour-dropzone-shade;
        }

        &:hover {
            background-color: $colour-shade-0;
            cursor: pointer;
        }
    }
}

.formGroup {
    margin-bottom: 0;
}

.error {
    padding: 0.65rem;
    color: $colour-error;
}

.active {
    background: $colour-shade-1 !important;
}
