
.e-grid .e-rowcell.customcss{
    padding: 2px !important;
    margin: 0px !important;
}

.e-grid .e-headercell.customcss{
    padding: 2px !important;
    margin: 0px !important;
}

/* Folloiwng don't seem to make a difference*/
/* .e-sortfilterdiv .e-icons.customcss{
    padding: 0px !important;
    margin: 0px !important;
    height: 0px;
    width: 0px;
    display: none !important; 
    
} */

/* .e-grid .e-sortfilterdiv.customcss { 
    display: none !important; 
  } 
 */
/* .e-sortfilterdiv .e-icons .e-ascending .e-icon-ascending.customcss{
    padding: 0px !important;
    margin: 0px !important;
} */