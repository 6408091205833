@import "../../../../global/stylesheets/mediaConfig";
@import "../../../../global/stylesheets/colours";

.container {
    width: 100%;
    display: flex;
    margin-bottom: .14rem;
}

.item {
    border-radius: 5px;
    padding: 10px;

    padding: 0.4rem;
    width: 100%;
    display: flex;
    min-height: 3.5rem;

    p {
        margin-bottom: 0;
    }

    .avatar {
        z-index: 0;
        display: none;

        @include tablet {
            display: flex;
        }
        
        flex: 0 6%;
    }

    .heading {
        display: block;
        width: 100%;
        
    .timestamp {
        color: #8798AD;
        }
    }

    .message {
        position: relative;
        padding: 0.6rem;
        box-shadow: 0px 3px 6px #00000029;
        flex: 0 auto;
        align-self: center;
        justify-self: center;
        text-align: left;
        border-radius: 5px;
    }

    .messageContent {
        word-break: break-word;
        
        img {
            display: block;
            max-height: 6rem;
        }
    }

    .name {
        font-weight: bold;
    }
}

.leftItem {
    justify-content: flex-start;
    composes: item;
    color: black;

    .message {
        background-color: white;
    }
}

.rightItem {
    width: 99%;
    justify-content: flex-end;
    composes: item;
    color: black;

    .message {
        background-color: #E8EBFF;
    }
}

.status {
    position: absolute;
    top: -6px;
    right: -10px;
    font-size: 0.6rem;
    color: #000;
    svg {
        width: 20px;
        height: 20px;
        margin: 0;
    }
}