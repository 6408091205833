@import '../../../../global/stylesheets/mediaConfig';
@import '../../../../global/stylesheets/colours';

.form {
    color: $colour-shade-5;
    text-align: left;
    margin: 0 -15px;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column-reverse;
    padding: 0 2rem;

    @include tablet {
        flex-direction: row;
        padding: 0;
    }

    .content {
        padding: 0 15px;
        flex: auto;
        padding: 1rem;
        background-color: white;
    }

    .image {
        padding: 0 15px;
        flex: 0 30%;
    }
}

.errorMessage {
    color: red !important;
    font-size: 0.85rem;
    text-align: left;
}

textarea {
    height: auto !important;
}

textarea,
.input {
    border-radius: 5px;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $colour-shade-4;
    margin-bottom: 0.4rem;
    background-color: $color-input-bg;
    background-clip: padding-box;
    border: 1px solid #f0f3ff;
    transition: border-color 0.15s;
    @media (max-width: $max-widthPhone) {
        width: 100%;
    }

    .input {
        height: calc(1.5em + 0.75rem + 2px);
    }

    &::placeholder {
        color: $colour-shade-2;
    }
}

.durationLengthInput{
    border-radius: 5px;
    width: 65px;
    padding: 0.375rem 0.75rem;
    margin-right: 10px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $colour-shade-4;
    margin-bottom: 0.4rem;
    background-color: $color-input-bg;
    background-clip: padding-box;
    border: 1px solid #f0f3ff;
    transition: border-color 0.15s;
    

    .input {
        height: calc(1.5em + 0.75rem + 2px);
    }
}

.durationSelect {
    width: 110px;
}

.rentDurationSelect {
    width: 140px;
}

.depositSchemeSelect {
    width: 200px;
}

.innerWrappingItem {
    padding-right: 10px;
    padding-bottom: 10px;
    width: 350px;
    @media (max-width: $min-widthTablet) {
        width: 100%;
    }
}

.durationWrapper {
    display: inline-flex;
    padding-right: 20px;
    //width: 100%;
}

.currencyInputWrapper {
    width: 100px;
    margin-right: 10px;
    padding-bottom: 5px;

    // This bad using the !important, will update once the RCCurrencyInput component is
    // made more flexible to allow custom css
    input {
        width: 100% !important;
        height: calc(1.5em + 0.75rem + 2px) !important;
        padding: 0.375rem 0.75rem !important;
        font-size: 1rem !important;
        font-weight: 400 !important;
        line-height: 1.5 !important;
        color: #495057 !important;
        border-radius: 0px !important;
        display: inline-block !important;
        -webkit-appearance: none !important;
        border-radius: 0.35rem !important;
        background-color: #f9faff !important;
        border: 1px solid #f0f3ff !important;
      }
}

.flexibleWrapper {
    display: flex;
    flex-wrap: wrap;
    //white-space: pre-wrap !important;
}

.row {
    display: flex;
    flex-direction: column;
    margin: 0 -15px;

    @include tablet {
        margin-bottom: 0.4rem;
    }

    @include desktop {
        flex-direction: row;
    }
}

.column {
    flex: 0 100%;
    margin-bottom: 0.5rem;

    @include tablet {
        padding: 0 15px;
        flex: 0%;
        margin: 0;
    }

    @include mobileLandscape {
        padding: 0 15px;
        flex: 0%;
        margin: 0;
    }

    @media (max-width: 600px) {
        padding: 0 15px;
        flex: 0%;
        margin: 0;
    }
}

.columnHalf {
    flex: 0 100%;
    padding: 0 15px;
    margin-bottom: 0.5rem;

    @include tablet {
        flex: 0 50%;
        margin: 0;
    }
}

.columnSmall {
    flex: 0 28%;
}

.label {
    line-height: 0.7rem;
    font-size: 0.7rem;
    color: $colour-shade-2;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.05rem;
    margin-bottom: 0.25rem;
    display: block;
    width: 100%;
}

.btn {
    border-radius: 300px;
    border: none;
    font-weight: bold;
    margin: 0 0.4rem;
    padding: 0.4rem;
    width: 8rem;

    &.cancel {
        color: #3c85ca;
        border: 1px solid #3c85ca;
        background-color: white;
    }

    &.save {
        background-color: #3c85ca;
        color: white;
        border: #3c85ca solid 1px;
    }
}

.buttons {
    display: flex;
    justify-content: center;
    font-size: smaller;

    > div {
        width: 100%;
    }

    @include tablet {
        justify-content: flex-end;
    }

    .btnContainer {
        flex: 0 0 auto;

        @include tablet {
            flex: 0 0 auto;
        }
    }

    @include mobileLandscape {
        font-size: 0.65rem;
    }

    @include tablet {
        font-size: 0.7rem;
    }

    @include desktop {
        font-size: 0.8rem;
    }

    @include fullHD {
        font-size: 1rem;
    }
}

.modalBodyOutstandingChanges {
    color: $colour-shade-5;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.modalHeader {
    color: $colour-shade-2;
    font-weight: 500;
    margin-bottom: 0px !important;
}

.imageUploader {
    background-color: #fefefe;
}

.documents {
    display: flex;
    background-color: white;
    padding: 1rem 0;
    justify-content: space-between;

    @include tablet {
        padding: 1rem;
    }

    .document {
        padding: 0 5px;
        flex: 0 0 auto;
    }
}

.dateContainer{
    display:inline-flex;
    width: 180px;
}

.keyDateIndicator{
    margin-left: 5px;
    :hover{
        cursor: pointer;
    }
}

.keyDateIndicatorImage{
    height: 37px;
}

.dateStatus{
    width: 50%;
    align-self:center;
    padding: 0px 0px 0px 15px;
}

.datePickerWrapper{
    width: 120px;
} 
.datePickerWrapper > div.react-datepicker-wrapper, 
.datePickerWrapper > div > div.react-datepicker__input-container
.datePickerWrapper > div > div.react-datepicker__input-container input {
   width: 100%;
}

.startEndDateContainer{
    display: inline-flex;
    flex-wrap: nowrap;
}

.startDateContainer {
    //width: 30%;
}

.durationContainer {
    //width: 30%;
    margin-left: 20px;
    margin-right: 20px;
}

.endDateContainer {
    //width: 30%;
}

.stickyComponentContentWrapper{
    display:inline-flex;
    width: 100%;
}

.infoIcon {
    margin-left: 0.5rem;
    height: 14px;
    width: 14px;
  }

  .tooltipContent {
    h1 {
      font-size: 1rem;
    }
    font-size: 0.8rem;
    text-align: left;
  }

  .sectionSpacer {
    height: 10px;
  }

  .accordionHeading {
    text-align: 'left';
    color: #0085ca;
    //font-size: 1rem !important;
    font-weight: 700 !important;
    font-family: "Rubik", sans-serif !important;
    padding-left: 10px;
  }

  .accordionSummary {
    flex-direction: row-reverse;
    padding: 0px 0px !important;
  }
