@import "../../../global/stylesheets/colours";

.col {
  min-width: 100%;
}

.closeButton {
  float: right;
}

.title {
  text-transform: uppercase;
  font-size: 0.85rem;
  font-weight: bold;
  color: $colour-shade-2;
  margin-top: 1rem;
  text-align: left;
}

.list {
  list-style: none;
  margin-top: 0.5em;
}

.listItem {
  text-decoration: none;
  text-align: center;
  font-size: 0.938em;
  padding: 0.5em;
  text-align: left;
}

.icon {
  height: 1.938em;
  width: 1.938em;
  float: left;
  margin-right: 0.5em;
}

.listItemServices {
  text-decoration: none;
  text-align: center;
  font-size: 0.938em;
  padding: 0.5em;
  text-align: left;
  margin-left: 1.2em;
}
