@import "../../../../global/stylesheets/colours";
@import "../../../../global/stylesheets/mediaConfig";

.generalPage {
  background-color: $colour-shade-0;
  padding: 2rem;
  // box-shadow: $colour-shade-5 0px 1px 6px, $colour-shade-5 0px 1px 4px;
  margin-bottom: 1rem;
}

.list {
  padding-top: 1rem;
}

.addIcon {
  margin-right: 0.5rem;
}