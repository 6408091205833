@import "../../../global/stylesheets/colours";

.clientsManagement {
  background-color: $colour-shade-0;
  padding: 1rem;
  margin-bottom: 1rem;
  margin: 0 auto;
}

.addButton {
  margin: 1rem;
  display: inline-flex;
  justify-content: flex-end;
}

.addClientIcon {
  margin-right: 0.5rem;
}
