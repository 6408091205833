@import "../../../../../../global/stylesheets/mediaConfig";
@import "../../../../../../global/stylesheets/colours";

.amount {
  font-size: 0.8rem;

  @include tablet {
    font-size: 1.2rem;
  }

  &.income {
    color: green;
  }

  &.expense {
    color: red;
  }
}