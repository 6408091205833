@import "../../../../global/stylesheets/colours";
@import "../../../../global/stylesheets/mediaConfig";

.generalPage {
  background-color: $colour-shade-0;
  margin-bottom: 1rem;
  position: relative;
  padding: 2px;
}

.view {
  display: block; 
  position: absolute;
  right: 0;
  font-size: 0.8rem;
  color: #0085ca;
  margin-right: 1rem;
  z-index: 2;

  &:hover {
    cursor: pointer;
  }
}

.row {
  display: flex;
  margin-right: 15px;
  margin-left: 15px;

  @include tablet {
    margin: 0;
  }

  .column {
    flex: 100%;
  }
}

.addButtons {
  margin-bottom: 1rem;

  button {
    width: auto;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.summary {
  margin-bottom: 1rem;
}

.section {
  padding: 1rem;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: $colour-shade-0;
  min-height: 7.6rem;

  .heading {
    font-size: 0.75rem;
    font-weight: 500;
    text-align: left;
    text-transform: uppercase;
    color: $colour-shade-3;
    letter-spacing: 1px;
    width: 100%;
  }

  p {
    padding-top: 1rem;
    text-align: center;
    color: $colour-shade-5;
    font-size: 3rem;

    &.event {
      color: $colour-blue-0;
    }

    &.login {
      color: $colour-green-0;
    }

    &.exception {
      color: $colour-error;
    }
  }
}

.loaded {
  padding-bottom: 1rem;

  .loadedIn {
    text-align: right;
  }
}

.tab {
  flex: 0 100%;
  margin-bottom: 1rem;

  @media (min-width: $widthTablet) {
    margin-bottom: 0;
    flex: 0 33.33%;
  }
}

.timelineEvent {
  margin: 0;
}

.profileImage {
  width: 100% !important;

  img {
    max-width: 100%;
  }
}

.label {
  font-size: 0.7rem;
  color: $colour-b0bac9;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05rem;
  margin-bottom: 0.25rem;
  display: inline-block;
  text-align: left;
  margin-right: 0.4rem;
}

.popup {
  margin-left: 0.4rem;
  display: inline-block;
}

.viewMore {
  color: $colour-blue-0;

  &:hover {
    cursor: pointer;
  }
}

.h2 {
  width: 100%;
  text-align: left;
  padding-bottom: 0.2rem;
  font-size: 1.2rem;
  color: #0085ca;
}
