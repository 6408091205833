@import '../../../global/stylesheets/mediaConfig';
@import '../../../global/stylesheets/colours';

.row {
    display: flex;

    .column {
        flex: auto;
    }
}

.btn {
    border-radius: 300px;
    border: none;
    font-weight: bold;
    margin: 0 0.4rem;
    padding: 0.4rem;
    width: 8rem;

    &.cancel {
        color: #3c85ca;
        border: 1px solid #3c85ca;
        background-color: white;
    }

    &.save {
        background-color: #3c85ca;
        color: white;
        border: #3c85ca solid 1px;
    }

    &.disabled {
        opacity: 0.8;
        cursor: not-allowed;
    }
}

.absolute {
    position: absolute;
}

.buttonContainer {
    width: 100%;
    position: relative;
    text-align: right;

    .buttons {
        button {
            @include tablet {
                &:last-of-type {
                    margin-right: 1rem;
                }
            }
        }

        @include tablet {
            display: inline-block;
        }
    }
}

.toggle {
}

.checkbox {
    line-height: 2rem;
    display: block;
    width: 100%;

    @include tablet {
        display: inline-block;
        width: auto;
    }

    > span {
        padding: 0 0.5rem 0 0;
        vertical-align: middle;
    }
}

.buttons {
    display: flex;
    justify-content: center;
    font-size: smaller;

    &.stickyMobile {
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        padding: 1rem;
        background-color: white;

        @include tablet {
            //position: relative;
            right: 0;
            //width: auto;
            justify-content: flex-end;
        }
    }

    @include tablet {
        justify-content: flex-end;
    }

    .btnContainer {
        flex: 0 0 auto;

        @include tablet {
            flex: 0 0 auto;
        }
    }

    @include mobileLandscape {
        font-size: 0.65rem;
    }

    @include tablet {
        font-size: 0.7rem;
    }

    @include desktop {
        font-size: 0.8rem;
    }

    @include fullHD {
        font-size: 1rem;
    }
}
