@import '../../../global/stylesheets/colours';
@import '../../../global/stylesheets/mediaConfig';
@import '../../../global/stylesheets/styling';

.page {
    color: black;

    p {
        font-size: 1rem;
    }

    .offeringItems {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
        flex-direction: column;
        text-align: left;

        @include tablet {
            flex-direction: row;
        }

        > div > img {
            margin-right: .25rem;
        }
    }

    .alreadyHaveAnAccount {
        color: black;
    }
}

.accessCode {
    color: black;
    text-decoration: underline;
    font-size: 0.8rem;
    margin-bottom: 0;

    &:hover {
        cursor: pointer;
    }
}

.form {
    margin: 0 auto;

    .group {
        position: relative;
        margin: 1rem 0;
        margin-top: 0;

        &:last-of-type {
            margin-bottom: 0 !important;
        }

        textarea,
        .input {
            border-radius: 5px;
            display: block;
            width: 100%;
            height: calc(1.5em + 0.75rem + 2px);
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: $colour-495057;
            margin-bottom: 0.4rem;
            background-color: $color-input-bg;
            background-clip: padding-box;
            border: 1px solid #d5deff;
            transition: border-color 0.15s;
            @media (max-width: $max-widthPhone) {
                width: 100%;
            }

            &::placeholder {
                color: $colour-b0bac9;
            }
        }
    }
}

.label {
    line-height: 0.7rem;
    text-align: left;
    font-size: 0.7rem;
    color: $colour-shade-2;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.05rem;
    margin-bottom: 0.25rem;
    display: block;
    width: 100%;
}

.logo {
    margin: 0 auto;
    text-align: center;
    margin-bottom: 1rem;
}

.errorMessage {
    margin-top: 0.2rem;
    color: $colour-error !important;
    font-size: 0.85rem;
    text-align: left;
}

.error {
    padding: 0.65rem;
    border-radius: 300px;
    border: 1px solid $colour-error;
    margin-bottom: 2rem;
    color: $colour-shade-0;
    background-color: $colour-error;
}

.buttonContainer {
    width: 100%;
    margin: 0 auto;

    @include tablet {
        width: 80%;
    }
}

.button {
    background-color: $colour-blue-0;
    color: $colour-shade-0;
    border: 1px solid $colour-blue-0;
    width: 100%;
    border-radius: 300px;
    padding: 0.55rem;
    font-weight: 600;
    font-size: 1.125rem;
}

.text {
    font-size: 1rem;
    text-align: center;
    margin: 0 auto;
    color: $colour-shade-2;
    margin-top: 1rem;
    justify-content: center;
}

.checkboxText {
    display: flex;
    flex-flow: row;
    margin: 1rem 0 0;
    text-align: left;

    label {
        align-items: start;
        > span {
            padding-top: 0;
        }
    }

    p {
        margin: 0;
        padding: 0;
    }

    .checkboxGroup {
        align-self: center;
        flex: 10%;
    }

    .terms {
        align-self: center;
        color: $colour-shade-2;
        flex: 100%;
        font-size: 0.8rem !important;
        text-align: left;

        @include desktop {
            //padding: 0.5rem;
        }
    }

    input {
        float: left;
        margin-top: 1.5rem;
    }
}

.successText {
    composes: text;
    font-size: 1rem;
}

h1 {
    color: $colour-shade-5;
    margin-bottom: 1rem;
}

.error {
    padding: 0.65rem;
    border-radius: 300px;
    border: 1px solid $colour-error;
    margin-bottom: 2rem;
    color: $colour-shade-0;
    background-color: $colour-error;
}

.errorMessage {
    color: $colour-error;
}

.loggedIn {
    word-break: break-all;
    color: $colour-shade-5;
}

.p {
    color: $colour-shade-5;
}

.link {
    display: contents;
    color: $colour-blue-0;
    text-decoration: underline;
}

.link:hover {
    cursor: pointer;
}

.loadingText {
    padding-top: 2rem;
    color: $colour-shade-5;
}

.logout {
    padding: 0.65rem;
    border: 1px solid $colour-green-0;
    margin-bottom: 1rem;
    color: $colour-green-0;
}

.modalBody {
    color: $colour-shade-5;
    padding: 1rem;

    .input,
    .button {
        margin-top: 1rem;
    }
}

.forgotPassword:hover {
    cursor: pointer;
}

.logo {
    margin-bottom: 1.5rem;
    height: 4rem;
}

textarea {
    resize: none;
}

.noBottomMargin {
    composes: group;
    margin-bottom: 0;
}

.nav {
    width: 100%;
    padding: 2rem;
    ul {
        display: block;
        list-style-type: none;
        padding-top: 1rem;
        padding-bottom: 1rem;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 0;
    }
    li {
        text-align: center;
        list-style: none;
    }
    a {
        color: $colour-blue-0;
        font-weight: 600;
        font-size: 0.7rem;
    }
}

.success {
    padding: 0.65rem 0rem;
    background-color: $colour-2ecc71;
    color: white;
    margin-bottom: 1rem;
    text-align: center;
}

.welcomeHeader {
    border-bottom: 2px solid black;
}

.termsError {
    color: $colour-error;
    font-size: 0.85rem;
}

.materialCheckbox {
    @include checkbox;
    color: $colour-blue-0 !important;
}
