@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.sidebar {
  padding: 2rem;
  min-width: 25rem;
  position: fixed !important;
  z-index: 99999999 !important;
}

@media only screen and (max-width: $min-widthPhone) {
  .sidebar {
    min-width: 1rem;
  }
}

.overlay {
  z-index: 4 !important;
}
