@import '../../../global/stylesheets/mediaConfig';
@import '../../../global/stylesheets/colours';
@import '../../../global/stylesheets/components/button/index.scss';

.errorMessage {
    color: red !important;
    font-size: 0.85rem;
    text-align: left;
}

.modal {
    @media (min-width: $widthTablet) {
        max-width: 50%;
        max-width: none;
        width: 50%;
    }

    width: 100%;
    padding: 0;

    button {
        top: 8px;
        right: 8px;

        svg {
            fill: white;
        }
    }
}

.overlay {
    background: rgba(0, 0, 0, 0.25);
    z-index: 2;
}

.buttons {
    display: flex;
    justify-content: center;
    font-size: smaller;

    @include tablet {
        justify-content: flex-end;
    }

    .btnContainer {
        flex: 0 0 auto;

        @include tablet {
            flex: 0 0 auto;
        }
    }

    @include mobileLandscape {
        font-size: 0.65rem;
    }

    @include tablet {
        font-size: 0.7rem;
    }

    @include desktop {
        font-size: 0.8rem;
    }

    @include fullHD {
        font-size: 1rem;
    }
}

.modalBody {
    color: $colour-shade-5;
    text-align: left;
    margin-bottom: 1rem;
    padding: 0.4rem 1rem 0.4rem 1rem;
}

.content {
    background-color: white;
    margin: 0.4rem;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    padding: 1rem;
}

.formGroup {
    margin-bottom: 1rem !important;
}

.success {
    p {
        text-align: center;
        color: black;
        margin: 0;
    }
}

.column {
    flex: 0 100%;

    @media (min-width: $widthTablet) {
        flex: 0%;
    }
}

.columnSmall {
    flex: 0 28%;
}

.label {
    line-height: 0.7rem;
    font-size: 0.7rem;
    color: $colour-shade-2;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.05rem;
    margin-bottom: 0.25rem;
    display: block;
    width: 100%;
}

textarea,
.input {
    border-radius: 5px;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $colour-shade-4;
    margin-bottom: 0.4rem;
    background-color: $color-input-bg;
    background-clip: padding-box;
    border: 1px solid #f0f3ff;
    transition: border-color 0.15s;
    @media (max-width: $max-widthPhone) {
        width: 100%;
    }

    &::placeholder {
        color: $colour-shade-2;
    }
}

.description {
    textarea {
        height: 6rem;
    }
}

.icon {
    display: none;
    align-self: center;
    justify-self: center;

    img {
        max-width: 2rem;
    }

    @media (min-width: $widthTablet) {
        display: block;
    }
}

.modelHeader {
    color: $colour-shade-4;
    font-weight: 500;
    margin-bottom: 0px !important;
    border-bottom: 2px solid $colour-shade-4;
    padding-bottom: 0.4rem;
    font-size: 1.4rem;
}

.buttonDiv {
    text-align: right;

    @media (min-width: $min-widthTablet) {
        width: 600px;
    }
}

.heading {
    background-color: $colour-blue-0;
    color: white;
    padding: 0.4rem;
    font-size: 1.2rem;
}

.row {
    display: flex;
    flex-direction: column;
    margin: 0 -15px;
    margin-bottom: 0.4rem;

    @include desktop {
        flex-direction: row;
    }
}

.rowNoMargin {
    composes: row;
    margin: 0 !important;
    flex-wrap: wrap;
}

.columnHalf {
    flex: 0 50%;
    padding: 0 15px;
}

.column {
    flex: 0 100%;
    padding: 0 15px;
}

.textarea {
    height: 5rem;
}

.success {
    text-align: center;
    color: black;
}

.sharingOptions {
    margin-top: 1rem;
}

.sharingRow {
    composes: row;
    max-width: 50%;
    margin-bottom: 0;

    label {
        margin: 0;
    }
}

.chatInput {
    textarea {
        background-color: #f9faff;
    }
}

.contactProperty {
    color: green;
    text-transform: none;
    font-size: 0.6rem;
}

.btn {
    &.cancel {
        @include cancelButton;
    }

    &.paidInFull {
        @include actionButton;
    }

    &.createInvoice {
        @include saveButton;
    }
}

.total {
    .title {
        font-weight: bold;
        font-size: 2rem;
    }
}

.documents {
    flex: 0 50%;
}

.selectedDocuments {
    @include desktop {
        flex: 0 50%;
    }

    img {
        max-width: 2rem;
    }

    .item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 1rem;
        padding: 0.2rem;
        text-align: center;
        flex: 0;
        border-bottom: 1px solid $colour-shade-1;

        @include desktop {
            min-width: 85%;
        }

        button {
            margin-top: 0.2rem;
            font-size: 0.8rem;
        }
    }
    .fileName {
        text-align: left;
        flex: 1;
        padding-left: 0.5rem;
    }

    .removeButton {
        display: flex;
    }
}

.loading {
    padding: 1rem;
}

.accordion {
    border: none;
}

.currencyInputWrapper {
    width: 100px;
    margin-right: 10px;
    padding-bottom: 5px;

    @media (max-width: $max-widthPhone) {
        width: 100% !important;
    }

    // This bad using the !important, will update once the RCCurrencyInput component is
    // made more flexible to allow custom css
    input {
        width: 330px !important;
        height: calc(1.5em + 0.75rem + 2px) !important;
        padding: 0.375rem 0.75rem !important;
        font-size: 1rem !important;
        font-weight: 400 !important;
        line-height: 1.5 !important;
        color: #495057 !important;
        border-radius: 0px !important;
        display: inline-block !important;
        -webkit-appearance: none !important;
        border-radius: 0.35rem !important;
        background-color: #f9faff !important;
        border: 1px solid #f0f3ff !important;

        @media (max-width: $max-widthPhone) {
            width: 100% !important;
        }
      }
}