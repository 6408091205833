@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.page {
    * {
        line-height: revert;
    }

    label {
        margin-bottom: 0;
    }

    nav {
        top: 4.8rem;
        
        > div {
            padding: 0 1.5rem;
        }

        @include tablet {
            top: 2.8rem;
        }
    }

    @include tablet {
        padding: 0;
    }

    .content {
        padding: 0 0.5rem;

        @include tablet {
            padding: 0 2rem 0 1rem;
        }
    }
}

.card {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}

.header {
    > nav > div {
        width: 98%;

        button {
            font-size: 0.8rem;
        }
    }
}