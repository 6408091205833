@import "../../../global/stylesheets/colours";

.text {
  color: $colour-shade-5 !important;
  display: block;
  margin-top: 2rem;
}

.logo {
  max-width: 5rem;
  margin-bottom: 1.5rem;
}

.loadingContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  width: 100%;

  .loading {
    display: block;
    background-image: url("../../../images/spinner-primary-large.png");
    background-repeat: no-repeat;
    height: 96px;
    width: 96px;
    animation: rotating 2s linear infinite;
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
