@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/styling";

.page {
  background-color: $colour-shade-0;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}

.navigation {
  flex: 0 100%;
  max-width: 100%;
}
.routedPage {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: $colour-shade-0;
  height: 100%;
  padding: 1rem;
  padding-right: 3rem;
  margin: 1rem;
}

.tabs {
  padding: 0;

  @media (min-width: $widthTablet) {
    padding: 0 15px 0 15px;
  }
}