@import '../../../global/stylesheets/colours';
@import '../../../global/stylesheets/styling';

.form {
    margin: 0 auto;
    max-width: 25rem;
    min-width: 25rem;
}

.topRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    .header{
        flex: 80
    }
    .help {
        flex: 20;
        align-self: center;

        .icon {
            display: inline-block;

            .text {
                font-weight: bold;
                display: none;
                color: $color-button-1;

                @include tablet {
                    display: inline-block;
                }
            }

            img {
                margin-right: 0.2rem;
            }

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.headerTitle {
    text-align: left;
    color: $colour-header-0;
    font-weight: 500;
    margin-bottom: 0px !important;
    border-bottom: 2px solid $colour-header-0;
    padding-bottom: 0.4rem;
    font-size: 1.4rem;
    margin-top: 0;
    padding-top: 0.4rem;
  }

.label {
    float: left;
    font-size: 0.7rem;
    color: $colour-shade-2;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.05rem;
    margin-bottom: 0.25rem;
    display: block;
    width: 100%;
    text-align: left;
}

.description {
    text-align: left;
    color: grey;
    line-height: 1.25rem;
    margin: 0.5rem 0;
}

.moreInformation {
    text-align: left;
    color: $color-button-1;
    line-height: 1.25rem;
}

.input {
    border-radius: 5px;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $colour-shade-4;
    margin-bottom: 0.4rem;
    background-color: $color-input-bg;
    background-clip: padding-box;
    border: 1px solid #f0f3ff;
    transition: border-color 0.15s;
    @media (max-width: $max-widthPhone) {
        width: 100%;
    }

    &::placeholder {
        color: $colour-shade-2;
    }
}

.button {
    background-color: $colour-blue-0;
    width: 100%;
    border: none;
    border-radius: 1.5rem;
    padding: 0.75rem;
    font-weight: 600;
    margin-top: 0.5rem;
    color: white;
}

.text {
    text-align: center;
    margin: 0 auto;
    color: $colour-shade-2;
    font-size: 0.85rem;
    margin-top: 1rem;
    justify-content: center;
}

.h1 {
    color: $colour-shade-5;
    margin-bottom: 0;
    text-align: left;
    text-transform: none;
}

.errorMessage {
    color: red !important;
    font-size: 0.85rem;
    text-align: left;
    display: block;
    margin-bottom: 1rem;
}

.loggedIn {
    word-break: break-all;
    color: $colour-shade-5;
}

.p {
    color: $colour-shade-5;
}

.link {
    color: $colour-blue-0;
}

.link:hover {
    cursor: pointer;
}

.loadingText {
    padding-top: 2rem;
    color: $colour-shade-5;
}

.logout {
    padding: 0.65rem;
    border: 1px solid $colour-green-0;
    margin-bottom: 1rem;
    color: $colour-green-0;
}

.body {
    ul {
        list-style: none;

        li {
            text-align: center;
            margin: 1rem;
            color: $colour-blue-0;
        }
    }
}

.sidebar {
    padding: 0.75rem;
    position: fixed !important;
    min-width: 30rem;
    max-width: 30rem;

    @media (max-width: 510px) {
        width: 100%;
        min-width: 0;
        max-width: none;
    }
}

.modalBody {
    color: $colour-shade-5;
    text-align: left;
    padding-bottom: 0;
    margin-bottom: 0.65rem;
    line-height: 2rem;

    @include tablet {
        height: auto;
        line-height: auto;
    }
}

.modalHeader {
    color: $colour-shade-2;
    font-weight: 500;
    margin-bottom: 0px !important;
}

.buttons {
    border-top: 1px #e4e4e4 solid;
    border-bottom: 1px #e4e4e4 solid;
    width: 100%;
    left: 0;
    padding: 0 1rem 1rem 1rem;
    position: sticky;
    bottom: 0;
    background-color: $colour-shade-0;
}
