@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/_mediaConfig";

.filters {
  display: flex;
  padding-bottom: 1rem;

  .addButton {
    flex: 0%;
    justify-self: flex-end;
  }
}

.dropdown {
  display: block;
  width: 100%;
  margin-right: 1rem;

  @include tablet {
    flex: 0 10%;
  }
}

.container {
  display: flex;
  position: relative;

  .list {
    position: relative;
    flex: 0 100%;
    border-right: 1px solid #f0f2f6;
    left: 0;
    transition: 1s;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    z-index: 1;
    width: 100%;

    @include tablet {
      flex: 0 28%;
    }
  }

  .content {
    @include tablet {
      flex: 0%;
    }

    @include fullHD {
      flex: 0%;
    }
    //height: calc(100vh - 77.83px);
    height: 100%;
    overflow: hidden;
    overflow-x: hidden;
    background-color: #EFE4E4;
  }

  .contentMobile {
    @include tablet {
      flex: 0%;
    }

    @include fullHD {
      flex: 0%;
    }
    height: 300px;
    overflow: hidden;
    overflow-x: hidden;
    background-color: #EFE4E4;
  }
}



.row {
  display: flex;

  .addButton {
    flex: 0%;
    align-self: center;

    button {
      width: 100%;
    }
  }

  .search {
    flex: 0 80%;
    padding: 0 15px;
  }

  .filterButton {
    align-self: center;
  }
}

.filters1 {
  padding: 1rem;
}

.collapsed {
  composes: list;
  flex: 0 !important;
  transition: 1s;
  overflow-x: hidden;
  overflow-y: hidden;
}

.collapseToggle {
  display: none;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 0.45rem 1rem;
  //left: 1.5rem;
  z-index: 2;

  @include tablet {
    padding: 1rem;
    left: 0;
  }

  .text {
    display: none;
    //left: -100px;
    //left: 0px;
    transition: 1s;
    font-size: 0.8rem;
    font-weight: normal;
  }

  &.isShown {
    display: block;
    position: absolute;
    z-index: 9999;
    background-color: white;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    top: 0;
    left: auto;
    right: 0;
    margin-right: 1rem;

    @include mobileLandscape {
      //margin-right: -1rem;
    }

    @include tablet {
      //margin-right: -2.4rem;
    }

    &:hover {
      cursor: pointer;
      background-color: #eee;

      .text {
        display: inline;
        transition: 1s;
        left: 0;
        margin-right: 0.5rem;
        align-self: center;
      }
    }
  }

  &.isCollapsed {
    display: block;
    position: absolute;
    z-index: 1;
    background-color: white;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    top: 0;

    &:hover {
      cursor: pointer;
      background-color: #eee;

      .text {
        display: inline;
        transition: 1s;
        left: 0;
        margin-right: 0.5rem;
        align-self: center;
      }
    }
  }
}
