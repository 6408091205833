@import "../../../../../global/stylesheets/colours";
@import "../../../../../global/stylesheets/mediaConfig";
@import "../../../../../global/stylesheets/styling";

.divStatus {
  float: left;
  border: 1px solid #e0e7ff;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  cursor: pointer;
  width: 8rem;
  margin-bottom: 1rem;
  position: relative;
  text-align: center;
  font-size: 0.75rem;
  &:hover {
    border: 1px solid $colour-blue-0;
  }
  min-height: 5rem;
}

.divStatusActive {
  composes: divStatus;
  border: 1px solid $colour-blue-0 !important;
}

.headingDiv {
  text-align: left;
  font-size: 0.75rem;
  font-weight: 500;
  text-align: left;
  text-transform: uppercase;
  color: $colour-shade-3;
  letter-spacing: 1px;
}

.input {
  border-radius: 0;
  background-color: $color-input-bg;
  width: 8rem;
}

.rowDiv {
  float: left;
  font-size: 0.75rem;
  font-weight: 500;
  text-align: left;
  text-transform: uppercase;
  color: $colour-shade-3;
  letter-spacing: 1px;
  margin-right: 1rem;
  margin-bottom: 1rem !important;
}

.rowDivDropDown {
  composes: rowDiv;
  width: 15rem;
}

.name {
  font-size: 0.75rem;
  font-weight: 500;
  text-align: left;
  text-transform: uppercase;
  color: $colour-shade-3;
  letter-spacing: 1px;
}

.selectIcon {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  z-index: 1;
}

.saveButton {
  border-radius: 100px !important;
  border: 1px solid $colour-blue-0 !important;
  color: $colour-blue-0 !important;
  background-color: $colour-shade-0;
  border: none;
  padding: 0.5rem;
  padding-left: 3rem;
  padding-right: 3rem;
  border-radius: 6px;
  float: right;
  &:hover {
    background-color: $colour-blue-0 !important;
    color: $colour-shade-0 !important;
  }
}

.inputLarge {
  width: 15rem;
}

.section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;

  div {
  }

  .fullWidth {
    flex: 3;
    max-width: 20rem;
  }

  .midWidth {
    flex: 2;
    max-width: 17.5rem;
  }

  .tickBox {
    flex: 1;
    max-width: 10rem;
  }
}

.page {
  background-color: white;
  //padding: 1rem;
}

.sectionTitle {
  border-bottom: 2px solid $colour-blue-0;
  width: 100%;
  text-align: left;
  margin-bottom: 0.5rem;
  padding-bottom: 0.35rem;
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 1px;
  color: $colour-blue-0;
}

.row {
  display: block;
  flex-wrap: wrap;

  @include tablet {
    display: flex;
    justify-content: space-between;
    margin: 0 -1rem 0.5rem -1rem;
  }

  .column {
    flex: 0 100%;
    margin-bottom: 1rem;
    max-width: 25rem;

    @media (min-width: $widthTablet) {
      flex: 0%;
      padding: 0 15px;
      margin-bottom: 0 !important;
    }
  }

  .columnDetails {
    flex: 0 10%;
  }

  .columnDetailsSize {
    flex: 0 25%;
    min-width: 4rem;
  }

  .columnSmallMaxSize {
    flex: 0;
    min-width: 6.2rem;
    max-width: 6.2rem;

    @include desktop {
      min-width: 8rem;
      max-width: 8rem;
    }
  }

  .columnMinDropdown {
    min-width: 2rem;
    max-width: 12rem;
  }

  .columnSize {
    max-width: 17rem;
  }

  .columnDetailsTickbox {
    flex: 0 50%;
    margin-bottom: 0.4rem;
    text-align: left;

    @include tablet {
      flex: 0 100%;
      max-width: 5rem;
      padding: 0 15px;
    }
  }

  .buttonContainer {
    margin-top: 1rem;
    bottom: 4rem;
    right: 6rem;
  }

  .addButton {
    &:hover {
      cursor: pointer;
    }
  }

  .columnSmall {
    flex: 0 16%;
    padding: 0 15px;
    max-width: 12rem;

    span {
      white-space: nowrap !important;
    }
  }

  .columnShared {
    flex: 0 18%;
    padding: 0 15px;
    padding-right: 0;
    text-align: center;

    input {
      ::-webkit-input-placeholder {
        text-align: center;
      }

      :-moz-placeholder {
        /* Firefox 18- */
        text-align: center;
      }

      ::-moz-placeholder {
        /* Firefox 19+ */
        text-align: center;
      }

      :-ms-input-placeholder {
        text-align: center;
      }
    }
  }

  .columnXSmall {
    flex: 0 100% !important;
    margin-bottom: 1rem;

    @media (min-width: $widthTablet) {
      flex: 0 5%;
      padding: 0 15px;
    }
  }

  .checkbox {
    max-height: 2rem;
  }

  .checkboxPadding {
    margin: -1rem 0 -2rem 0;
  }
}

.rowCheckbox {
  display: flex !important;
  flex: 1 100%;
  min-width: 100%;
  margin-top: 2rem;

  @include tablet {
    margin-top: 0;
  }

  .columnSmall {
    flex: 50%;
    max-width: 6rem;
  }
}

.rowSaveChanges {
  display: block;
  min-height: 2.5rem;

  @include tablet {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin: 0.5rem -1rem;
  }

  .saveFixed {
    position: relative !important;
    display: contents;
  }
}

.rowJustifyLeft {
  composes: row;
  justify-content: flex-start;
}

.checkboxHeight {
  margin: 0;
  display: flow-root;
  min-width: 20rem;
}

.propertyList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @include tablet {
    flex-wrap: nowrap;
    padding: 0 1rem;
  }

  .columnDetails {
    flex-basis: 50%;
    padding: 0.25rem 0.5rem;

    @include tablet {
      flex-basis: 100%;
      padding: 0;
    }
  }
}

.split {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @include tablet {
    flex-direction: row;
  }

  .columnSplit {
    flex: 1;

    .columnSmall {
      display: flex;
    }

    .wrappingColumn {
      flex-wrap: wrap;
    }

    .certificate {
      margin-right: 2rem;
    }

    .featureList {
      display: flex;
      flex-wrap: wrap;

      .columnSmall {
        flex-basis: 50%;

        @include tablet {
          flex-basis: 100%;
        }

        @include desktop {
          flex-basis: 50%;
        }
      }
    }
  }

  .columnSplitSmall {
    max-width: 35rem;
    margin: 1rem;

    @include fullHD {
      margin: 0;
    }
  }
}

.splitFeatures {
  composes: split;
  @include tablet {
    flex-direction: column;
  }

  @include fullHD {
    flex-direction: row;
  }
}

.label {
  float: left;
  font-size: 0.7rem;
  color: $colour-b0bac9;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05rem;
  margin-bottom: 0.25rem;
  display: block;
  width: 100%;
  text-align: left;
}

.regulationLabel {
  margin-top: 1rem;
  margin-bottom: -1rem;

  @include tablet {
    margin: 0;
  }
}

.input {
  border-radius: 5px;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $colour-495057;
  background-color: $color-input-bg;
  background-clip: padding-box;
  border: 1px solid #f0f3ff;
  transition: border-color 0.15s;
}

.smallInput {
  composes: input;
  max-width: 50%;
  width: 50%;
}

.xSmallInput {
  composes: input;
  max-width: 18%;
  width: 18%;
}

.inputFifty {
  composes: input;
  max-width: 50%;
  width: 50%;
}

.addButtonColumn {
  composes: column;
  flex: 0 85% !important;
  padding-right: 0 !important;
}

.list {
  background-color: white;
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(128, 128, 128, 0.13);

  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;

  position: absolute;
  z-index: 1;
  width: auto;
  min-width: 10rem;
  margin-top: -1rem;

  li {
    border-bottom: 1px solid rgba(128, 128, 128, 0.13);
    padding: 0.4rem;

    &:hover {
      cursor: pointer;
      background-color: rgba(128, 128, 128, 0.13);
    }
  }
}

.inputDatePicker {
  z-index: 0;
  width: 100%;
  border: 1px solid #f0f3ff;

  div {
    border: none !important;
  }

  div {
    div {
      width: 125% !important;
      z-index: 5 !important;
    }
  }

  .calendarIcon {
    width: 1.2rem;
  }
}

.success {
  svg {
    margin: 0 auto;
    margin-bottom: 1rem;
  }

  p {
    color: black;
    text-align: center;
  }
}

.associated {
  margin: auto 0.25rem auto 0;
  float: left;

  div {
    min-width: 2rem;
    max-width: 12rem;
  }
}

.addButton {
  margin: auto 0.5rem;
}

.featuresAddButton {
  float: left;
}

.datePicker {
  margin-left: -0.5rem;
  max-width: 15rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.councilColumn {
  display: flex;
}

.councilTooltip {
  margin-left: 0.25rem;
}

.tooltipContent {
  font-size: 0.8rem;
  text-align: left;

  .councilHeader {
    margin-bottom: 0.5rem;
  }

  h1 {
    font-size: 1rem;
  }

  h2 {
    font-size: 1.2rem;
    color: $colour-shade-4;
  }

  p {
    font-size: 0.8rem;
    color: $colour-shade-4;
    font-weight: 500;
    line-height: 0.75rem;
  }

  .links {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;

    img {
      margin-right: 1rem;
      max-width: 4rem;
    }
  }

  .gasIcon {
    height: 3rem;
  }
}

.smallText {
  display: flex;
  flex-direction: row;
  span {
    white-space: nowrap;
  }
  img {
    align-self: center;
    margin: 0.5rem;
    margin-top: 1rem;
  }
}

.regulationSmallText {
  display: flex;
  flex-direction: row;
  margin-top: -0.7rem;
  font-size: 0.7rem;

  span {
    white-space: nowrap;
  }
  img {
    align-self: center;
    margin: 0.5rem;
    margin-top: 1rem;
  }
}

.infoIcon {
  height: 1rem;
}

.dropdown {
  display: flex;
  width: 100%;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  margin-right: 0.5rem;
}

.gasAppliancesRow {
  width: 100%;
}

.expiresColumn {
  display: flex;
}

.fullWidth {
  min-width: 100%;
  float: left;
}

.materialCheckbox {
  @include checkbox;
}
