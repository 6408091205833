@import "../../../../global/stylesheets/colours";
@import "../../../../global/stylesheets/mediaConfig";
@import "../../../../global/stylesheets/styling";

.page {
  margin: 0 auto;
  background-color: $colour-shade-0;

  .content {
    @include tablet {
      padding: 1rem;
    }
  }

  .row {
    display: block;
    flex-wrap: wrap;

    @include tablet {
      display: flex;
      margin: 0 -15px;
      margin-bottom: .5rem;
    }

    .col {
      padding: 0 15px;
      flex: 0 50%;
  
      @include desktop {
        flex: 1 1;
      }
    }

    .thirdCol {
      flex: 0 33.33%;
      padding: 0 15px;
    }
  }

  .label {
    line-height: 0.7rem;
    font-size: 0.7rem;
    color: $colour-shade-2;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.05rem;
    margin-bottom: 0.25rem;
    display: block;
    width: 100%;
    text-align: left;
  }

.input {
  border-radius: 5px;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $colour-shade-4;
  margin-bottom: 0.4rem;
  background-color: $color-input-bg;
  background-clip: padding-box;
  border: 1px solid #f0f3ff;
  transition: border-color 0.15s;
  @media (max-width: $max-widthPhone) {
    width: 100%;
  }

  &::placeholder {
    color: $colour-shade-2;
  }
}


textarea,
.input {
  border-radius: 5px;
  display: block;
  width: 100%;
  height: auto;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $colour-shade-4;
  margin-bottom: 0.4rem;
  background-color: $color-input-bg;
  background-clip: padding-box;
  border: 1px solid #f0f3ff;
  transition: border-color 0.15s;
  @media (max-width: $max-widthPhone) {
    width: 100%;
  }

  &::placeholder {
    color: $colour-shade-2;
  }
}

.sectionTitle {
  border-bottom: 2px solid $colour-blue-0;
  text-align: left;
  padding-bottom: 0.35rem;
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 1px;
  color: $colour-blue-0;
  margin: 1rem;

  @include tablet {
    margin: 0;
    margin-bottom: 1rem;
  }
}

.materialCheckbox {
  @include checkbox;
}
}

.mobileRow {
    display: flex;
    margin: 0 -15px;
    margin-bottom: .5rem;

    .thirdCol {
      flex: 0 33.33%;
      padding: 0 15px;
    }
}

.checkbox {
  flex: 0 0 auto !important;
  font-size: .8rem;

  @include tablet {
    font-size: 1rem;
  }
}

.checkboxColumns {
  flex: 100%;
  padding: 0 15px;

  @include fullHD {
    flex: 0 0 auto;
  }
}

.propertyColumn {
  flex: 1 1;
  padding: 0 15px;
}

.descriptionCol {
  flex: 0 100%;
  padding: 0 15px;
}

.errorMessage {
  color: $colour-error !important;
  font-size: 0.85rem;
  text-align: left;
}

.breadcrumbs {
  text-align: left;
  padding: 0 0 1rem 0;
}