@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.userContactInfo {
  text-align: left;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .column {
    flex-direction: column;
    flex: 0%;
  }
}

.image {
  composes: column;
  flex: 0 !important;
  align-self: center;
}

.name {
  composes: column;
  margin-left: 0.4rem;
  font-size: 0.8rem;
  flex: 0% !important;
}

.typeOrRole {
  position: relative;
  color: white;
  background-color: #0085ca;
  padding: 0.1rem 0.4rem !important;
  border-radius: 1rem;
  text-align: left;
  font-size: 0.8rem;
}

.icon {
  position: absolute;
  height: 0.8rem;
  left: 3px;
  top: 3px;
}

.isUser {
  padding-left: 0.8rem !important;
}
