@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.modal {
  max-width: 25rem;
  overflow: visible;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.25);
}

.heading {
  border-bottom: 2px solid #0085ca;
  width: 100%;
  text-align: left;
  margin-bottom: 0.4rem;
  padding-bottom: 0.2rem;
  font-size: rem;
  color: #0085ca;
  font-weight: bold;
}

.p {
  color: black;
  margin-bottom: 0.4rem;
}

.error {
  color: $colour-error !important;
  font-size: 0.85rem;
  text-align: left;
}

.row {
  display: flex;
  margin: 0 -5px;

  .column {
    flex: 0%;
    padding: 0 5px;
  }

  .columnAuto {
    flex: 0 0 auto;
    padding: 0 5px;
  }
}

.dropdownSpacing {
  margin-top: 1.2rem;
}

.label {
  float: left;
  font-size: 0.7rem;
  color: #b0bac9;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05rem;
  margin-bottom: 0.25rem;
  display: block;
  width: 100%;
  text-align: left;
}

.input {
  border-radius: 5px;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #f9faff;
  background-clip: padding-box;
  border: 1px solid #f0f3ff;
  transition: border-color 0.15s;
}

.btn {
  color: #0085ca;
  border: 1px solid #0085ca;
  border-radius: 1rem;
  background-color: transparent;
  margin-left: 0.4rem;
}

.calculate {
  border: none;
  background-color: orange;
  color: white;
}

.buttons {
  margin-top: 1rem;
  width: 100%;
  text-align: right;
}

.errorMessage {
  color: $colour-error !important;
  font-size: 0.85rem;
  text-align: left;
}

.inputSmall {
  composes: input;
  width: 3rem;
}

.columnThirtyPercent {
  composes: column;
  flex: 0 30% !important;
}

.residentialOnlyMessage {
  color: $colour-blue-0;
}

// This bad using the !important, will update once the RCCurrencyInput component is
// made more flexible to allow custom css
.rcCurrencyInput {
  input {
    width: 60px !important;
    height: calc(1.5em + 0.75rem + 2px) !important;
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #495057 !important;
    border-radius: 0px !important;
    display: inline-block !important;
    -webkit-appearance: none !important;
    border-radius: 0.35rem !important;
    background-color: #f9faff !important;
    border: 1px solid #f0f3ff !important;
    text-align: left !important;
  }
}