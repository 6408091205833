@import '../../../../global/stylesheets/colours';
@import '../../../../global/stylesheets/mediaConfig';
@import '../../../../global/stylesheets/styling';
@import '../../../../global/stylesheets/functions';
@import '../../../../global/stylesheets/components/button/index.scss';
@import '../../../../global/stylesheets/components/button/variables.scss';

@mixin iconSize {
    border-radius: 100%;
    width: px-to-rem($button-height);
    height: px-to-rem($button-height);
    text-align: center;
    display: block;
    min-width: 0;
}

.button {
    align-self: center;
    padding: 0;
    @include iconSize;

    @include tablet {
        padding: px-to-rem(1) px-to-rem(6);
        @include baseButton($icon-button-width);
    }

    &.submit {
        @include saveButton;
    }

    &.cancel {
        @include cancelButton;
    }

    &.action {
        @include actionButton;
    }

    &.default {
        @include cancelButton;
    }

    &.full {
        width: 100%;
    }

    &.center {
        margin: 0 auto;
        text-align: center;
    }

    &.iconOnly {
        @include iconSize;

        .content {
            display: block;
            text-align: center;

            .icon {
                padding: 0;
            }
        }
    }

    .content {
        display: block;

        @include tablet {
            display: flex;
            justify-content: space-around;
        }

        .icon {
            @include tablet {
                padding-left: 0.25rem;
                align-self: center;
                flex: 0 0 auto;
            }

            img {
                display: block;
                margin: 0 auto;
            }

            &.left {
                transform: rotateX(180);
            }

            &.up {
                transform: rotateX(-90);
            }
            &.down {
                transform: rotateX(90);
            }
        }

        .text {
            align-self: center;
            flex: auto;
            display: none;
            padding: 0 0.25rem;

            @include tablet {
                display: block;
            }
        }
    }
}
