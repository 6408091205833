@import './colours';
@import './mediaConfig';
@import './functions.scss';

// Headings
$heading1: 4rem;

@mixin cardTitle() {
    text-align: left;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 500;
    color: $colour-shade-3;
    letter-spacing: 1px;
}

@mixin buttonStyle() {
    border-radius: 20rem !important;
    border: 1px solid $colour-blue-0 !important;
    color: $colour-blue-0 !important;
    background-color: $colour-shade-0;
    border: none;
    padding: 0.2rem 0.5rem;

    &:hover {
        background-color: $colour-blue-0 !important;
        color: $colour-shade-0 !important;

        .addPropertyText {
            color: $colour-shade-0 !important;
        }
    }
}

@mixin checkbox() {
    color: $colour-blue-0 !important;
}

@mixin hoverOver() {
    &:hover {
        cursor: pointer;
        background-color: $color-input-bg;
    }
}
