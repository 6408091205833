@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.errorMessage {
  color: $colour-error !important;
  font-size: 0.85rem;
  text-align: left;
}

.modal {
  @media (min-width: $widthTablet) {
    max-width: 50%;
    max-width: none;
    width: 50%;
  }

  width: 100%;
  padding: 0;

  button {
    top: 8px;
    right: 8px;

    svg {
      fill: white;
    }
  }
}

.overlay {
  background: rgba(0, 0, 0, 0.25);
  z-index: 2;
}

.buttons {
  padding: 1rem;
  padding-top: 2rem;
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}

.modalBody {
  color: $colour-shade-5;
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.4rem 1rem 0.4rem 1rem;
}

.content {
  background-color: white;
  margin: 0.4rem;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  padding: 1rem;
}

.formGroup {
  margin-bottom: 1rem !important;
}

.success {
  p {
    text-align: center;
    color: black;
    margin: 0;
  }
}

.column {
  flex: 0 100%;

  @media (min-width: $widthTablet) {
    flex: 0%;
  }
}

.label {
  line-height: 0.7rem;
  font-size: 0.7rem;
  color: $colour-shade-2;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05rem;
  margin-bottom: 0.25rem;
  display: block;
  width: 100%;
}

textarea,
.input {
  border-radius: 5px;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $colour-495057;
  margin-bottom: 0.4rem;
  background-color: $color-input-bg;
  background-clip: padding-box;
  border: 1px solid #f0f3ff;
  transition: border-color 0.15s;
  @media (max-width: $max-widthPhone) {
    width: 100%;
  }

  &::placeholder {
    color: $colour-b0bac9;
  }
}

.description {
  textarea {
    height: 6rem;
  }
}

.icon {
  display: none;
  align-self: center;
  justify-self: center;

  img {
    max-width: 2rem;
  }

  @media (min-width: $widthTablet) {
    display: block;
  }
}

.modelHeader {
  color: $colour-shade-4;
  font-weight: 500;
  margin-bottom: 0px !important;
  border-bottom: 2px solid $colour-shade-4;
  padding-bottom: 0.4rem;
  font-size: 1.4rem;
}

.buttonDiv {
  text-align: right;

  @media (min-width: $min-widthTablet) {
    width: 600px;
  }
}

.heading {
  background-color: $colour-blue-0;
  color: white;
  padding: 0.4rem;
  font-size: 1.2rem;
}

.row {
  display: flex;
  flex-direction: column;
  margin: 0 -15px;
  margin-bottom: 0.4rem;

  @include desktop {
    flex-direction: row;
  }
}

.column {
  flex: 0 100%;
  padding: 0 15px;

  @include desktop {
    flex: 0 50%;
  }
}

.textarea {
  height: 5rem;
}

.success {
  text-align: center;
  color: black;
}

.sharingOptions {
  margin-top: 1rem;
}

.sharingRow {
  composes: row;
  max-width: 50%;
  margin-bottom: 0;

  label {
    margin: 0;
  }
}

.chatInput {
  textarea {
    background-color: #f9faff;
  }
}

.contactProperty {
  color: green;
  text-transform: none;
  font-size: 0.6rem;
}
