@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/mediaConfig";

.simpleRouterTabs {
  width: 100%;
  display: block;
  height: 100%;

  nav {
    width: 100%;
    float: left;
    height: 100%;
    padding: 0px;
    font-size: 0.8rem;

    ul {
      padding: 0;
      margin: 0;
      height: 100%;
      display: block;
      justify-content: space-between;
      overflow-y: hidden;
      overflow-x: auto;
      
      @media (max-width: $widthTablet) {
        display: flex;
        flex-direction: row;
      }

      li {
        color: $colour-707070;
        text-transform: uppercase;
        height: 100%;
        padding: .5rem;

        flex: 1;
      }

      .li {
        float: left;
        list-style-type: none;
        margin: 0;
        cursor: pointer;
        pointer-events: auto;
        font-weight: bold;
        letter-spacing: 1.25px;
      }

      .activeLi {
        position: relative;
        float: left;
        list-style-type: none;
        margin: 0;
        font-weight: bold;
        letter-spacing: 1.25px;
        margin-bottom: -0.4rem;
        background-color: #F1F1F1;
      }

      .link {
        color: $colour-707070;

        &:hover {
          text-decoration: none;
          color: $color-tabHover;
        }

        .desktop {
          display: block;

          img {
            margin-bottom: 0.2rem;
            margin-right: 0.2rem;
          }
        }

        .mobile {
          display: none;
          flex-direction: column;

          img {
            margin: 0 auto 0.4rem;
            width: 2rem;
            height: 2rem;
          }
        }

        @media (max-width: $widthTablet) {
          .desktop {
            display: none;
          }

          .mobile {
            display: flex;
          }
        }
      }

      .li,
      .activeLi {
        word-wrap: nowrap;
        width: auto;
        margin-bottom: 0;

        @media (max-width: $max-widthPhone) {
          padding: 0.25rem;
        }
      }
    }
  }
}

.disabledLi {
  composes: li;
  pointer-events: none;
  color: rgba(112, 112, 112, 0.22) !important;
  opacity: 0.3;

  .link {
    pointer-events: none;
  }
}

.icon {
  padding: 0.4rem;
  padding-top: 0;
  padding-left: 0;
  max-width: 25px;
  min-width: 25px;
  max-height: 25px;
  min-height: 25px;

  @media (max-width: $max-widthPhone) {
    display: block;
    margin: 0 auto;
    padding: 0;
    margin-bottom: 0.25rem;
  }
}
