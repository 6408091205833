@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/mediaConfig";

.header {
  min-height: 1.5rem;
}

.headerTitle {
  text-align: left;
  color: $colour-header-0;
  font-weight: 500;
  margin-bottom: 0px !important;
  border-bottom: 2px solid $colour-header-0;
  padding-bottom: 0.4rem;
  font-size: 1.4rem;
  margin-top: 0;
  padding-top: 0.4rem;
}

.navigation {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .tab {
    width: 3rem;
  }

  .seperator {
    width: 6rem;
    min-width: 5rem;
    height: 2px;
    min-height: 2px;
    margin: 1rem -0.3rem auto -0.3rem;
    z-index: -1;
  }

  .name {
    display: flex;
    margin: 0 -1.5rem;
  }
}

.shown {
  display: contents;
  padding-bottom: 4rem;
  min-height: 100%;

  @include desktop {
    padding-bottom: 0;
  }
}

.hidden {
  display: none;
}

.formWrapper {
  //padding-bottom: 4rem;
  padding-top: 0.6rem;
}

.selected {
  border-top: solid #01bb20 2px;
}

.unselected {
  border-top: solid #dedede 2px;
}

.tick {
  position: relative;
  margin: -1.75rem 1rem 1rem 1rem;
}

.page {
  display: flex;
  flex-direction: column;
  height: auto;
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
  width: 100%;
  min-height: 4rem;
  float: right;
  background-color: $colour-shade-0;
  z-index: auto;
  margin-top: auto;
  margin-bottom: 0;
  bottom: 0;
}

.button {
  width: 35%;
  border-radius: 0;
  padding: 0.5rem;
  font-weight: 600;
  margin-top: 0.5rem;
}

.navButton {
  border: 0;
  border-radius: 1.5rem;
  z-index: 999 !important;
}

.backButton {
  composes: navButton;
  float: left;
  background-color: $colour-shade-0;
  color: $colour-blue-0;
  border: solid 2px $colour-blue-0;

  &:hover {
    background-color: $colour-blue-0;
    color: $colour-shade-0;
    border: solid 2px $colour-blue-0;

    span {
      color: $colour-shade-0;
    }

    a {
      color: $colour-shade-0;
    }
  }
}

.nextButton {
  composes: navButton;
  float: right;
  background-color: $colour-blue-0;
  border: solid 2px $colour-shade-0;
}

.tooltipContent {
  font-size: 0.8rem;
  text-align: left;

  .links {
    img {
      margin-right: 1rem;
    }
    display: inline-block;
    padding: 0.5rem;
  }

  .gasIcon {
    height: 3rem;
  }
}

.infoIcon {
  margin-left: 0.5rem;
  margin-bottom: 0.1rem;
}

.close {
  position: absolute;
  top: -0.5rem;
  right: 1rem;
}

.h1 {
  color: $colour-shade-5;
  margin-bottom: 0 !important;
  font-size: 1rem;
  text-align: center;
}

.error {
  color: $colour-error;
  font-size: 0.8rem;
  text-align: left;
  min-width: 100%;
}
