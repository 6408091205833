@import url("https://cdn.syncfusion.com/ej2/material.css");

.toast {
    :global {
        .e-toast {
            display: block;
        }

        .e-toast-message {
            width: auto !important;
      }
    }
}