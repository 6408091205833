@import '../../colours';
@import '../../mediaConfig';
@import '../../functions.scss';

@mixin h1() {
    color: $colour-shade-2;
    font-weight: 500;
    text-transform: none;
    margin-bottom: 1rem;
    font-size: 1.25rem;
}
