@import "../../../../../global/stylesheets/colours";

.generalPage {
  background-color: $colour-shade-0;
  margin-bottom: 1rem;
}

.label {
  text-align: left;
  font-size: 0.7rem;
  color: $colour-shade-2;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05rem;
  margin-bottom: 0.25rem;
  width: 100%;
}

.container {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.input {
  padding: 0.45rem;
  border-radius: 0;
  background-color: $colour-shade-0;
  width: 100%;
  text-align: left;
  font-weight: 500;
  color: $colour-shade-5;
  border: 1px solid #ced4da;
}

.title {
  margin-top: 1rem;
  font-size: 1.65rem;
}

.disabled {
  composes: input;
  pointer-events: none;
  opacity: 0.45;

  &:hover {
    cursor: not-allowed;
  }
}

.buttons {
  button {
    float: left;
    width: 9rem;
  }
}

.goBack {
  padding: 0.4rem;
  font-size: 0.8rem;
  text-align: left;
  color: $colour-shade-5;

  &:hover {
    cursor: pointer;
  }

  img {
    max-width: 0.85rem;
  }
}
