@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/mediaConfig";

.whitelabel {
    background-color: #E8F4FA;

    @include tablet {
        padding: 1rem;
        padding-right: 2rem;
    }

    .content {
        margin-top: 6rem;

        @include tablet {
            margin-top: 4rem;
            padding: 1rem;
        }
    }

.card {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}
}