@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.container {
  //margin: 0 !important;
}

.routedPage {
  //background-color: $colour-shade-0;
  //box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  height: 100%;
  //padding: 0 0px 0 0px;
}

.nestedPropertyDashboard {
  //padding: 5px 10px 0 10px;
  //background-color: #fff !important;
}

.tabbedNav{
  //padding-left: 20px;
}
