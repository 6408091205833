@import "../../../global/stylesheets/colours";

.userItem {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  text-align: left;

  background-color: $colour-shade-0;

  &:hover {
    cursor: pointer;
  }
}

.itemRow {
  &:hover {
    cursor: pointer;
  }
}

.icons {
  img {
    padding-left: 2.5rem;
  }
}

.label {
  text-align: left;
  font-size: 0.7rem;
  color: $colour-shade-2;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05rem;
  margin-bottom: 0.25rem;
  width: 100%;
}
