@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/styling";

.item {
  margin-top: 0.3rem;
  text-align: left;

  &:hover {
    cursor: pointer;
  }
}

.name {
  div {
    display: inline-block;
    margin-left: 0.4rem;
  }
}

.addEmail {
  display: block;
  font-size: 0.8rem;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.buttons {
  flex: 0 100%;
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;
  display: flex;

  @include desktop {
    flex: 0 15.5%;
    justify-content: center;
    justify-items: center;
    justify-self: center;
    align-content: center;
    align-items: center;
    align-self: center;
  }
}

.invitationStatus {
  text-align: left;
  flex: 0 100%;
  margin-bottom: 1rem;
  align-self: center;
  margin-bottom: 0;
  margin-top: 1rem;
  @include desktop {
    flex: 0 25%;
  }
}

.label {
  color: $colour-b0bac9;
  font-weight: 500;
  font-size: 0.8rem;
  text-align: left;
}

.lastActivity {
  flex: 0 15%;
  align-self: center;
}

.inviteButton {
  align-self: center;

  button {
    float: none;
  }
}

.image {
  align-self: center;
  flex: 0 5%;

  div {
    position: relative !important;
    margin: 0 !important;
  }
}

.value {
  text-align: left;
  margin-bottom: 1rem;
  @include desktop {
    margin-bottom: 0;
  }

  p {
    font-size: 0.8rem;
    color: black;
    margin-bottom: 0;
    line-height: 1.4rem;
  }

  button {
    font-size: 0.8rem;
    width: auto;
    text-align: center;
    padding: 0.4rem;
    font-weight: bold;
  }
}

.itemButtons {
  flex: 0 33%;
  margin-bottom: 0.25rem;
  padding: 0;
  display: inline;

  img {
    cursor: pointer;
  }
}

#emailValue {
  @media (max-width: $max-widthTablet) {
    margin-right: 0.7rem;
  }
}

.avatar {
  margin: auto;
  width: 2rem !important;
  height: 2rem !important;
  background-color: $colour-c4d0f6 !important;
  position: absolute !important;
  font-size: 1rem !important;

  img {
    width: 2rem !important;
    height: 2rem !important;
  }
}

.contactName {
  cursor: pointer;
  align-self: center;
  font-size: 1.4rem;
  margin-right: 0.5rem;
}

.centerAligned {
  text-align: left;
  align-self: center;
}

.nameDescription {
  flex: 0 100%;
  align-self: center;
  margin-top: 1rem;

  @include desktop {
    flex: 0 15%;
  }
}

.statusFloat {
  position: absolute;
  top: -0.8rem;
  right: 6px;
  width: auto;
  padding-bottom: 0;
  background-color: red;
  padding: 0.1rem 0.1rem;
  min-width: 5rem;
  color: white;
  border-radius: 2rem;
  font-size: 0.7rem;
}

.new {
  background-color: #fe8686;
}

.inProgress {
  background-color: #42ca00;
}

.completed {
  background-color: #090808;
}

.bottomRight {
  position: relative;
  font-size: 0.6rem;
  color: $colour-shade-5;
  width: 100%;
  padding-bottom: 1rem;

  span {
    display: inline;
  }

  @media (min-width: $widthTablet) {
    position: absolute;
    bottom: 0;
    right: 5px;
    width: auto;
    padding-bottom: 0;
  }

  span {
    display: inline !important;
  }
}

.moreMenuIcon,
.pointer,
.deleteIcon {
  &:hover {
    cursor: pointer;
  }
}

.successIcon {
  margin-top: 1.5rem;
  margin-left: 3rem;
}

.failureIcon {
  margin-top: 1.5rem;
}

.tooltipBody {
  padding: 0.5rem;
  margin: 0.5rem;
  text-align: left;
  margin-top: 0;
  padding-top: 0;
  p {
    color: $colour-shade-5 !important;
    margin-bottom: 0;
  }
}

.title {
  color: #0085ca;
  font-size: 1rem;
  font-weight: bold;
}

.btns {
  flex: 0 20%;
}

.row {
  display: flex;
  flex-wrap: wrap;

  .column {
    flex: 100%;
  }

  .columnSmall {
    flex: 0%;
  }
}

.description {
  color: #818385;
  font-size: 0.8rem;
  line-height: 0.75rem;
}

.listItem {
  position: relative;
  &:first-of-type {
    margin-top: 0;
  }

  margin-bottom: 1rem;
  padding: 1rem;
  padding-top: 0;
  padding-bottom: 0;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.07058);
  border: 1px solid #dcdcdc;

  @include tablet {
    margin: 1rem;
  }

  p {
    margin-bottom: 0;
  }

  &.active {
    border-left: 6px solid #0085ca;
  }

  &:hover {
    box-shadow: 0px 5px 8px #00000029;
  }

  .highPriority {
    position: absolute;
    top: -8px;
    right: 6rem;
    padding-bottom: 0;
    padding: 0.1rem 0.1rem;
    color: white;
    border-radius: 2rem;
    width: 1rem;
    background: #fe8686 0% 0% no-repeat padding-box;
    border-radius: 10px;
  }
}

.addedBy {
  color: black;
  font-size: 0.6rem;
  line-height: 0.75rem;
}

.noMargin {
  margin: 0;
}

.buttonColumn {
  flex: 0 100%;
}

.desktopToggle {
  display: none;

  @include tablet {
    display: block;
  }
}

.mobileToggle {
  @include tablet {
    display: none;
  }
}

.addedContext {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.contextMenu {
  display: flex;
}
