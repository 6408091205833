@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/styling";

.fileRow {
    display: flex;
    flex-wrap: wrap;
    margin: .25rem 0;

    label {
        margin-bottom: 0;
    }

    :global {
        .rc__control {
            border: 1px solid #eaeefd !important;
        }
        .rc__control, .rc__value-container {
            height: calc(1.5em + 0.75rem + 2px) !important;
            //border: 1px solid #eaeefd;
        }
    }

    .clearIcon {
        padding: .5rem;

        &:hover {
            cursor: pointer;
        }

        &.disabled {
            cursor: not-allowed;
            pointer-events: none;
        }
    }

    .hidden {
        opacity: 0;
        visibility: hidden;
    }

    .fileItem {
        display: flex;
        flex: 100%;
        background-color: #f9f9f9;
        border: 1px solid #cbcbcb;
        margin-bottom: 1rem;
        position: relative;

        .innerColumn {
            align-self: center;
            flex: auto;
            display: inline-block;
            margin: 0 .25rem;
            padding-bottom: .25rem;

            @include tablet {
                display: block;
                margin: 0 .5rem;
                padding-bottom: 0;
            }
        }

        .status {
            display: block;
        }

        .mobileClear {
            display: inline-block;
            position: absolute;
            top: 0;
            right: 0;

            @include tablet {
                display: none;
            }
        }

        .delete {
            display: none;

            @include tablet {
                display: block;
            }
        }

        .autoColumn {
            align-self: center;
            flex: 0;
        }

        .innerRow {
            display: block;
            margin: 0.25rem;

            @include tablet {
                display: flex;
            }
            
            input {
                width: 100%;
                border-radius: 5px;
                display: block;
                width: 100%;
                height: calc(1.5em + 0.75rem + 2px);
                padding: 0.375rem 0.75rem;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: $colour-495057;
                background-color: #f9faff;
                background-clip: padding-box;
                border: 1px solid #eaeefd;
                transition: border-color 0.15s;
            }

            .dropdown {
                flex: 25%;
                align-self: center;
            }

            .innerColumn {
                flex: auto;
                align-self: center;

                &.success {
                    color: $color-green-1;
                }

                &.failure {
                    color: red;
                }

                progress {
                    height: 1rem;
                    border-radius: 0;
                    border: 1px solid #3c85ca;
                  }
                  progress::-webkit-progress-bar {
                    background-color: white;
                  }
                  progress::-webkit-progress-value {
                    background-color: #3c85ca;
                  }
                  progress::-moz-progress-bar {
                    /* style rules */
                  }
            }
        }
    }
}

.upload {
    :global {
        .template_wrapper .e-file-select-wrap {
            display: none;
        }
        
        .template_wrapper .e-upload {
            float: none;
            border: none;
        }
    }
}

.filesRow {
    ul {
        list-style: none;
    }
}

.dropArea {
    padding: 1rem;
    text-align: center;
    border: 2px dashed #cbcbcb;
}

.upload {

.buttonRow {
    display: flex;
    margin-top: 1rem;
    justify-content: flex-end;

    .buttonColumn {
        flex: 0 0 auto;
        align-self: center;

        margin-right: 1rem;

        &:last-of-type{
            margin-right: 0;  
        }
    }
}

  .row {
    display: flex;

    .column {
      flex: 100%;
      align-self: center;
    }

    .buttonColumn {
        flex: 0 0 auto;
        align-self: center;
    }
  }
    
  .panelStyle {
    button {
      display: inline-block
    }
  }

  :global {
    .control_wrapper .imagepreview {
      max-width: 505px;
      margin: auto;
  }
  .imagepreview #dropArea .e-upload .e-upload-files {
    text-align: initial;
    border-top: none;
  }
  .imagepreview #dropArea .e-upload .e-upload-files .e-upload-file-list {
    border: 0;
    display: grid;
    width: 165px;
  }
  .imagepreview .e-file-select-wrap {
      display: none;
  }
  .imagepreview .e-upload {
      border: none;
      margin-top: 10px;
      width: 100%;
  }
  .imagepreview #dropimage {
      font-size: 14px;
  }
  .e-bigger .imagepreview #dropimage {
      font-size: 15px;
  }
  .control-pane #uploadpreview {
      overflow: initial;
  }
  .imagepreview #dropArea {
      border: 1px dashed #c3c3cc;
      position: relative;
      text-align: center;
      padding-top: 20px;
      display: block;
      width: 100%;
      overflow: hidden;
  }
  .imagepreview #dropArea .e-upload .e-upload-files {
      text-align: initial;
      border-top: none;
  }
  .imagepreview #dropArea .e-upload-files .e-file-delete-btn.e-icons,
  .imagepreview #dropArea .e-upload-files .e-file-remove-btn.e-icons {
      top: 120px;
      background-color: white;
      border-radius: 50%;
      font-size: 12px;
      left: 80px;
  }
  .imagepreview #dropArea .e-upload-files li .e-file-remove-btn.e-icons.e-upload-icon {
      font-size: 14px;
      left: 20px;
  }
  .imagepreview #dropArea .e-upload-files li:hover .e-icons {
      visibility: visible;
  }
  .imagepreview #dropArea .e-upload-files li .e-icons {
      visibility: hidden;
  }
  @font-face {
      font-family: 'Uploader_Icon';
      src:
      url(data:application/x-font-ttf;charset=utf-8;base64,AAEAAAAKAIAAAwAgT1MvMj1tSfIAAAEoAAAAVmNtYXDnEOdVAAABiAAAADZnbHlmoZcPvgAAAcgAAABAaGVhZBLQTSUAAADQAAAANmhoZWEINQQDAAAArAAAACRobXR4CAAAAAAAAYAAAAAIbG9jYQAgAAAAAAHAAAAABm1heHABDgAdAAABCAAAACBuYW1lQySinQAAAggAAAIxcG9zdLfl0usAAAQ8AAAAMgABAAAEAAAAAFwEAAAAAAAD2AABAAAAAAAAAAAAAAAAAAAAAgABAAAAAQAA2vKJUF8PPPUACwQAAAAAANftBBgAAAAA1+0EGAAAAAAD2AP4AAAACAACAAAAAAAAAAEAAAACABEAAgAAAAAAAgAAAAoACgAAAP8AAAAAAAAAAQQAAZAABQAAAokCzAAAAI8CiQLMAAAB6wAyAQgAAAIABQMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAUGZFZABA5wDnAAQAAAAAXAQAAAAAAAABAAAAAAAABAAAAAQAAAAAAAACAAAAAwAAABQAAwABAAAAFAAEACIAAAAEAAQAAQAA5wD//wAA5wD//wAAAAEABAAAAAEAAAAAAAAAIAAAAAIAAAAAA9gD+AAHABAAADchESMVITUjEzM3ETMRFzMBKAOwPvzMPp1mtUC1Zv7FCAF6vb0BO7X+EAHwtQE7AAAAABIA3gABAAAAAAAAAAEAAAABAAAAAAABAAgAAQABAAAAAAACAAcACQABAAAAAAADAAgAEAABAAAAAAAEAAgAGAABAAAAAAAFAAsAIAABAAAAAAAGAAgAKwABAAAAAAAKACwAMwABAAAAAAALABIAXwADAAEECQAAAAIAcQADAAEECQABABAAcwADAAEECQACAA4AgwADAAEECQADABAAkQADAAEECQAEABAAoQADAAEECQAFABYAsQADAAEECQAGABAAxwADAAEECQAKAFgA1wADAAEECQALACQBLyBVcGxvYWRlclJlZ3VsYXJVcGxvYWRlclVwbG9hZGVyVmVyc2lvbiAxLjBVcGxvYWRlckZvbnQgZ2VuZXJhdGVkIHVzaW5nIFN5bmNmdXNpb24gTWV0cm8gU3R1ZGlvd3d3LnN5bmNmdXNpb24uY29tACAAVQBwAGwAbwBhAGQAZQByAFIAZQBnAHUAbABhAHIAVQBwAGwAbwBhAGQAZQByAFUAcABsAG8AYQBkAGUAcgBWAGUAcgBzAGkAbwBuACAAMQAuADAAVQBwAGwAbwBhAGQAZQByAEYAbwBuAHQAIABnAGUAbgBlAHIAYQB0AGUAZAAgAHUAcwBpAG4AZwAgAFMAeQBuAGMAZgB1AHMAaQBvAG4AIABNAGUAdAByAG8AIABTAHQAdQBkAGkAbwB3AHcAdwAuAHMAeQBuAGMAZgB1AHMAaQBvAG4ALgBjAG8AbQAAAAACAAAAAAAAAAoAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIBAgEDAAhVcGxvYWRlcgAAAAA=) format('truetype');
      font-weight: normal;
      font-style: normal;
  }
  .imagepreview #dropArea .e-upload .e-upload-files .e-icons.e-upload-icon{
      font-family: 'Uploader_Icon';
      speak: none;
      font-size: 16px;
      left: 20px;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }
  .imagepreview #dropArea .e-upload .e-upload-files .e-icons.e-upload-icon::before{
      content: '\e700';
  }
  .imagepreview #dropArea .e-upload .e-upload-files .e-icons:not(.e-uploaded):hover{
      background-color: #e6e6e6;
      border-color: #adadad;
      color: #333;
  }
  .highcontrast .imagepreview #dropArea .e-upload-files .e-file-remove-btn.e-icons,
  .highcontrast .imagepreview #dropArea .e-upload-files .e-file-delete-btn.e-icons {
      background-color: #ffd939;
  }
  .highcontrast .imagepreview #dropArea .e-upload .e-upload-files .e-file-delete-btn.e-icons::before,
  .highcontrast .imagepreview #dropArea .e-upload .e-upload-files .e-file-remove-btn.e-icons::before{
      color: black;
  }
  .imagepreview #dropArea .e-upload .e-upload-files .e-upload-file-list {
      border: 0;
      display: inline-block;
      width: 165px;
  }
  .imagepreview .upload-image {
      width: 150px;
      height: 150px;
      display: inline-flex;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin: 7px;
      text-align: center;
      line-height: 10;
      border-radius: 5px;

      &:after {
        content: "";
        position: absolute;
        top: 6px;
        left: 6px;
        width: inherit;
        height: inherit;
        color: transparent;
        border-radius: 5px;
    }
  }
  .imagepreview .upload-image
  .highcontrast .imagepreview div.file-name {
      color: white;
  }
  .imagepreview div.file-name {
        color: rgba(0, 0, 0, 0.87);
        font-size: 14px;
        padding: 3px 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 90%;
        white-space: nowrap;
  }
  .highcontrast .imagepreview div.file-size {
      color: white;
  }
  .imagepreview div.file-size, .imagepreview div.file-status {
        font-size: 14px;
        padding: 3px 10px;
        overflow: hidden;
  }
  .imagepreview .progressbar {
      background: #ff4081;
      border: none;
      border-radius: 10px;
      height: 4px;
      margin-left: 7px;
      width: 90%;
      top: -60px;
      position: relative;
  }
  .imagepreview #dropArea progress {
      border: none;
      background: #fff;
      margin-left: 8px;
  }
  .highcontrast .imagepreview #dropArea progress {
      border: none;
      background: black;
  }
  .imagepreview progress::-webkit-progress-bar {
      border: none;
      background-color: #ffffff;
  }
  .highcontrast .imagepreview progress::-webkit-progress-bar {
      border: none;
      background-color: #000000;
  }
  .material .imagepreview progress::-webkit-progress-value {
      border-radius: 2px; 
      background-color: #ff4081;
  }
  .bootstrap .imagepreview progress::-webkit-progress-value {
      border-radius: 2px; 
      background-color: #1f496e;
  }
  .fabric .imagepreview progress::-webkit-progress-value {
      background-color: #1763ff;
      border-radius: 2px; 
      top: -66px;
  }
  .highcontrast .imagepreview progress::-webkit-progress-value {
      background-color: #ffd939;
      border-radius: 2px; 
  }
  .material .imagepreview progress::-moz-progress-bar {
      border-radius: 2px; 
      background-color: #ff4081;
  }
  .bootstrap .imagepreview progress::-moz-progress-bar {
      border-radius: 2px; 
      background-color: #1f496e;
  }
  .fabric .imagepreview progress::-moz-progress-bar {
      background-color: #1763ff;
      border-radius: 2px; 
      top: -66px;
  }
  .highcontrast .imagepreview progress::-moz-progress-bar {
      background-color: #ffd939;
      border-radius: 2px; 
  }
  .material .imagepreview #dropimage a {
      color:#ff4081;
  }
  .fabric .imagepreview #dropimage a {
      color: #1763ff;
  }
  .bootstrap .imagepreview #dropimage a {
      color: #1f496e;
  }
  .highcontrast .imagepreview #dropimage a {
      color: #ffd939;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .imagepreview .dropTarget .e-upload .e-upload-files .e-file-remove-btn.e-icons, .e-bigger .imagepreview .dropTarget .e-upload .e-upload-files .e-file-remove-btn.e-icons {
       padding: 18px 25px 18px 12px;
    }
  }
  }
}

.uploadButton {
    text-align: center;
    padding: .2rem 1rem;
    border-radius: 300px;
    border: none;
    width: auto;
    border: none;
    background-color: $colour-blue-0;
    color: white;
}

.cancelButton {
    text-align: center;
    padding: .2rem 1rem;
    border-radius: 300px;
    width: auto;
    background-color: transparent;
    color: $colour-blue-0;
    border: 1px solid $colour-blue-0;
}

.browse {
    cursor: pointer;
}