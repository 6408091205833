@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/mediaConfig";

.userItem {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  text-align: left;

  img {
    max-height: 2rem;
    padding: 0;
  }

  &:hover {
    cursor: pointer;
  }
}

.itemRow {
  &:hover {
    cursor: pointer;
  }
}

.icons {
  img {
    padding-left: 2.5rem;
  }
  @media (max-width: $max-widthPhone) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.label {
  text-align: left;
  font-size: 0.7rem;
  color: $colour-shade-2;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05rem;
  margin-bottom: 0.25rem;
  width: 100%;
  @media (max-width: $max-widthPhone) {
    margin-top: 1rem;
  }
}
