@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.alert {
  display: none;

  @include tablet {
    display: block;
  }

  color: white;
  padding: .4rem;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);

  &.isScrolled {
    position: fixed;
    width: 100%;
    z-index: 2;
    margin-top: -2rem;
  }

  &.isSuccess {
    background-color: #2ecc71;
  }

  &.isError {
    background-color: #e74c3c;
  }
}