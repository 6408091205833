@import "../../../../../global/stylesheets/colours";

.page {
  width: 100%;
}

.documentList {
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;

  li {
    margin-bottom: 2rem;
    text-align: left;
    color: $colour-707070;
    img {
      max-width: 2rem;
      margin-right: 1rem;
    }
  }
}

.add {
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 0.6rem;
  color: #000;

  &:hover {
    cursor: pointer;
  }
}