@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.dateRangePicker {
  display: flex;
  flex: 0 100%;
  margin-left: 1rem;

  @include mobileLandscape {
    flex: 0 50%;
    min-width: 11.5rem;
    max-width: 11.5rem;
  }

  @include desktop {
    min-width: 12rem;
  }
}
