@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/styling";

.item {
  margin-top: 0.3rem;

  &:hover {
    cursor: pointer;
  }
}

.name {
  div {
    display: inline-block;
    margin-left: 0.4rem;
  }
}

.addEmail {
  display: block;
  font-size: 0.8rem;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.label {
  color: $colour-b0bac9;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.8rem;
  text-align: left;
}

.lastActivity {
  flex: 0 15%;
  align-self: center;
}

.inviteButton {
  align-self: center;

  button {
    float: none;
  }
}

.image {
  align-self: center;
  flex: 0 5%;

  div {
    position: relative !important;
    margin: 0 !important;
  }
}

.value {
  text-align: left;
  margin-bottom: 1rem;

  @include desktop {
    margin-bottom: 0;
  }

  p {
    font-size: 0.8rem;
    color: black;
    margin-bottom: 0;
    line-height: 1.4rem;
  }

  button {
    font-size: 0.8rem;
    width: auto;
    text-align: center;
    padding: 0.4rem;
    font-weight: bold;
  }
}

.itemButtons {
  flex: 1;
  margin: 0 0.2rem;
  padding: 0;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  padding-top: 0.8rem;

  img {
    cursor: pointer;
    margin: 0 auto;
    @include square(1.9rem);
  }
}

#emailValue {
  @media (max-width: $max-widthTablet) {
    margin-right: 0.7rem;
  }
}

.avatar {
  margin: auto;
  width: 2rem !important;
  height: 2rem !important;
  background-color: $colour-c4d0f6 !important;
  position: absolute !important;
  font-size: 1rem !important;

  img {
    width: 2rem !important;
    height: 2rem !important;
  }
}

.contactName {
  cursor: pointer;
  align-self: center;
  font-size: 1.4rem;
  margin-right: 0.5rem;
}

.userContactInfo {
  align-self: center;
  flex: 0 53%;

  @include mobileLandscape {
    flex: 0 100%;
  }

  @include tablet {
    flex: 0 55%;
  }

  @include desktop {
    flex: 25%;
  }

  .name {
    font-size: 0.8rem;
  }

  .typeOrRole {
    font-size: 0.8rem;
    display: block;
  }

  .userIcon {
    height: 0.8rem;
  }
}

.centerAligned {
  text-align: left;
  align-self: center;
  flex: 0 47%;

  @include mobileLandscape {
    flex: 0 100%;
  }

  @include tablet {
    flex: 0 45%;
  }

  @include desktop {
    flex: 0 25%;
  }
}

.invitationStatus {
  text-align: left;
  flex: 0 50%;
  align-self: center;

  @include mobileLandscape {
    flex: 0 100%;
  }

  @include tablet {
    flex: 0 50%;
  }

  @include desktop {
    flex: 0 25%;
  }
}

.buttons {
  flex: 0 50%;
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;
  display: flex;

  @include mobileLandscape {
    flex: 0 100%;
  }

  @include tablet {
    flex: 0 50%;
  }

  @include desktop {
    flex: 0 20%;
  }
}

.nameDescription {
  flex: 0 100%;
  align-self: center;
  margin-top: 1rem;

  @include desktop {
    flex: 0 15%;
  }
}

.bottomRight {
  position: relative;
  font-size: 0.6rem;
  color: $colour-shade-5;
  width: 100%;
  padding-bottom: 1rem;

  span {
    display: inline;
  }

  @media (min-width: $widthTablet) {
    position: absolute;
    bottom: 0;
    right: 5px;
    width: auto;
    padding-bottom: 0;
  }

  span {
    display: inline !important;
  }
}

.moreMenuIcon,
.pointer,
.deleteIcon {
  &:hover {
    cursor: pointer;
  }
}

.successIcon {
  margin-top: 1.5rem;
  margin-left: 3rem;
}

.failureIcon {
  margin-top: 1.5rem;
}

.tooltipBody {
  padding: 0.5rem;
  margin: 0.5rem;
  text-align: left;
  margin-top: 0;
  padding-top: 0;
  p {
    color: $colour-shade-5 !important;
    margin-bottom: 0;
  }
}

.button {
  align-self: center;
  margin: 0 auto;

  @include desktop {
    margin: 0;
  }
}

.invite,
.cancelButton {
  @include buttonStyle;

  &:hover {
    cursor: pointer;
  }
}

.tooltipContent {
  p,
  .dateBy {
    font-size: 0.8rem;
    color: black;
  }
  h1 {
    font-size: 1rem;
    padding: 0;
    margin-bottom: 1rem;
  }
  font-size: 0.8rem;
  text-align: left;

  .links {
    img {
      margin-right: 1rem;
    }
    display: inline-block;
    padding: 0.5rem;
  }
}
