@import "../../../global/stylesheets/_mediaConfig";

.conductInspectionPage {
    background-color: #F3F3F3;
    height: 100%;

    .content {
        display: flex;

        @include tablet {
            padding: 1rem 2rem 1rem 1rem;
        }

        .form {
            flex: auto;
            background-color: white;
            padding: 1rem 2rem;

            @include tablet {
                padding: 1rem;
            }
        }

        .editor {
            flex: 50%;
        }
    }

    .header {
        background-color: #0085CA;
        padding: 1rem 2rem;
        display: flex;

        @include tablet {
            padding: 1rem;
        }
    
        .row {
            display: flex;
            
            .column {
                flex: auto;
            }
        }
    
        .textContainer {
            flex: auto;
            text-align: left;
        }

        .icon {
            flex: 0 0 auto;
            align-self: center;
            padding: 0 1rem;
            cursor: pointer;

            img {
                max-height: 100%;
                max-width: 0.5rem;
            }
        }
    
        .headerText {
            color: white;
            font-size: 15px;
            text-transform: uppercase;
        }
    
        .subText {
            color: #8ECCEC;
            font-size: 14px;
        }
    }
}