@import '../../../../global/stylesheets/colours';
@import '../../../../global/stylesheets/mediaConfig';
@import '../../../../global/stylesheets/styling';
@import '../../../../global/stylesheets/functions';
@import '../../../../global/stylesheets/components/button/index.scss';

.button {
    @include baseButton;

    &.submit {
        @include saveButton;
    }
    
    &.cancel {
        @include cancelButton;
    }
    
    &.action {
        @include actionButton;
    }
    
    &.default {
        @include cancelButton;
    }

    &.full {
        width: 100%;
    }
}
