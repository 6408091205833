.flexContainer {
  height: 100vh;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.flexItem {
  padding: 5px;
  margin: 10px;
  line-height: 20px;
  color: rgb(32, 11, 11);
  font-weight: bold;
  font-size: 1.25em;
  text-align: center;
}

.text {
  padding-top: 2.5em;
  color: black;
}

.logo {
    max-height: 4rem;
    //width: 12.2rem;
    //height: 5.75rem;
}