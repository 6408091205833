@import "../../../../global/stylesheets/colours";

.generalPage {
  background-color: $colour-shade-0;
  padding: 2rem;
  // box-shadow: $colour-shade-5 0px 1px 6px, $colour-shade-5 0px 1px 4px;
  margin-bottom: 1rem;
}

.addButtons {
  margin-bottom: 1rem;

  button {
    width: auto;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.summary {
  margin-bottom: 1rem;
}

.section {
  padding: 1rem;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: $colour-shade-0;
  min-height: 7.6rem;

  .heading {
    font-size: 0.75rem;
    font-weight: 500;
    text-align: left;
    text-transform: uppercase;
    color: $colour-shade-3;
    letter-spacing: 1px;
    width: 100%;
  }

  p {
    padding-top: 1rem;
    text-align: center;
    color: $colour-shade-5;
    font-size: 3rem;

    &.event {
      color: $colour-blue-0;
    }

    &.login {
      color: $colour-green-0;
    }

    &.exception {
      color: $colour-error;
    }
  }
}
