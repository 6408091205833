@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.plus {
  font-size: 2rem;
  display: inline-block;
}

.addProperty {
  border-radius: 300px !important;
  border: 1px solid $colour-blue-0 !important;
  color: $colour-blue-0 !important;
  background-color: $colour-shade-0;
  border: none;
  border-radius: 6px;

  @media (max-width: $max-widthPhone) {
    border: none !important;
  }

  &:hover {
    background-color: $colour-blue-0 !important;
    color: $colour-shade-0 !important;

    .addPropertyText {
      color: $colour-shade-0 !important;
    }
  }

  .addPropertyText {
    color: $colour-blue-0 !important;
    border: none;
    margin: 0;
    padding-right: 1rem;
  }
}

.addPropertySmall {
  composes: addProperty;
  width: 2.25rem;
  padding-right: 0;

  svg {
    width: 1rem;
    height: 1rem;
  }
}

.addPropertyIcon {
  margin-right: -1.2rem !important;
  margin-left: -1rem;
  @media (max-width: $max-widthPhoneSamasungGalaxy) {
    padding: 0.4rem 0 0.4rem 0 !important;
    margin: 0rem !important;
  }
  img {
    margin: 0;
    padding: 0;
  }
}

.addPropertyText {
  font-size: 1.2rem;
  color: $colour-blue-0;
  padding: 0.65rem;
  padding-right: 0rem;
  @media (max-width: $max-widthPhone) {
    display: none;

    svg {
      display: block;
    }
  }
}

.addPropertyTextFixed {
  composes: addPropertyText;

  @media (max-width: $max-widthPhone) {
    display: inline;

    svg {
      display: inline;
    }
  }
}

.active {
  background-color: #F9FAFF;
}

.list {
  background-color: white;
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(128, 128, 128, 0.13);

  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;

  position: absolute;
  z-index: 1;
  width: auto;
  min-width: 10rem;

  li {
    border-bottom: 1px solid rgba(128, 128, 128, 0.13);
    padding: 0.4rem;

    &:hover {
      cursor: pointer;
      background-color: rgba(128, 128, 128, 0.13);
    }
  }
}

.inputContainer {
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;

  .input {
    margin: 0;
    padding-right: 30px;
  }

  .inputContent {
    position: absolute;
    bottom: 8px;
    right: 10px;
  }
}

.input {
  border-radius: 5px;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $colour-495057;
  background-color: $color-input-bg;
  background-clip: padding-box;
  border: 1px solid #f0f3ff;
  transition: border-color 0.15s;
  margin-bottom: 1rem;
}