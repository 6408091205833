@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/styling";

.collapsibleContent {
    .content {
      display: flex;
      flex-direction: column;
      height: 0px;
      max-height: 0px;
      opacity: 0;
      overflow: hidden;
      transition: all .5s;

      &[aria-expanded="true"] {
        overflow: unset;
        opacity: 1; 
        height: 100%;
        max-height: none; /* important for animation */
        transition: all 0.5s cubic-bezier(0, 1, 0, 1);
      }
    }

    .title {
        border-bottom: 2px solid #0085ca;
        width: 100%;
        text-align: left;
        margin-bottom: 0.5rem;
        padding-bottom: 0.35rem;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: 1px;
        color: #0085ca;

        .chevron {
            font-size: 1.5rem;
            vertical-align: bottom;
            padding-right: 1rem;
        }

        .chevron::before {
            border-style: solid;
            border-width: 0.1em 0.1em 0 0;
            content: "";
            display: inline-block;
            height: 0.45em;
            left: 0.15em;
            position: relative;
            top: 0.15em;
            transform: rotate(-45deg);
            width: 0.45em;
          }
          
          .chevron.right:before {
            left: 0;
            transform: rotate(45deg);
          }
          
          .chevron.bottom:before {
            top: 0;
            transform: rotate(135deg);
          }
          
          .chevron.left:before {
            left: 0.25em;
            transform: rotate(-135deg);
          }
          
        
        &:hover {
            cursor: pointer;
        }
    }

    .slidedown {
        //overflow: hidden;
    }
}