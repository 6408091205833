@import "../../../../global/stylesheets/mediaConfig";
@import "../../../../global/stylesheets/colours";

.page {
  //padding: 1rem;
  //padding-top: 10px;
  background-color: white;

  @include tablet {
    //margin-right: 1rem;
  }
}

.button {
  margin-bottom: 0.25rem;
  position: absolute;
  top: 3rem;
  right: 2rem;

  @include tablet {
    margin-bottom: 0rem;
    top: 4rem;
    right: 4rem;
  }
}

.addIcon {
  margin-right: 0.5rem;
}

.listItem {
  margin-bottom: 0.75rem;
}
