@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.container {
  background-color: white;
  height: 100%;
}

.loading {
  margin-top: 4rem;
}

.propertyImage {
  img {
    height: 40vh;
    width: 100%;
  }
  @media (min-width: $widthTablet) {
    display: none;
  }
}

.propertyCard {
  margin: 0 auto;
  background-color: $colour-shade-0;
  border-bottom: 1px solid $colour-e4e4e4;
}

.hiddenStreetView {
  visibility: hidden;
}

.plainNested {
  background-color: #fff !important;
  
  @media (max-width: $max-widthPhone) {
    padding: 0px;
  }
}