@import '../../../global/stylesheets/mediaConfig';
@import '../../../global/stylesheets/colours';

.form {
    margin: 0 auto;

    button {
        width: 100%;
        margin-top: 1rem;
    }
}

.label {
    float: left;
    font-size: 0.7rem;
    color: $colour-shade-2;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.05rem;
    margin-bottom: 0.25rem;
}

.errorMessage {
    margin-top: 0.2rem;
    color: $colour-error !important;
    font-size: 0.85rem;
    text-align: left;
}

textarea,
.input {
    border-radius: 5px;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $colour-495057;
    margin-bottom: 0.4rem;
    background-color: $color-input-bg;
    background-clip: padding-box;
    border: 1px solid #d5deff;
    transition: border-color 0.15s;
    @media (max-width: $max-widthPhone) {
        width: 100%;
    }

    &::placeholder {
        color: $colour-b0bac9;
    }
}

.buttonContainer {
    width: 100%;
    margin: 0 auto;

    @include tablet {
        width: 80%;
    }
}

.button {
    background-color: $colour-blue-0;
    color: $colour-shade-0;
    border: 1px solid $colour-blue-0;
    width: 100%;
    border-radius: 300px;
    padding: 0.55rem;
    font-weight: 600;
    font-size: 1.125rem;
}

.links {
  margin: 1rem;
  text-decoration: underline;
  color: #0085CA;
  cursor: pointer;
  font-size: 1rem;
}