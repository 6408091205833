@import "../../../../global/stylesheets/mediaConfig";
@import "../../../../global/stylesheets/colours";

.page {
  background-color: $colour-shade-0;
  margin: 0 auto;
  margin-top: 1rem;
  padding: 0;
  margin: 1rem;

  @media (min-width: $widthTablet) {
    padding: 2rem;
  }
}