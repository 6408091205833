@import "../../../global/stylesheets/colours";

.footer {
  margin: 0 auto;
  max-width: 90rem;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  padding: 1rem;
  background-color: $colour-shade-0;

  embed {
    max-width: 7.5rem;
  }
}

.right {
  text-align: left;
}

.left {
  text-align: right;
  font-size: 0.8rem;
  color: $colour-shade-4;
  text-transform: uppercase;
  font-weight: 500;
  padding: 0;
}
