@import "../../../../global/stylesheets/colours";
@import "../../../../global/stylesheets/mediaConfig";

.container {
  margin: 0;
}

.navigation {
  flex: 0 100%;
  max-width: 100%;
}

.routedPage {
  margin: 1rem;
  background-color: $colour-shade-0;
  height: 100%;

  padding: 0;

  @media (min-width: $widthTablet) {
    padding: 0 15px 0 15px;
  }
}

.tabs {
  padding: 0;

  @media (min-width: $widthTablet) {
    padding: 0 15px 0 15px;
  }
}

.content {
  height: 100%;
}

.row {
  display: block;
  margin: 0 0px;
  padding: 0.6rem;

  @include tablet {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }

  @include fullHD {
    flex-wrap: nowrap;
  }

  .column {
    display: block;
    width: 100%;

    @include tablet {
      flex: 0 100%;
      padding: 0 15px;
    }
  }

  .columnSmall {
    display: block;
    padding: 0 15px;
    margin-top: 1rem;
    width: 100%;

    @include tablet {
      flex: 0 100%;
    }

    @include fullHD {
      flex: 0 30%;
    }
  }
}

.ds {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
}

.h2 {
  width: 100%;
  text-align: left;
  padding-bottom: 0.2rem;
  font-size: 1.2rem;
  color: #0085ca;
}

.chat {
  box-shadow: 0px 5px 8px #0000001c;
  background-color: white;
  margin-bottom: 1rem;

  @include tablet {
    margin-bottom: 0;
  }
}
