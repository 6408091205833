@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/styling";

.elevioArticleEmbedWrapper {
  margin: 0 auto;
  padding: 1rem;

  @include tablet {
    max-width: 50%;
    padding: 0;
  }
}