@import "../../../../../global/stylesheets/colours";
@import "../../../../../global/stylesheets/_mediaConfig";

.page {
  background-color: white;
  padding: 0rem 0rem 1rem 0rem;
}

.row {
  display: flex;
  justify-content: space-between;
  margin: 0 -15px;
  margin-bottom: 1rem;

  .column {
    flex: 0%;
    padding: 0 15px;

    button {
      padding-right: 0 !important;
      float: right;
    }
  }

  .columnSmall {
    flex: 0 16%;
    padding: 0 15px;
  }

  .columnShared {
    flex: 0 18%;
    padding: 0 15px;
    padding-right: 0;
    text-align: center;

    input {
      ::-webkit-input-placeholder {
        text-align: center;
      }

      :-moz-placeholder {
        /* Firefox 18- */
        text-align: center;
      }

      ::-moz-placeholder {
        /* Firefox 19+ */
        text-align: center;
      }

      :-ms-input-placeholder {
        text-align: center;
      }
    }
  }

  .columnXSmall {
    flex: 0 5%;
    padding: 0 15px;
  }
}

.label {
  font-size: 0.7rem;
  color: $colour-b0bac9;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05rem;
  margin-bottom: 0.25rem;
  display: block;
  width: 100%;
  text-align: left;
}

.input {
  border-radius: 5px;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $colour-495057;
  background-color: $colour-shade-0;
  background-clip: padding-box;
  border: 1px solid #f0f3ff;
  transition: border-color 0.15s;
}

.smallInput {
  composes: input;
  max-width: 50%;
  width: 50%;
}

.xSmallInput {
  composes: input;
  max-width: 18%;
  width: 18%;
}

.inputFifty {
  composes: input;
  max-width: 50%;
  width: 50%;
}

.addButtonColumn {
  composes: column;
  flex: 0 85% !important;
  padding-right: 0 !important;
}

.title {
  margin-bottom: 1rem;
}

.addIcon {
  margin-right: 0.5rem;
}

.noTenancies {
  p {
    color: black;
  }
}

.filter {
  margin-bottom: 1rem;
  display: block;

  @include tablet {
    display: flex;
  }
}

.currentTenants {
  display: block;
  width: 100%;
  align-self: center;

  @include tablet {
    flex: 0 20%;
    width: auto;
  }
}

.addTenancy {
  align-self: center;
  flex: 0%;
  justify-content: flex-end;
  display: flex;
}
