@import "../../../../global/stylesheets/mediaConfig";
@import "../../../../global/stylesheets/colours";

.filter {
  //display: flex;
}

.toggleButton {
  color: $colour-707070;
  border: 1px solid $colour-e4e4e4;
  background-color: $colour-shade-0;
  padding: 0.65rem;
  float: left;
  margin-bottom: 0.6rem;
  border-radius: 0;
  font-size: 0.65rem;
  margin: 0.1rem;
  border-radius: 6px 6px 6px 6px;
  min-width: 5rem;

  &:hover {
    background-color: #B5AFAF;
  }

  &:focus {
    outline: 0;
  }

  @media (max-width: $max-widthTablet) {
    display: none;
  }
}

.toggleButtonShowAlways {
  color: #707070;
  border: 1px solid $colour-e4e4e4;
  background-color: $colour-shade-0;
  padding: 0.65rem;
  float: left;
  margin-bottom: 0.6rem;
  border-radius: 0;
  font-size: 0.65rem;
  margin: 0.1rem;
  border-radius: 6px 6px 6px 6px;
  min-width: 5rem;

  &:hover {
    background-color: #B5AFAF;
  }

  &:focus {
    outline: 0;
  }
}

.toggleButtonActive {
  composes: toggleButton;
  color: $colour-shade-0;
  background-color: $colour-blue-0;

  @media (max-width: $max-widthTablet) {
    display: none;
  }
}

.filterIcon {
  background-color: $colour-shade-0;
  padding: 0.4rem 0rem 0rem 0rem;
  float: left;
  //margin-bottom: 0.6rem;
  //border-radius: 6px 6px 6px 6px;
  display: block;

  &:hover {
    cursor: pointer;
  }

  @include tablet {
    margin-bottom: 0;
    //@include square(3rem);
  }

  @media (min-width: $min-widthTabletLandscape) {
    pointer-events: none;
  }

  @include desktop {
    //@include square(2.5rem);
    display: none;
  }
}

.filterWindow {
  @media (max-width: $max-widthTablet) {
    text-align: left;
    padding-left: 2rem;
  }
}

.sortDropdown {
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: $colour-e4e4e4;
  color: $colour-shade-5;

  .close {
    float: right;
    font-size: 0.85rem;

    &:hover {
      font-weight: 600;
      cursor: pointer;
    }
  }

  .title {
    font-size: 1rem;
    color: $colour-707070;
  }

  .marginBottom {
    margin-bottom: 1rem;
  }

  ul {
    list-style: none;
    li.active {
      color: $colour-blue-0;
    }

    li {
      display: inline;
      padding: 1rem;
    }
  }
}

.filterIconf {
  composes: sortDropdown;

  float: left;
  width: 100vw;
  position: relative;
  z-index: 10;
}

.sortByIconModel {
  float: left;
  padding-top: 0.2rem;
  @media (min-width: $min-widthPhone) {
    display: none;
  }
}
