@import "global/stylesheets/styling";
@import "global/stylesheets/colours";

.App {
  text-align: center;
  font-family: "Rubik", sans-serif;
}

.input-range__label {
  display: none !important;
}

.input-range__track--active {
  background: $colour-blue-0 !important;
}

.input-range__slider {
  border: 4px solid $colour-blue-0 !important;
  background-color: white !important;
  z-index: 1;
}
