@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.page {
  background-color: $colour-shade-0;
  min-height: 100vh;
  display: absolute;
  overflow: hidden;
}

.container {
  // These top margin are required to accomodate the height needed for the sticky top bar,
  // which would otherwise overlap
  margin-top: 4rem;

  @include tablet {
    margin-top: 4.8rem;
  }

  @media (max-width: 768px) {
    margin-top: 5rem;
  }
}

.pageContainer {
  padding: 5px 25px 0 25px;
  @media (min-width: $widthWithNav) {
    &.sidebarOpen {
      padding: 5px 25px 0 25px;
    }
    padding: 5px 25px 0 10px;
    @media (max-width: 500px) {
        padding: 0px;
    }
  }
}

.dashboardNavigationMobile {
  z-index: 6;
  padding-left: 0.8rem;
  background-color: rgba(0, 0, 0, 0.35);
  position: fixed;
  max-width: 100%;
  width: 100%;
  border-right: 1.5px solid #e9eeff;
  margin-left: 1rem;

  .navContainer {
    margin-left: -1rem;
    width: 11rem;
    background-color: white;
  }

  @media (min-width: $widthWithNav) {
    display: none;
  }
}

.dashboardNavigationDesktop {
  display: none;

  @media (min-width: $widthWithNav) {
    display: block;
  }

  border-right: 1.5px solid #e9eeff;
  padding-right: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  min-width: 170px;
  max-width: 170px;

  &.sidebarOpen {
    display: none;
  }
}

.sidebarOpen {
  //overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

.sidebarClosed {
  //overflow: unset;
  -webkit-overflow-scrolling: auto;
}

.stickyChat {
  flex: 0 20%;
  padding: 0;
  display: none;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);

  @include tablet {
    display: block;
  }
}

.stickyChats {
  ul {
    position: fixed;
    list-style: none;
    bottom: 0;
    z-index: 1;
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
  }

  li {
    background-color: #fff;
    border-radius: 8px 8px 0 0;
    transition: background-color 0.4s;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    padding: 0.4rem 1rem;
    float: left;
    margin-right: 1rem;
    max-height: 30rem;
    max-width: 22rem;
    overflow: auto;

    &:hover {
      cursor: pointer;
    }

    p {
      color: black;
      margin: 0;
    }
  }
}

.stickyChatToggle {
  right: 2rem;
  bottom: 2rem;
  position: fixed;
  display: none;

  &:hover {
    cursor: pointer;
  }

  @include tablet {
    display: block;
  }
}

.close {
  display: inline-block;
}
