@import '../../../global/stylesheets/colours';
@import '../../../global/stylesheets/mediaConfig';

$bg-color: #424242;
$hl-color: #2196f3;
$muted-color: mix($colour-shade-0, $bg-color, 65%);
$trans-time: 300ms;

.form {
    .text {
        text-align: center;
        margin: 0 auto;
        color: $colour-shade-2;
        margin-top: 1rem;
        justify-content: center;
        font-size: 1rem;
    }

    .link {
        display: contents;
        color: $colour-blue-0;
        text-decoration: underline;
        cursor: pointer;
    }

    .tandcs {
        text-align: left;
    }

    .group {
        textarea,
        .input {
            border-radius: 5px;
            display: block;
            width: 100%;
            height: calc(1.5em + 0.75rem + 2px);
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: $colour-495057;
            margin-bottom: 0.4rem;
            background-color: $color-input-bg;
            background-clip: padding-box;
            border: 1px solid #d5deff;
            transition: border-color 0.15s;
            @media (max-width: $max-widthPhone) {
                width: 100%;
            }

            &::placeholder {
                color: $colour-b0bac9;
            }
        }
    }
}

.label {
    float: left;
    font-size: 0.7rem;
    color: $colour-shade-2;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.05rem;
    margin-bottom: 0.25rem;
}

.logo {
    max-height: 4rem;
    margin-bottom: 1rem;
}

.errorMessage {
    margin-top: 0.2rem;
    color: $colour-error !important;
    font-size: 0.85rem;
    text-align: center;
}

.buttonContainer {
    width: 100%;
    margin: 0 auto;

    @include tablet {
        width: 80%;
    }
}

.button {
    background-color: $colour-blue-0;
    color: $colour-shade-0;
    border: 1px solid $colour-blue-0;
    width: 100%;
    border-radius: 300px;
    padding: 0.55rem;
    font-weight: 600;
    font-size: 1.125rem;
}

.text {
    text-align: center;
    margin: 0 auto;
    color: $colour-shade-2;
    font-size: 0.7rem;
    margin-top: 1rem;
    justify-content: center;
}

.successText {
    composes: text;
    font-size: 1rem;
}

.error {
    padding: 0.65rem;
    border-radius: 300px;
    border: 1px solid $colour-error;
    margin-bottom: 2rem;
    color: $colour-shade-0;
    background-color: $colour-error;
}

.errorMessage {
    color: $colour-error;
}

.loggedIn {
    word-break: break-all;
    color: $colour-shade-5;
}

.p {
    color: $colour-shade-5;
}

.link {
    display: contents;
}

.loadingText {
    padding-top: 2rem;
    color: $colour-shade-5;
}

.logout {
    padding: 0.65rem;
    border: 1px solid $colour-green-0;
    margin-bottom: 1rem;
    color: $colour-green-0;
}

.modalBody {
    color: $colour-shade-5;
    padding: 1rem;

    .input,
    .button {
        margin-top: 1rem;
    }
}

.forgotPassword:hover {
    cursor: pointer;
}

.logo {
    margin-bottom: 1.5rem;
}

textarea {
    resize: none;
}

.bar {
    position: relative;
    display: block;
    &:before {
        content: '';
        height: 2px;
        width: 0;
        bottom: 0px;
        position: absolute;
        background: $hl-color;
        transition: $trans-time ease all;
        left: 0%;
    }
}

.noBottomMargin {
    composes: group;
    margin-bottom: 0;
}
