@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.horizontalEntitySelector {
  width: 100%;
  background-color: white;
  padding-bottom: .2rem;
  overflow-y: hidden;
  overflow-x: auto;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
    height: 100%;
    white-space:nowrap;
    text-align: left;
    margin-bottom: 2px;

    &::after {
      display: block;
      content: '';
      width: 100%;
      height: 2px;
      background: #E2E2E2;
      position: absolute;
      bottom: -2px;
      left: 0;
     }

    li {
      line-height: .75rem;
      font-size: .70rem;
      color: #8c8e8e;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 0.05rem;
      //padding: 0 .4rem;
      position: relative;
      display: inline;

      .count {
        background: #D5DEFF 0% 0% no-repeat padding-box;
        border: 1px solid #D5DEFF;
        border-radius: 7px;
        color: #2E5BFF;
        text-align: center;
        margin-left: .2rem;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        font-size: .75rem;
      }

      &.active {
        &::after {
          display: block;
          content: '';
          width: 100%;
          height: 2px;
          background: #0D8BCC;
          position: absolute;
          bottom: -8px;
          left: 0;
          z-index: 1;
              }
      }
    }
  }
}