@import "../../../../global/stylesheets/mediaConfig";
@import "../../../../global/stylesheets/colours";

.statuses {
  .status {
    background-color: red;
    padding: 0;
    color: white;
    border-radius: 2rem;
    display: inline-block;
    text-align: center;
    max-width: 4rem;
    width: 100%;
    font-size: 0.8rem;

    @include tablet {
      padding: 0 1rem;
      max-width: 100%;
    }
  
    &.notactive {
      background-color: red;
    }
    
    &.active {
      background-color: green;
    }
    
    &.invited {
      background-color: #0085ca;
    }
  }
}
