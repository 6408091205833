@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/mediaConfig";

.container {
  margin-left: 0px;
  background-color: #F9FAFF;
  width: 100%;

  @include tablet {
    margin: 0;
  }
}

.navigation {
  flex: 0 100%;
  max-width: 100%;
}

.routedPage {
  background-color: #F9FAFF;
  height: 100%;
  padding: 0;

  @media (min-width: $widthTablet) {
    //margin: 1rem;
    //padding: 0 15px 0 15px;
  }
}

.tabs {
  padding: 0;

  @media (min-width: $widthTablet) {
    padding: 0 15px 0 15px;
  }
}

.content {
  height: 100%;
}

.ds {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}

.documentsPage {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  padding: 1rem;
  background-color: white;
}

.goBack {
  font-size: 1rem;
  color: black;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05rem;
  margin-bottom: 0.25rem;
  display: block;
  width: 100%;

  @include tablet {
    display: none;
  }
}