@import "../../../../../global/stylesheets/colours";
@import "../../../../../global/stylesheets/mediaConfig";

.page {
  margin: 0 auto;
  background-color: $colour-shade-0;
}

.container {
  margin: 0;
  padding: 1rem;
  margin-top: 1rem;
}