@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.button {
  background-color: $colour-blue-0;
  width: 20%;
  border-radius: 0;
  padding: 0.75rem;
  font-weight: 600;
  margin-top: 0.5rem;
  border: none;
  margin-left: 1rem;
  border-radius: 6px;

  @media (max-width: $max-widthPhone) {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.cancelButton {
  composes: button;
  background-color: $colour-3f64e899;
}

.modalBody {
  color: $colour-shade-5;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-bottom: 0;
  line-height: 2rem;

  @include tablet {
    height: 10rem;
    line-height: auto;
    margin-bottom: 4rem;
  }
}

.records {
  color: $colour-blue-0;
  font-weight: 600;
}

.modelHeader {
  color: $colour-shade-2;
  font-weight: 500;
  margin-bottom: 0px !important;
}

.buttonDiv {
  text-align: right;
}

.checkboxes {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.address {
  color: $colour-blue-0;
  font-weight: bold;
}

.modal {
  @media (min-width: $widthTablet) {
    max-width: 50%;
    max-width: none;
    width: 50%;
  }

  width: 100%;
  padding: 0;

  button {
    top: 8px;
    right: 8px;

    svg {
      fill: $colour-close-button;
    }
  }
}

.overlay {
  background: rgba(0, 0, 0, 0.25);
  z-index: 2;
}

.body {
  padding: 1rem;
}
