@import "../../../global/stylesheets/_mediaConfig";

.container {
    display: inline-block;
    width: auto !important;

    .ellipsis {
        display: inline-block;
        margin: 0;
    }
}