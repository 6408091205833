@import '../../../../global/stylesheets/mediaConfig';
@import '../../../../global/stylesheets/colours';
@import '../../../../global/stylesheets/styling';
@import '../../../../global/stylesheets/components/links/index.scss';

.row {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;

    @include tablet {
        flex-wrap: nowrap;
    }

    .column {
        flex: 0;
        min-width: 6rem;

        @include tablet {
            flex: 0%;
        }
    }
}

.tiles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 0 100%;
    justify-content: space-around;
    align-content: center;
    padding-top: 1rem;

    @include tablet {
        flex: 0 88%;
    }
}

.edit {
    position: absolute;
    right: 0;
    font-size: 0.8rem;
    color: #0085ca;
    opacity: 1;

    @include link();
    @include hoverOver();
}