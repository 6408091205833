@import '../../../global/stylesheets/colours';

.dropzone {
    -webkit-flex: 1 1;
    flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: $colour-0c54602e;
    border-style: dashed;
    background-color: #f3f3f3;
    color: $colour-shade-5;
    outline: none;
    transition: border 0.24s ease-in-out;

    p {
        color: $colour-shade-5;
    }
}

.uploadButton {
    width: 100%;

    > div {
        justify-content: center;
    }
}
