@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.imageGallery {
  position: relative;

  :global {
    .image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
      height: 60px !important;
      width: 1.5rem !important;
      z-index: 1 !important;
    }
  }

  .actions {
    position: absolute;
    z-index: 1;

    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    line-height: 1;
    padding: .5rem 20px;
    left: 0;
    top: 0;

    pointer-events: initial;
  }

  .syncfusionDropdown {
    background-color: transparent !important;
    padding: 0 !important;
    margin: 0 !important;
    font: inherit;
  
    ul {
      background-color: white !important;
    }
  }
}

.modal {
  @media(min-width: $widthTablet) {
    max-width: 50%;
    max-width: none;
    width: 80%;
  }

  width: 100%;
}

.modalBody {
  color: $colour-shade-5;
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.modalHeader {
  color: $colour-shade-2;
  font-weight: 500;
  margin-bottom: 0px !important;
}