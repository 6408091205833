@import "../../../../global/stylesheets/mediaConfig";
@import "../../../../global/stylesheets/colours";
@import "../../../../global/stylesheets/styling";

.syncfusionDropdown {
  background-color: $colour-green-0 !important;
  padding: 2px !important;
  margin: 0 !important;
  font: inherit;

  ul {
    background-color: white !important;
  }
}

.subIcon {
  display: block;
  min-width: 1.2rem;
  min-height: 1.2rem;
  background-repeat: no-repeat;
  background-size: 100%;
  align-self: center;
}

.subIconEdit {
  composes: subIcon;
  background-image: url("../../../../images/icons/transactions/icon_contextmenu_edit.svg");
}

.subIconDelete {
  composes: subIcon;
  background-image: url("../../../../images/icons/transactions/icon_contextmenu_delete.svg");
}
