@import "../../../../../global/stylesheets/mediaConfig";
@import "../../../../../global/stylesheets/colours";

.content {
  padding: 0 1rem;
}

.row {
  display: flex;
  justify-content: flex-end;
  .column {
    flex: 0%;
  }

  .iconColumn {
    justify-self: flex-end;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-self: center;

    @include tablet {
      flex-direction: row;
    }
  }
}

.tabRow {
  justify-content: flex-start !important;
  margin-bottom: 0.5rem;
}

.icon {
  margin-right: 1rem;
  display: inline-block;

  .text {
    display: none;

    @include tablet {
      display: inline-block;
    }
  }

  img {
    margin-right: 0.2rem;
  }

  &:hover {
    cursor: pointer;
  }
}

.grid {
  width: 100%;
  padding-top: 1rem;
  padding-right: 1rem;
}

.dropdown {
  display: flex;
  min-width: 5rem;
  flex-direction: row;
  justify-content: left;

  @include mobileLandscape {
    flex-direction: row;
    justify-content: left;
    flex-wrap: wrap;
  }
}

.gridRow {
  margin-top: 0.25rem;
}

.gridColumn {
  width: 100%;
}

.tabNav {
  width: 100%;
  margin: 0 auto;

  @include mobileLandscape {
    margin: unset;
  }
}

.typeDropdown {
  display: flex;
  flex: 0 100%;
  max-width: 3rem;
  margin-bottom: 0.5rem;

  @include mobileLandscape {
    flex: 0 45%;
    margin-bottom: 0;
  }

  @include tablet {
    max-width: 8rem;
  }
}

.selectDropdown {
  display: none;
  min-width: 8rem;

  @include tablet {
    display: block;
  }
}

.searchInput {
  display: flex;
  flex: 0 90%;
  margin-left: 1rem;

  @include mobileLandscape {
    flex: 0 50%;
    min-width: 8rem;
    max-width: 8rem;
  }

  @include tablet {
    min-width: 8rem;
    max-width: 8rem;
  }

  @include desktop {
    min-width: 12rem;
    max-width: 15rem;
  }
}

.dateRangePicker {
  display: none;

  @include tablet {
    display: block;
  }
}

.marginTop {
  margin-top: 1rem;
}

.actionMenu {
  display: flex;
  flex-direction: row;
  margin-right: 0.25rem;
}

.iconAction {
  display: block;
  min-width: 2rem;
  min-height: 2rem;
  background-repeat: no-repeat;
  background-size: 100%;
}

.actionText {
  margin-left: 0.1rem;
  margin-top: 0.225rem;
  margin-right: 0.5rem;
  display: none;

  @include desktop {
    display: block;
  }
}

.subIcon {
  display: block;
  min-width: 1.2rem;
  min-height: 1.2rem;
  background-repeat: no-repeat;
  background-size: 100%;
  align-self: center;
}

.subIconActivate {
  composes: subIcon;
  background-image: url("../../../../../images/icons/user_activate_icon.svg");
}

.subIconAdd {
  composes: subIcon;
  background-image: url("../../../../../images/icons/transactions/icon_contextmenu_addexpense.svg");
}

.subIconDelete {
  composes: subIcon;
  background-image: url("../../../../../images/icons/transactions/icon_contextmenu_delete.svg");
}

.subIconExportCsv {
  composes: subIcon;
  background-image: url("../../../../../images/icons/transactions/icon_contextmenu_csv.svg");
}

.subIconExportPdf {
  composes: subIcon;
  background-image: url("../../../../../images/icons/transactions/icon_contextmenu_pdf.svg");
}

.subIconExportExcel {
  composes: subIcon;
  background-image: url("../../../../../images/icons/transactions/icon_contextmenu_excel.svg");
}

.addTransactions {
  display: none;

  @include mobileLandscape {
    display: block;
  }
}

.syncfusionDropdown {
  background-color: white !important;
  padding: 0 !important;
  font: inherit !important;
}

.filter {
  margin: 0.25rem 0.5rem;
  display: block;

  @include tablet {
    display: none;
  }

  .filterIcon {
    @include square(2rem);
  }
}
