$min-widthPhone: 500px;
$min-widthPhoneLandscape: 640px;
$min-widthTablet: 768px;
$min-widthPhonePlusLandscape: 812px;
$min-widthDesktop: 1024px;
$min-widthTabletLandscape: 1025px;
$min-widthLargeDesktop: 1200px;
$max-widthPhone: 500px;
$max-widthPhoneSamasungGalaxy: 736px;
$max-widthPhoneLandscape: 768px;
$max-widthPhonePlusLandscape: 1023px;
$max-widthTablet: 1024px;
$max-widthSmallDesktop: 1200px;
$min-widthLargeDesktop: 1850px;
$min-widthLaptopSmall: 1530px;

$paddingMobileCards: 0 0 15px 0;

// New breakpoints going forward with mobile-first design
// These values are for Min media queries only.
// No max width queries should be used going forward

$widthMobileLandscape: 568px;
$widthTablet : 768px;
$widthDesktop: 1024px;
$widthFullHD: 1200px;
$widthWithNav: 1400px;

// Max grid width for desktop size
$widthGridDesktop: 980px;

/*
    Mixins for common styling scenarios
*/

@mixin tabletLandscape {
    @media (min-width: #{$widthTablet}) and (orientation: landscape) {
        @content;
    }
}

@mixin mobileLandscape {
    @media (min-width: #{$widthMobileLandscape})  {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$widthTablet}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$widthDesktop}) {
        @content;
    }
}
  
@mixin fullHD {
    @media (min-width: #{$widthFullHD}) {
        @content;
    }
}

@mixin square($size) {
    min-width: $size;
    max-width: $size;
    min-height: $size;
    max-height: $size;
}
