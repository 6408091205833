.multiselect {
    display: flex;
    margin: 0 -15px;
    justify-content: center;

    .item {
        flex: 0;
        padding: 0 15px;
        width: 100%;
    }
}