@import "../../../global/stylesheets/colours";

.viewingAtom {
  padding-bottom: 1rem;

  p {
    color: $colour-shade-5;
    margin: 0;
    font-size: 0.85rem;
    text-align: left;
  }
}

.label {
  text-align: left;
  font-size: 0.7rem;
  color: $colour-shade-2;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05rem;
  margin-bottom: 0 !important;
  width: 100%;
}
