@import "../../../global/stylesheets/colours";

#modal {
  background-color: $colour-shade-0;
  border-radius: 0rem;
}

.alert {
  border: none !important;
  background-color: $colour-shade-0;
}

.text {
  color: $colour-shade-5;
}

.loading {
  margin: 0 auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;
}

.overlay {
  background: rgba(0, 0, 0, 0.25);
}