@import "../../../../global/stylesheets/mediaConfig";
@import "../../../../global/stylesheets/colours";

.container {
    .innerContainer{
        width: 100%;
        background-color: #EFE4E4;
        display: flex;
        padding-bottom: 1px;
    }

    width: 100%;
    display: flex;

    .wrapper {
        display: flex;
    }

    .actions {
        width: 100%;
        flex: 0 20%;
        justify-self: center;
        align-self: center;
        display: flex;

        @include tablet {
            flex: 0 8%;
        }

        .emoji, .attach {
            flex: 1;
            text-align: center;
            padding-left: 5px;
            padding-right: 5px;
            &:hover {
                cursor: pointer;
            }
            img{
                height: 30px;
                width: 30px;
            }
        }
    }

    input, textarea {
        flex: 1 85%;
        background-color: white;
        border-radius: 20px;
        margin-right: .4rem;
        margin-top: .4rem;
        text-align: left;
        letter-spacing: 0;
        color: black;
        padding-left: 1rem;
        border: 1px solid #F2F5FF;
        padding: 0.375rem 0.75rem;
        height: initial;
    }

    button {
        flex: 0%;
        width: 100%;
        align-self: center;

        img {
            //margin-right: 0.5rem;
            align-self: center;
            margin-bottom: 0.4rem;
        }
    }
}

.files {
    //background-color: white;
    padding: 1rem;
    box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, .10);
    margin-bottom: .4rem;

    img {
        max-height: 4rem;
        border-radius: 1rem;
    }
}

.label {
    float: left;
    font-size: 0.7rem;
    color: $colour-shade-2;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.05rem;
    margin-bottom: 0.25rem;
    display: block;
    width: 100%;
    text-align: left;
  }