@import "../../../../../global/stylesheets/colours";

.property {
  .row {
    display: flex;

    .column {
      flex: 0%;
    }
  }

  img {
    max-width: 6rem;
  }
}

.text {
  composes: column;
  padding: 1rem;
}

.image {
  composes: column;
  flex: 0 !important;
}

.bold {
  font-weight: bold;
}