@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/styling";

.filter {
  margin-left: 1rem;
  //align-self: center;
  //flex: 0 5%;
  

  @include desktop {
    flex: 0 0 auto;
  }
}

.nestedRow {
  display: flex;
  margin: 0 -15px;
  align-items: center;

  .nestedColumn {
    padding: 0 15px;
    flex: auto;
    flex-grow: 1;
    flex-basis: 0;
    align-self: center;
  }
}

.searchBarDiv {
  margin-left: 1rem;
  margin-right: 0rem;
  padding-bottom: 0.5rem;
}

.listPage {
  margin: 0 auto;
  background-color: $colour-shade-0;

  @media (max-width: 1200px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.propertyList {
  overflow: hidden;
}

.infiniteScroll {
  overflow-y: hidden !important;
}

.loader {
  padding: 2rem;

  p {
    padding: 2rem;
    color: $colour-shade-5;
  }
}

.loadingOverlay {
  position: absolute; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  margin-right: 15px;
  margin-left: 15px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(
    255,
    255,
    255,
    0.75
  ); /* $colour-shade-5 background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */

  .container {
    position: fixed;
    padding: 0;
    margin: 0;
    top: 40%;
    left: 45%;
  }
}

.propSearchBar {
  z-index: 1;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  background-color: $colour-shade-0;
}

.gridDiv {
  margin: 0;
  padding: 0;
  display: flex;
}

.actions {
  margin-left: -1rem;
  margin-right: 1.5rem;
  margin-bottom: 0.4rem;
  display: inline;
  align-self: center;

  button {
    @include tablet {
      display: inline;
    }
  }

  .mobileAdd {
    @include tablet {
      display: none;
    }
  }

  .actionButtonAddProperty {
    display: none;

    @include tablet {
      display: inline;
    }
  }
}
