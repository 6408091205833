@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/styling";

.footer {
  > div {
    padding: 0 2rem;
  }

  ul {
    text-align: left;
    padding: 0;
    list-style: none;

    li {
      font-weight: bold;
      color: black;
      font-size: 1rem;
      text-align: left;
      display: inline-block;
      text-decoration: underline;
      margin-right: .5rem;

      &:last-of-type {
        margin-right: 0;
      }

      a {
        color: black;
      }
    }
  }
}