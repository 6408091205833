@import "../../../../global/stylesheets/colours";
@import "../../../../global/stylesheets/_mediaConfig";

.filters {
  display: flex;
  padding-bottom: 1rem;
  width: 100%;

  .addButton {
    flex: 0%;
    justify-self: flex-end;
    margin-right: 2.5rem;

    @include mobileLandscape {
      margin-right: 0;
    }
  }
}

.filterIcon {
  @include square(2rem);
}

.label {
  line-height: 0.7rem;
  float: left;
  font-size: 0.7rem;
  color: $colour-shade-2;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05rem;
  margin-bottom: 0.25rem;
}

.heading {
  background-color: white;
  z-index: 1;
}

.dropdown {
  display: block;
  width: 100%;
  margin-right: 1rem;
  flex: 0 100%;
}

.issueItems {
  margin-top: 1rem;
  margin-right: 1rem;

  @include mobileLandscape {
    margin-right: 0;
  }
}

.row {
  display: flex;

  .addButton {
    flex: 0%;
    align-self: center;
  }

  .search {
    flex: 70% 1;
    padding: 0 5px;

    @include mobileLandscape {
      flex: 70%;
    }
  }

  .filterButton {
    align-self: center;
    flex: 0 5%;
  }

  .toggleButton {
    align-self: center;
    flex: 0 5%;
    margin-right: 0.5rem;

    &:hover {
      cursor: pointer;
    }
  }
}

.filters1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 4rem;

  @include mobileLandscape {
    padding-right: 1rem;
  }
}
