@import '../../../../../global/stylesheets/mediaConfig';
@import '../../../../../global/stylesheets/colours';
@import '../../../../../global/stylesheets/styling';

.currentValuationInputField {
    border-radius: 5px;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $colour-shade-4;
    margin-bottom: 0.4rem;
    background-color: $color-input-bg;
    background-clip: padding-box;
    border: 1px solid #f0f3ff;
    transition: border-color 0.15s;
    box-shadow: none !important;
    @media (max-width: $max-widthPhone) {
        width: 100%;
    }

    .input {
        height: calc(1.5em + 0.75rem + 2px);
    }
}

.currentDateInputField {
    border-radius: 5px;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $colour-shade-4;
    margin-bottom: 0.4rem;
    background-color: $color-input-bg;
    background-clip: padding-box;
    border: 1px solid #f0f3ff;
    transition: border-color 0.15s;
    @media (max-width: $max-widthPhone) {
        width: 100%;
    }

    .input {
        height: calc(1.5em + 0.75rem + 2px);
    }
}

.customModal {
    overflow:unset;
  }

  // This bad using the !important, will update once the RCCurrencyInput component is
// made more flexible to allow custom css
.rcCurrencyInput {
    input {
      width: 100% !important;
      height: calc(1.5em + 0.75rem + 2px) !important;
      padding: 0.375rem 0.75rem !important;
      font-size: 1rem !important;
      font-weight: 400 !important;
      line-height: 1.5 !important;
      color: #495057 !important;
      border-radius: 0px !important;
      display: inline-block !important;
      -webkit-appearance: none !important;
      border-radius: 0.35rem !important;
      background-color: #f9faff !important;
      border: 1px solid #f0f3ff !important;
    }
  }