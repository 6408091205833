@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.addProperty {
  a {
    text-decoration: none;
  }

  text-align: center;
  padding: .2rem 1rem;
  border-radius: 300px;
  border: none;
  background-color: transparent;
  color: $colour-blue-0;
  width: auto;

  span {
    display: none;

    @include tablet {
      display: inline-block;
    }
  }
}

.addPropertyWithBorder {
  composes: addProperty;
  border: 1px solid $colour-blue-0;
}

.addPropertyFloatRight {
  composes: addProperty;
}

.addPropertySmall {
  composes: addProperty;
  width: 2.5rem;
  padding-right: 0;
}

.disabled {
  composes: addProperty;
  opacity: 0.5;
  pointer-events: none;

  &:hover {
    cursor: not-allowed;
  }
}

.addPropertyIcon {
  @media (max-width: $max-widthPhoneSamasungGalaxy) {
    padding: 0.4rem 0 0.4rem 0 !important;
    margin: 0rem !important;
  }
}

.addPropertyText {
  color: $colour-blue-0;
  padding: 0.2rem;

  display: none;

  @include tablet {
    display: block;
  }
}