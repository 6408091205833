@import "../../../../../global/stylesheets/colours";
@import "../../../../../global/stylesheets/mediaConfig";

.page {
  margin: 0 auto;
  background-color: $colour-shade-0;
  padding: 1rem;
}

.container {
  margin: 0;
  padding: 1rem;
  margin-top: 1rem;
}

.formControl {
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
  align-content: left;
  margin-bottom: 1rem;

  .marginLeft {
    margin-left: 0.4rem;
    align-self: center;
  }
}
