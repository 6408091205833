@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.addButton {
  margin: auto 0.5rem;
  float: left;
}

.addButton {
  &:hover {
    cursor: pointer;
  }
}
