@import "../../../global/stylesheets/colours";

.form {
  margin: 0 auto;
  max-width: 30rem;
  width: 100%;
}

.label {
  text-align: left;
  font-size: 0.7rem;
  color: $colour-shade-2;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05rem;
  margin-bottom: 0.25rem;
  width: 100%;
}

.input {
  border-radius: 0;
  background-color: $color-input-bg;
}

.text {
  color: black !important;
}

.addressFindButton:hover {
  cursor: pointer;
}

.text {
  text-align: center;
  margin: 0 auto;
  color: $colour-shade-2;
  font-size: 0.85rem;
  margin-top: 1rem;
  justify-content: center;
}

.headerTitle {
  text-align: left;
  color: $colour-header-0;
  font-weight: 500;
  margin-bottom: 0px !important;
  border-bottom: 2px solid $colour-header-0;
  padding-bottom: 0.4rem;
  font-size: 1.4rem;
  margin-top: 0;
  padding-top: 0.4rem;
}

.error {
  margin-top: 1rem;
  padding: 0.65rem;
  border: 1px solid $colour-error;
  margin-bottom: 1rem;
  color: $colour-error;
}

.loggedIn {
  word-break: break-all;
  color: $colour-shade-5;
}

.p {
  color: $colour-shade-3;
  text-align: left;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.link {
  color: $colour-blue-0;
}

.link:hover {
  cursor: pointer;
}

.emailExists {
  color: $colour-error;
  padding-top: 0.25rem;
}

.h1 {
  text-align: left;
  margin-bottom: 0;
}

.h2 {
  color: $colour-shade-3;
}

.addressToggle {
  text-align: right;
  color: $colour-blue-0;
  font-size: 0.75em;
}

.addressToggle:hover {
  cursor: pointer;
  font-weight: bold;
}

.sliderInput {
  padding: 0.5rem;
}

.inputAddress {
  composes: input;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.findAddress {
  color: $colour-shade-5;
}

.text {
  padding-top: 2rem;
  color: $colour-shade-5;
}

.inputHolder {
  position: relative;
}

.dropdown {
  position: absolute;
  background-color: $colour-shade-0;
  color: $colour-shade-5;
  max-height: 14em;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 99999999999999999999;
}

.input,
.submit {
  display: inline-block;
  border-radius: 0;
  -webkit-appearance: none;
}

.addressInput {
  composes: input;
  width: 80%;
  display: inline-block;
}

.selectedAddress {
  composes: input;
  width: 100%;
  display: inline-block;
}

.addressFindButton {
  composes: input;
  width: 20%;
  display: inline-block;
  background-color: $colour-2ecc71;
  color: $colour-shade-0;
  border: 0;
}

.addressResultsDropdown {
  composes: dropdown;
  color: $colour-shade-5;
  font-size: 0.8rem;
  text-align: left;
  overflow-y: scroll;
  box-shadow: 0 4px 8px 0 $colour-shade-5;
}

.addressResultsDropdown ul {
  list-style: none;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.addressResultsDropdown ul li {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.4rem;
  margin-left: 0;
  font-size: 0.8rem;
}

.addressResultsDropdown ul li:hover {
  background-color: $colour-shade-0;
  cursor: pointer;
}

.emailExists {
  color: $colour-error;
  padding-top: 0.25rem;
}

.close {
  position: absolute;
  top: -25px;
  right: 0px;
}

.sticky {
  position: sticky;
  bottom: 0;
  height: 30px;
  width: 100%;
}

.errorMessage {
    margin-top: 0.2rem;
    color: $colour-error !important;
    font-size: 0.85rem;
    text-align: left;
}