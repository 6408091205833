@import '../../../global/stylesheets/mediaConfig';
@import '../../../global/stylesheets/colours';

.container {
    //background-color: #f1f1f1;
    height: 100%;

    .loader {
        margin-top: 1rem;
    }

    .content {
        @include tablet {
            //padding: 1rem 2rem 1rem 2rem;
        }

        .withBackground {
            background-color: white;
            padding: 1rem;
        }

        .hideOnMobile {
            display: none;

            @include tablet {
                display: initial;
            }
        }

        .row {
            display: flex;
            margin: 0 0px;
            flex-direction: column-reverse;

            @include tablet {
                flex-direction: row;
            }

            .column {
                flex: auto;
                padding: 0 15px;
            }

            .smallColumn {
                flex: 0 25%;
                padding: 0 2rem;

                @include tablet {
                    padding: 0;
                }
            }
        }
    }

    .navigation {
        flex: 0 100%;
        max-width: 100%;

        margin: 0 1rem;

        @include tablet {
            margin: 0;
        }
    }

    .headerBar {
        background-color: white;
        width: 100%;
        padding: 0.25rem 1.25rem;
        text-align: left;
        display: flex;

        @include tablet {
            padding: 0.5rem 1rem 0.5rem 0.5rem;
        }

        .breadcrumbs {
            flex: 100%;
            align-self: center;

            @include tablet {
                padding-left: 0.5rem;
            }
        }

        .actionMenu {
            flex: 0 0 auto;
            align-self: center;

            .actionIcon {
                height: 2rem;
            }
        }
    }
}
