@import "../../../../global/stylesheets/mediaConfig";
@import "../../../../global/stylesheets/colours";
@import "../../../../global/stylesheets/styling";

.syncfusionDropdown {
  background-color: transparent !important;
  padding: 0 !important;
  margin: 0 !important;
  font: inherit;

  ul {
    background-color: white !important;
  }
}

.icon {
  display: block;
  min-width: 1.2rem;
  min-height: 1.2rem;
  background-repeat: no-repeat;
  background-size: 100%;
  align-self: center;
}

.subIcon {
  display: block;
  min-width: 1.2rem;
  min-height: 1.2rem;
  background-repeat: no-repeat;
  background-size: 100%;
  align-self: center;
}

.subIconChangePassword {
  composes: subIcon;
  background-image: url("../../../../images/icons/user_changepassword_icom.svg");
}

.subIconActivate {
  composes: subIcon;
  background-image: url("../../../../images/icons/user_activate_icon.svg");
}

.subIconTenancy {
  composes: subIcon;
  background-image: url("../../../../images/icons/actionmenu/icon_contextmenu_add_tenancy.svg");
}

.iconGoToFinancials { 
  composes: subIcon;
  background-image: url("../../../../images/icons/icon_contextmenu_goto_financials.svg");
}

.subIconMarkAsPaid {
  composes: subIcon;
  background-image: url("../../../../images/icons/transactions/icon_contextmenu_paid.svg");
}

.subIconAddDocument {
  composes: subIcon;
  background-image: url("../../../../images/icons/actionmenu/icon_contextmenu_add_document.svg");
}

.subIconAddTenancyContract {
  composes: subIcon;
  background-image: url("../../../../images/icons/transactions/icon_contextmenu_income.svg");
}

.subIconAddInventory {
  composes: subIcon;
  background-image: url("../../../../images/icons/actionmenu/icon_contextmenu_add_tenancy.svg");
}

.subIconAddIssue {
  composes: subIcon;
  background-image: url("../../../../images/icons/actionmenu/icon_contextmenu_add_issue.svg");
}

.subIconEdit {
  composes: subIcon;
  background-image: url("../../../../images/icons/transactions/icon_contextmenu_edit.svg");
}

.subIconDelete {
  composes: subIcon;
  background-image: url("../../../../images/icons/transactions/icon_contextmenu_delete.svg");
}

.subIconProperty {
  composes: subIcon;
  background-image: url("../../../../images/icons/transactions/icon_contextmenu_property.svg");
}

.subIconContact {
  composes: subIcon;
  background-image: url("../../../../images/icons/transactions/icon_contextmenu_contact.svg");
}

.subIconGoToTimeline {
  composes: subIcon;
  background-image: url("../../../../images/icons/icon_contextmenu_goto_timeline.svg");
}

.subIconGoToDocuments {
  composes: subIcon;
  background-image: url("../../../../images/icons/icon_contextmenu_goto_documents.svg");
}