@import "../../../global/stylesheets/colours";

.error {
  p {
    color: $colour-error;
    margin: 0;
  }

  .description {
    font-size: 0.8rem;
  }

  border: 2px solid $colour-error;
  padding: 0.65rem;

  .button {
    background-color: $colour-blue-0;
    width: 100%;
    border-radius: 0;
    margin-top: 1rem;
  }
}
