@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.userCardNav {
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  background-color: white;

  @include tablet {
    padding: 0.4rem;
  }

  .subText {
    color: rgba(0, 0, 0, 0.44);
    font-weight: 700;
    font-size: 0.65rem;
    text-transform: uppercase;
    display: inline-block;
    background-color: rgba(128, 128, 128, 0.1);
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    margin-left: 0;

    @media (min-width: $widthTablet) {
      font-size: 0.8rem;
      margin-left: 0.4rem;
    }
  }

  .image {
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    flex: 0 20%;
    margin-top: 5px;
    align-self: center;

    div {
      position: relative !important;
      margin: 0 !important;
    }

    @media (min-width: $widthTablet) {
      flex: 0 10%;
    }

    @media (min-width: $widthDesktop) {
      flex: 0 5%;
    }
  }

  .actionIcon {
    height: 2rem;
  }

  .name {
    padding-left: 0.4rem;
    align-self: center;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    text-align: left;
    font-size: 0.8rem;
    //flex: 0 40%;
    flex: 1;

    @media (min-width: $widthTablet) {
      font-size: 1.8rem;
      flex: auto;
    }
  }

  .actionButton {
    align-self: center;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    flex: 0 0 auto;

    .text {
      width: 100%;
      padding-left: 6px;

      @media (min-width: $widthTablet) {
        padding-left: 1rem;
      }
    }

    @media (min-width: $widthTablet) {
      flex: 0 20%;
    }

    @media (min-width: $widthDesktop) {
      flex: 0 10%;
    }
  }

}