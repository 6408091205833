@import "../../../../global/stylesheets/mediaConfig";
@import "../../../../global/stylesheets/colours";
@import "../../../../global/stylesheets/styling";

.tenantDashboard {
  background-color: #e8f4fa;
  height: 100%;

  @include tablet {
    padding: 1rem;
  }

  .content {
    padding-top: 1rem;

    @include tablet {
      padding-top: 0;
    }
  }

  .parentRow {
    display: block;

    @include tablet {
      display: flex;
      margin-bottom: 1rem;
    }

    .column {
      flex: 0%;
      padding:0 15px;
      margin-bottom: 1rem;

      @include tablet {
        margin-bottom: 0;
      }
    }
  }
}

.card {
  position: relative;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,.1);
  background-color: white;
  padding: .4rem;
  height: 100%;

  .title {
    @include cardTitle;
  }
}

.cardNoPadding {
  composes: card;
  padding: 0 !important;
}

.row {
  display: flex;

  .col {
    flex: 0%;
    padding: .4rem;
  }
}

.propertyCol {
  composes: col;
  padding: 0 !important;
}

.tenancyTerms {
  text-align: left;
}