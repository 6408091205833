.success {
    text-align: left;
    color: black;

    h2{
        color: gray;
    }

    .steps {        
        text-align: left;
        margin: 2rem 0;

        .nextStepsHeading {
            font-size: 26px;
        }

        .nextStep {
            text-align: left;
            color: black;
            font-size: 20px;
            //font-weight: 400;
        }

        .stepDescription {
            font-size: 18px;
            text-align: left;
            color: gray;
        }
    }
}
