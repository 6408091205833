body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.dd-menu .dd-menu-items ul li > *:not(.dd-item-ignore),
.dd-menu .dd-menu-items ol li > *:not(.dd-item-ignore) {
  display: flex !important;
}

.infinite-scroll-component {
  overflow: visible !important;
}

input:focus {
  outline: none;
}

html {
  font-size: 16px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.gm-iv-container {
  display: none;
}

.react-date-picker__button {
  border: 0;
  padding: 4px 6px;
  background: $colour-blue-0 !important;
  border-top-right-radius: 0.35rem;
  border-bottom-right-radius: 0.35rem;
  padding: 0.5rem 0.8rem !important;
}

.react-date-picker__wrapper {
  display: flex;
  border: thin solid gray;
  width: 100%;
  border-radius: 0.35rem !important;
  border-radius: 0;
  background-color: $colour-shade-0;
  z-index: 1;
  border: 1px solid #ced4da !important;
}

.react-date-picker__calendar--open {
  z-index: 6 !important;
  width: 100% !important;
}

.react-calendar {
  width: 100% !important;
}

.react-date-picker__inputGroup {
  padding-left: 0.75rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  font-size: 1rem !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
  font-family: Rubik !important;
  color: rgb(73, 80, 87) !important;
}

.react-calendar__month-view__days__day--weekend {
  color: rgb(73, 80, 87) !important;
}

.input-range__slider {
  z-index: 0 !important;
}

.rc__indicator-separator {
  display: none;
}

.rc__dropdown-indicator svg {
  fill: $colour-b0bac9;
}

.rc__menu {
  z-index: 10 !important;
}

.ToolTipPortal div:first-of-type {
  z-index: 9999999 !important;
}

.reactour__helper--is-open button {
  width: 12px;
  height: 12px;
}

.reactour__helper--is-open div button {
  width: 16px;
  height: 12px;
}

.reactour__helper--is-open div nav button {
  width: 8px;
  height: 8px;
}

.e-table .e-groupcaption {
  text-align: left;
}

.e-control {
  font-family: "Rubik", sans-serif !important;
}

.e-acrdn-item {
  margin-bottom: 1rem;
  overflow: visible !important;
}

.e-expand-state .e-acrdn-header-content {
  color: black !important;
}

.e-active .e-acrdn-header {
  background-color: white;
}

.e-acrdn-header {
  background-color: #e5efff !important;;
  color: black !important;
}

.e-rowcell {
  padding: .25rem .5rem .25rem 21px !important;
}

.e-url .e-menu-icon {
  transform: translateY(50%);
}