@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/styling";

.propertySummaryCard {
  .maxRow {
    display: flex;
  }

  .hover {
    @include hoverOver();
  }

  .row {
    display: flex;

    .column {
      flex: auto;
      align-self: center;
    }

    .autoColumn {
      //flex: 0 0 auto;
      align-self: center;
    }

    .propertyName {
      color: black;
      font-weight: 400;
      font-size: .8rem;
      vertical-align: middle;
    }

    .propertyType, .propertyYearBuilt, span {
      //color: $colour-8c8c8c;
      font-weight: 400;
      display: inline;
      font-size: .8rem;
    }
  }

  .icon {
    margin-left: .25rem;
  }
}

.circle {
  position: relative;
  border-radius: 100px;
  text-align: right;
  padding: 0.1rem .6rem;
  font-size: .7rem;
  color: white !important;

  &.a{
    background-color: #0A8647 
  }

  &.b{
    background-color:#2EA949
  }

  &.c{
    background-color:#95CA53
  }

  &.d{
    background-color:#F1EC37
  }

  &.e{
    background-color:#F5AD34
  }

  &.f{
    background-color:#F0702F
  }

  &.g{
    background-color:#E92730
  }
}

.title {
  color: $colour-blue-1;
  font-weight: 700;
  font-size: .8rem;
  padding-right: 4px;
}