@import "../../../../global/stylesheets/mediaConfig";
@import "../../../../global/stylesheets/colours";

.page {
  //background-color: $colour-shade-0;
  background-color: #F5F5F5;
  //margin: 0 auto;
  //margin-top: 1rem;
  padding: 0 1rem 0 1rem;
  //margin: 1rem;


  @media (min-width: $widthTablet) {
    padding: 1rem;
    width: 100%;
  }
}

.actions {
  display: flex;
  margin-bottom: 1rem;
  justify-content: flex-end;

  .showOnlyMyTenancies {
    flex: 0 20%;
  }

  .column {
    flex: 0%;
    justify-self: flex-end;
  }

  .addButton {
    flex: 0 100%;

    @media (min-width: $widthTablet) {
      flex: 0 15%;
    }
  }
}

.listItem {
  margin-bottom: 0.8rem;
  background-color: $colour-shade-0;
  margin: 0;
  padding: 0.8rem;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  @media (max-width: $max-widthTablet) {
    padding: 0.5;
  }
}

.col {
  margin: 0;
  padding: 0;
  @media (max-width: $max-widthPhone) {
    width: 100% !important;
  }
}

.tenantName {
  margin-left: 3rem;
  font-size: 1.5rem;
  text-align: center;
  @media (max-width: $max-widthPhone) {
    text-align: left;
    margin-right: 1rem;
  }
}

.heading {
  padding: 0.2rem;
  text-align: left;
}

.columns {
  text-align: left;
}

.address {
  &:hover {
    cursor: pointer;
  }

  img {
    display: inline;
  }
}

.label {
  color: $colour-b0bac9;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.8rem;
}

.item {
  padding: 0.4rem 0 0.4rem 0;
  @media (max-width: $max-widthPhone) {
    padding: 0.5rem;
  }
}

.addressItem {
  composes: item;
  min-width: 8rem;
  margin: 0;
  @media (max-width: $max-widthSmallDesktop) {
    padding: 0rem 0 0rem 0;
    display: none;
  }
}

.itemDesktop {
  composes: item;

  @media (max-width: 1199px) {
    padding: 0rem 0 0rem 0;
    display: none;
  }
}

.title {
  color: $colour-blue-1;
  font-weight: 700;
  text-align: center;
}

.stats {
  text-align: left;
  cursor: pointer;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  img {
    margin: 0.5rem;
    margin-top: 2rem;
  }

  @media (max-width: $max-widthPhone) {
    margin: 2rem;
    padding: 2rem;
  }

  @media (max-width: $max-widthTablet) {
    display: flex;
    justify-content: center;
  }
}

.paddedContent {
  padding: 0;
}

.filter {
  padding-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex: 0%;

  .input {
    flex: 0 20%;
  }

  .filterIcon {
    flex: 0 5%;
    align-self: center;
    display: none;

    @media (min-width: $widthTablet) {
      display: inline-block;
    }
  }
}

.propertyName {
  color: $colour-8c8c8c;
  font-weight: 400;
  font-size: 0.85rem;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
  margin-top: 0.1rem;
}

.bottomRight {
  position: absolute;
  bottom: 10px;
  right: 25px;
  font-size: 0.6rem;
  color: $colour-shade-5;
  padding-top: 1rem !important;

  @media (max-width: $max-widthPhone) {
    right: 2.3rem;
  }
}

.dropdown {
  padding-left: 15px;

  @media (max-width: $max-widthPhone) {
    padding-right: 0px;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.2;
}

.actionIcon,
.pointer,
.deleteIcon {
  &:hover {
    cursor: pointer;
  }
}

.imageDiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: $max-widthTablet) {
    padding: 0;
    margin: 0;
    float: left;
    display: flex;
    flex-direction: row;
  }
}

.edit {
  &:hover {
    cursor: pointer;
  }
}

.ItemButtons {
  padding-right: 1rem;

  @media (min-width: $min-widthPhoneLandscape) {
    padding-right: 1rem;
    margin-left: -1.5rem;
  }

  @media (min-width: $min-widthDesktop) {
    padding-right: 2.5rem;
    margin-left: -5rem;
  }

  @media (min-width: $min-widthLargeDesktop) {
    padding-right: 1rem;
    margin-left: -1rem;
  }
}

.returnIcon {
  &:hover {
    cursor: pointer;
  }
}

.propertyName {
  @media (min-width: $widthTablet) {
    display: inline-block;
  }
}

.propertyNameBtn {
  @media (min-width: $widthTablet) {
    display: none;
  }

  text-align: initial;

  label {
    margin-bottom: 0;
  }

  width: auto;
  font-weight: 600;
  display: block;
  margin: 0 auto;
  float: none;
  background-color: $colour-shade-0;
  border: none;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  border-radius: 300px;
  font-size: 0.8rem;
  padding: 0.4rem;
  margin-top: -0.75rem;

  color: $colour-8c8c8c;
  letter-spacing: 0.5px;
}