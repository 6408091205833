@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/styling";

.invitationStatus {
  display: flex;
  margin: 1rem 0;
}

.button {
  align-self: center;
  margin: 0 auto;

  @include desktop {
    margin: 0;
  }
}

.text {
  align-self: center;
  flex: 0 50%;
  p {
    line-height: 1rem;
    font-size: 0.8rem;
    margin: 0; 
    color: black;
  }
}

.tooltipContent {
  p,
  .dateBy {
    font-size: 0.8rem;
    color: black;
  }
  h1 {
    font-size: 1rem;
    padding: 0;
    margin-bottom: 1rem;
  }
  font-size: 0.8rem;
  text-align: left;

  .links {
    img {
      margin-right: 1rem;
    }
    display: inline-block;
    padding: 0.5rem;
  }
}

.invite,
.cancelButton {
  @include buttonStyle;

  &:hover {
    cursor: pointer;
  }
}

.buttonContainer {
  > div {
    margin-top: 0;
  }
}

.tooltip {
  background-color: white;
}