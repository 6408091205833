@import "../../../global/stylesheets/colours";

.form {
  margin: 0 auto;
  max-width: 25rem;
}

.label {
  float: left;
  font-size: 0.7rem;
  color: $colour-shade-2;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05rem;
  margin-bottom: 0.25rem;
}

.input {
  border-radius: 0;
  background-color: $color-input-bg;
}

.button {
  background-color: $colour-blue-0;
  width: 100%;
  border-radius: 0;
  padding: 0.75rem;
  font-weight: 600;
  margin-top: 0.5rem;
}

.text {
  text-align: center;
  margin: 0 auto;
  color: $colour-shade-2;
  font-size: 0.85rem;
  margin-top: 1rem;
  justify-content: center;
}

h1 {
  color: $colour-shade-5;
  margin-bottom: 1rem;
}

.error {
  padding: 0.65rem;
  border: 1px solid $colour-error;
  margin-bottom: 1rem;
  color: $colour-error;
}

.loggedIn {
  word-break: break-all;
  color: $colour-shade-5;
}

.p {
  color: $colour-shade-5;
}

.link {
  color: $colour-blue-0;
}

.link:hover {
  cursor: pointer;
}

.loadingText {
  padding-top: 2rem;
  color: $colour-shade-5;
}

.logout {
  padding: 0.65rem;
  border: 1px solid $colour-green-0;
  margin-bottom: 1rem;
  color: $colour-green-0;
}

.body {
  ul {
    list-style: none;

    li {
      text-align: center;
      margin: 1rem;
      color: $colour-blue-0;
    }
  }
}

.sidebar {
  padding: 2rem;
}
