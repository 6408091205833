@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

body {
  overflow: hidden;
}

.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.left {
  flex: 1;
  margin-left: 1rem;
  max-width: 2rem;

  @media (min-width: $widthTablet) {
    margin-left: 2rem;
  }

  @media (max-width: $min-widthTablet) {
    flex: 1;
  }
}

.center {
  flex: 0 0 auto;
  margin: 0 auto;
  width: auto;

  .logo {
    float: left;
    margin-left: 1rem;
    max-width: 10rem;
    max-height: 77.8px;
  }

  @media (max-width: $min-widthPhone) {
    width: 22rem;
    flex: 6;

    .logo {
      float: none;
      margin-left: 0;
    }
  }
}

.profile {
  flex: 2;
  align-self: center;

  .row {
    display: flex;
    float: right;
  }

  .userInfoRow {
    display: flex;
    float: right;
    flex-direction: row-reverse;
  }

  .helpColumn {
    display: none;

    @include tablet {
      display: block;
    }
  }

  .helpIcon {
    padding: 0 1rem;

    &:hover {
      cursor: pointer;
    }
  }

  .column {
    flex: 0 0 auto;
    align-self: center;
  }

  .userContactInfoColumn {
    flex-wrap: nowrap;
    flex: 1;
    max-width: 20%;
  }

  .profileRow {
    display: flex;
  }

  .profileWrapper {
    float: right;
    text-align: right;
  }

  .textWrapper {
    display: none;
    margin-right: 0.4rem;

    @include tablet {
      display: block;
      flex: 0% !important;
    }
  }

  .image {
    &:hover {
      cursor: pointer;
    }
  }
}

.right {
  flex: 2;

  @media (max-width: $min-widthTablet) {
    flex: 1;
  }

  div {
    display: flex;
    flex: 1;
    float: right;
  }

  .account {
    max-width: 50rem;
    width: 23rem;
    min-height: 2.5rem;

    .profileImage {
      display: block;
      margin-right: 4rem;
      margin-left: 0;

      &:hover {
        cursor: pointer;
      }

      @media (max-width: $max-widthPhone) {
        margin: 0;
      }

      @include tablet {
        text-align: left;
        margin-left: 1rem;
        flex: 0;
      }
    }

    .welcomeText {
      display: none;
      flex: 0;
      flex-direction: column;

      @include tablet {
        .welcome,
        .name {
          font-size: 0.8rem;
        }
      }

      .welcome {
        font-weight: bold;
      }

      .name {
        color: $colour-shade-2;
      }

      @include tablet {
        display: flex;
        flex: 2;
      }
    }

    @media (min-width: $min-widthPhone) and (max-width: $max-widthPhoneLandscape) {
      width: 14rem;
    }

    @media (max-width: $max-widthPhone) {
      width: 3rem;
    }

    .signOutButton {
      flex: 2;

      div {
        justify-content: flex-end;
        margin-right: 1rem;
      }
    }
  }
}

.stickyNav {
  composes: nav;
  background-color: $colour-blue-0;
}

.col {
  align-self: center;
}

.logo {
  padding: 1rem;
  max-width: 10rem;

  @media (max-width: $min-widthLargeDesktop) {
    max-width: 8rem;
  }

  @media (max-width: $max-widthSmallDesktop) {
    max-width: 8rem;
  }
}

.signOut {
  color: $colour-blue-0;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.8rem;
}

.signOut:hover {
  cursor: pointer;
  font-weight: 600;
}

.menu {
  margin-right: 2rem;

  .desktop {
    display: none;

    @include fullHD {
      display: inline-block;
    }
  }

  .mobile {
    @include fullHD {
      display: none;
    }
  }
}

.notificationIcon:hover {
  cursor: pointer;
}

.wrapper {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  width: 100%;
  //box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: $colour-shade-0;
  border-bottom: 1px $colour-e4e4e4 solid;

  .smallLogo {
    float: left;
    max-height: 2rem;
    padding-right: 1rem;
    display: none;
    opacity: 0;
    -webkit-animation: slideIn 0.25s forwards;
    -moz-animation: slideIn 0.24s forwards;
    animation: slideIn 0.25s forwards;
  }

  .logo {
    display: inline-block;
    -webkit-animation: slideFromAbove 0.25s forwards;
    -moz-animation: slideFromAbove 0.25s forwards;
    animation: slideFromAbove 0.25s forwards;
  }
}

@-webkit-keyframes slideIn {
  0% {
    transform: translateX(-900px);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes slideIn {
  0% {
    transform: translateX(-900px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideIn {
  0% {
    transform: translateX(-900px);
  }
  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes slideFromAbove {
  0% {
    transform: translateY(-900px);
  }
  100% {
    transform: translateY(0);
  }
}
@-moz-keyframes slideFromAbove {
  0% {
    transform: translateY(-900px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slideFromAbove {
  0% {
    transform: translateY(-900px);
  }
  100% {
    transform: translateY(0);
  }
}

@media (min-width: $min-widthPhone) {
  .stickyWrapper {
    composes: wrapper;
    position: fixed;
    width: 100%;
    z-index: 2;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;

    .nav {
      max-height: 2rem;
      min-height: 2rem;
    }

    .smallLogo {
      display: inline-block;
      opacity: 1;
    }

    .logo {
      display: none;
      -webkit-animation: slideFromAbove 0.5s forwards;
      -moz-animation: slideFromAbove 0.5s forwards;
      animation: slideFromAbove 0.5s forwards;
    }
  }
}

.left {
  composes: col;
  text-align: left;
}

.right {
  composes: col;
  text-align: right;
}

.item {
  color: $colour-blue-0;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.8rem;
  margin-right: 1rem;
}

.itemAddProperties {
  composes: item;

  @media (max-width: $max-widthPhonePlusLandscape) {
    display: none;
  }
}

.logo:hover,
.item:hover {
  cursor: pointer;
}

.signOutIcon {
  display: none;

  @media (max-width: $min-widthPhone) {
    display: block;
    float: right;
    align-items: center;
  }
}

.item,
.notificationIcon {
  @media (max-width: $min-widthPhone) {
    display: none;
  }
}

.signOut {
  padding-right: 2rem;

  @media (max-width: $max-widthPhone) {
    display: none;
  }
}

.menu {
  &:hover {
    cursor: pointer;
  }
}

.notificationDropdown {
  ul {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
    list-style: none;

    li {
      padding: 0.4rem;
      color: $colour-shade-5;

      img {
        max-width: 100%;
        max-height: 100%;
      }

      p {
        color: $colour-shade-5;
        margin-bottom: 0;
      }

      .title {
        font-size: 1.2rem;
        font-weight: bold;
      }

      .body {
        font-size: 1rem;
      }

      .date {
        width: 100%;
        float: right;
        font-size: 0.6rem;
        color: lightgray;
      }
    }
  }
}

.notificationWrapper {
  float: right;
  text-align: left;

  .notificationDropdown {
    position: fixed;
    background-color: $colour-shade-0;
    box-shadow: 0 4px 8px 0 $colour-shade-5;
    min-width: 20rem;
    width: 20rem;
    top: 2rem;
    z-index: 999999999999999999;

    .header {
      background-color: $colour-blue-0;
      color: $colour-shade-0;
      padding: 0.4rem;
      text-align: left;
    }
  }

  .notificationIcon {
    position: absolute;

    @media (min-width: $min-widthTablet) {
      right: 0;
    }
    @media (min-width: $min-widthLargeDesktop) {
      right: -4rem;
    }
  }
}

.dropdownMenu {
  display: block !important;
}

.notificationDesc {
  display: block;
  font-size: 0.6rem;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  overflow: hidden;
}

.listItem {
  .row {
    display: flex;
  }

  img {
    max-width: 2rem;
  }
}

.welcome,
.name {
  font-size: 0.9rem;
  text-align: left;
  display: block;
}

.signOutButton {
  align-self: center;
}

.dashboardButtons {
  justify-content: flex-end;
}

.welcome,
.name {
  text-align: right;
}

.userIcon {
  height: 0.8rem;
}

.typeOrRole {
  span {
    padding-left: 0.6rem;
  }
}

.shrink {
  .logo {
    max-width: 5rem;
  }
}

.actions {
  align-self: center;
}
