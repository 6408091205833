@import '../../../global/stylesheets/mediaConfig';
@import '../../../global/stylesheets/colours';
@import '../../../global/stylesheets/styling';
@import '../../../global/stylesheets/components/links/index.scss';

.issuesStatus {
    display: block;
    width: 100%;
}

.title {
    width: 100%;
}

.columns {
    display: flex;
    flex-direction: row;

    .column {
        display: flex;
        flex-direction: column;
        flex: 1;
        font-size: 0.8rem;
        margin: 0 0.4rem;
        cursor: pointer;

        @include hoverOver();
    }
}

.number {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: -0.5rem;
}

.numberNew {
    color: $colour-orange-0;
}

.numberInProgress {
    color: $colour-green-0;
}

.numberTotal {
    color: $colour-blue-0;
}

.baseIcon {
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    background: #e6e7ed;
    -moz-border-radius: 20px;
    -webkit-border-radius: 20px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    margin: 0 auto;
}

.add {
    span {
        margin-left: 0.5rem;
        @include link();
    }
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 0.8rem;
    color: #0085ca;

    @include tablet {
      top: .5rem;
      right: .5rem;  
    }

    &:hover {
        cursor: pointer;
    }
}
