@import "../../../../global/stylesheets/colours";
@import "../../../../global/stylesheets/_mediaConfig";

.chatListItem {
  border: 1px solid #F0F2F6;
  border-right: none;
  border-left: 0;
  padding: .4rem;
  position: relative;

  &:hover {
    cursor: pointer;
    background-color: #F0F2F6;
  }

  label {
    margin: 0;
  }
}

.chatListItemActive {
  composes: chatListItem;
  background-color: #F0F2F6;
  border-left: 2px solid #0085ca;
}

.row {
  display: flex;
  align-content: center;
  align-items: center;
  color: black;

  p {
    color: black;
    margin-bottom: 0;

    .header {
      font-weight: bold;
    }
  }

  .avatar {
    flex: 0 15%;

    div:first-of-type {
      position: relative !important;
      margin: 0 !important;
    }
  }

  .message {
    flex: 100%;
  }

  .actions {
    flex: 0 0 auto;
    align-self: flex-end;
    text-align: right;

    .dateTime {
      font-size: .8rem;
      color: #2b2b2b;
    }
  }
}

.bottomRight {
  position: absolute;
  bottom: 0;
  right: 10px;
  font-size: 0.6rem;
  color: #000;
}

.message {
  color: #707070 !important;
}

.circle {
  -webkit-border-radius:8px;
    -moz-border-radius:8px;
    border-radius:8px;
    border:1px solid #ccc;
    width:8px;
    height:8px;
}