@import "../../../global/stylesheets/colours";

.userManagement {
  background-color: $colour-shade-0;
  padding: 2rem;
  box-shadow: $colour-shade-5 0px 1px 6px, $colour-shade-5 0px 1px 4px;
  margin-bottom: 1rem;
}

.addUser {
  color: $colour-shade-0;
  background-color: $colour-blue-0;
  border: none;
  padding: 0.65rem;
  border-radius: 5%;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 12rem;
  margin-bottom: 1rem;
  float: right;
}

.page {
  margin: 0 auto;
}
