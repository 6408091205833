@import "../../../../../../global/stylesheets/mediaConfig";
@import "../../../../../../global/stylesheets/colours";

.tenancyStatus {
  color: white;
  font-size: 0.8rem;
  padding: 0;
  border-radius: 2rem;
  display: inline-block;
  text-align: center;
  max-width: 4rem;
  width: 100%;

  @include tablet {
    padding: 0 1rem;
    max-width: 100%;
  }

  &.approved {
    background-color: blue;
  }

  &.current {
    background-color: green;
  }

  &.ending {
    background-color: orange;
  }

  &.past {
    background-color: red;
  }

  &.rejected {
    background-color: red;
  }

  &.new {
    background-color: #0085ca;
  }
}