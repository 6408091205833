@import "../../../../global/stylesheets/mediaConfig";
@import "../../../../global/stylesheets/colours";

.page {
  //padding-top: 1rem;
}

.content {
  //padding: 0 2rem 1rem 1rem;
}

.row {
  display: flex;
  justify-content: flex-end;
  .column {
    flex: 0%;
  }

  .iconColumn {
    justify-self: flex-end;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-right: .5rem;

    @include tablet {
      flex-direction: row;
    }
  }
}

.tabRow {
  justify-content: flex-start !important;
  margin-bottom: 0.5rem;
}

.actionMenu {
  display: flex;
  flex-direction: row;
  margin-right: 0.25rem;
}

.iconAction {
  display: block;
  min-width: 2rem;
  min-height: 2rem;
  background-repeat: no-repeat;
  background-size: 100%;
}

.actionText {
  margin-left: 0.1rem;
  margin-top: 0.225rem;
  margin-right: 0.5rem;
  display: none;

  @include desktop {
    display: block;
  }
}

.icon {
  margin-right: 1rem;
  display: inline-block;

  .text {
    display: none;

    @include tablet {
      display: inline-block;
    }
  }

  img {
    margin-right: 0.2rem;
  }

  &:hover {
    cursor: pointer;
  }
}

.grid {
  width: 100%;
  padding-top: 10px;
  //padding-right: 0;
}

.dropdown {
  display: flex;
  min-width: 5rem;
  flex-direction: column;
  justify-content: left;

  @include mobileLandscape {
    flex-direction: row;
    justify-content: left;
  }
}

.incomeExpenditureDropdown {
  composes: dropdown;
  max-width: 12rem;
  margin-bottom: 0.5rem;
}

.gridRow {
  margin-top: 0.25rem;
}

.gridColumn {
  width: 100%;
}

.tabNav {
  width: 100%;
  margin: 0 auto;

  @include mobileLandscape {
    margin: unset;
  }
}

.searchInput {
  display: flex;
  flex: 0 100%;
  margin-left: 1rem;

  @include mobileLandscape {
    flex: 0 50%;
    min-width: 12rem;
    max-width: 12rem;
  }

  @include tablet {
    min-width: 12rem;
    max-width: 15rem;
  }
}

.dateRangePicker {
  display: flex;
  flex: 0 100%;
  margin-left: 1rem;

  @include mobileLandscape {
    flex: 0 50%;
    min-width: 12rem;
    max-width: 12rem;
  }

  @include tablet {
    min-width: 12rem;
  }
}

.marginTop {
  margin-top: 1rem;
}

.amount {
  font-size: 1.2rem;

  &.income {
    color: green;
  }

  &.expense {
    color: red;
  }
}

.subIcon {
  display: block;
  min-width: 1.2rem;
  min-height: 1.2rem;
  background-repeat: no-repeat;
  background-size: 100%;
  align-self: center;
}

.subIconAdd {
  composes: subIcon;
  background-image: url("../../../../images/icons/transactions/icon_contextmenu_addexpense.svg");
}

.subIconAddIncome {
  composes: subIcon;
  background-image: url("../../../../images/icons/actionmenu/icon_contextmenu_addincome.svg");
}

.subIconAddExpense {
  composes: subIcon;
  background-image: url("../../../../images/icons/actionmenu/icon_contextmenu_addexpense.svg");
}

.subIconDelete {
  composes: subIcon;
  background-image: url("../../../../images/icons/transactions/icon_contextmenu_delete.svg");
}

.subIconExportCsv {
  composes: subIcon;
  background-image: url("../../../../images/icons/transactions/icon_contextmenu_csv.svg");
}

.subIconExportPdf {
  composes: subIcon;
  background-image: url("../../../../images/icons/transactions/icon_contextmenu_pdf.svg");
}

.subIconExportExcel {
  composes: subIcon;
  background-image: url("../../../../images/icons/transactions/icon_contextmenu_excel.svg");
}

.subIconChangePassword {
  composes: subIcon;
  background-image: url("../../../../images/icons/user_changepassword_icom.svg");
}

.subIconActivate {
  composes: subIcon;
  background-image: url("../../../../images/icons/user_activate_icon.svg");
}

.subIconMarkAsPaid {
  composes: subIcon;
  background-image: url("../../../../images/icons/transactions/icon_contextmenu_paid.svg");
}

.subIconAddDocument {
  composes: subIcon;
  background-image: url("../../../../images/icons/actionmenu/icon_contextmenu_add_document.svg");
}

.subIconAddTenancyContract {
  composes: subIcon;
  background-image: url("../../../../images/icons/actionmenu/icon_contextmenu_add_document.svg");
}

.subIconAddInventory {
  composes: subIcon;
  background-image: url("../../../../images/icons/actionmenu/icon_contextmenu_add_tenancy.svg");
}

.subIconAddIssue {
  composes: subIcon;
  background-image: url("../../../../images/icons/actionmenu/icon_contextmenu_add_issue.svg");
}

.subIconEdit {
  composes: subIcon;
  background-image: url("../../../../images/icons/transactions/icon_contextmenu_edit.svg");
}

.subIconDelete {
  composes: subIcon;
  background-image: url("../../../../images/icons/transactions/icon_contextmenu_delete.svg");
}

.subIconProperty {
  composes: subIcon;
  background-image: url("../../../../images/icons/transactions/icon_contextmenu_property.svg");
}

.subIconContact {
  composes: subIcon;
  background-image: url("../../../../images/icons/transactions/icon_contextmenu_contact.svg");
}

.subIconGoToTimeline {
  composes: subIcon;
  background-image: url("../../../../images/icons/icon_contextmenu_goto_timeline.svg");
}

.subIconGoToDocuments {
  composes: subIcon;
  background-image: url("../../../../images/icons/icon_contextmenu_goto_documents.svg");
}

.syncfusionDropdown {
  background-color: transparent !important;
  padding: 0;
  font: inherit;

  ul {
    background-color: white !important;
  }
}

.disabledActionButton {
  pointer-events: none;
  opacity: 0.5;
}

.mobileAmountStatusRow {
  margin-bottom: 0;
}

.actionColumn {
  padding: 0 !important;
}

.amount {
  font-size: 0.8rem;
  color: green;

  @include tablet {
    font-size: 1.2rem;
  }
}