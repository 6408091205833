.streetView {
  width: 100%;
  z-index: 0;
  position: absolute;
  //min-height: 16rem;
  pointer-events: none;

  @media(max-width: 500px) {
    height: 16rem;
  }

  .map {
    position: relative;
  }
}

.overlay {
  position: relative;
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  cursor: pointer; /* Add a pointer on hover */
  filter: brightness(75%);
}

.canDrag {
  pointer-events: unset !important;
}

.map {
  height: 100%;
}

.test {
  height: 100%;
}