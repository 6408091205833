@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/styling";

.dropdown {
  display: block;
  width: 100%;

  :global {
    .Select.is-open { position: relative; z-index: 1000; }
  }

  div[class^="rc__control"],
  div[class*="rc__control"] {
    width: 100%;
    border-radius: 5px;
    background-color: $color-input-bg;
    color: black;
    border: 1px solid #f0f3ff;
  }

  div[class^="rc__menu"],
  div[class*="rc__menu"] {
    text-align: left;
  }
}

.smallerFont {
  font-size: 0.8rem;

  @include tablet {
    font-size: 1rem;
  }
}
