@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets//colours";

.gmap {
  width: 100%;
  height: 18rem;

  @media (max-width: $max-widthPhone) {
    height: 10rem;
  }
}

.gmapFullHeight {
  composes: gmap;
  height: 100% !important;
}
