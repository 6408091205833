@import '../../../../../global/stylesheets/colours';
@import '../../../../../global/stylesheets/mediaConfig';
@import '../../../../../global/stylesheets/styling';

.page {
    padding: 0 1rem 0 1rem;
    padding-bottom: 1rem;

    @include tablet {
        padding: 0 2rem 0 1rem;
    }

    hr {
        width: 80%;
        border-top: 2px solid #dcfafa;
    }

    .row {
        display: flex;
        flex-wrap: wrap;

        @include tablet {
            display: flex;
            justify-content: space-between;
            margin: 0 -15px;
        }

        .column {
            display: flex;
            flex: 0 50%;

            @include tablet {
                flex: 25%;
                padding: 0 0.25rem;
                max-width: 18rem;

                button {
                    padding-right: 0 !important;
                    float: right;
                }
            }
        }

        .columnSmall {
            flex: 0 49%;
            padding: 0 1rem;

            @include tablet {
                flex: 0 21%;
                padding: 0 15px;
            }
        }
    }

    #title {
        width: 100%;
    }

    .datePicker {
        display: flex;
    }

    .title {
        border-bottom: 2px solid $colour-blue-0;
        width: 100%;
        text-align: left;
        margin-bottom: 0.5rem;
        padding-bottom: 0.35rem;
        text-transform: capitalize;
        font-size: 1rem;
        font-weight: 700;
        letter-spacing: 1px;
        color: $colour-blue-0;
    }

    .label {
        float: left;
        font-size: 0.7rem;
        color: $colour-b0bac9;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.05rem;
        margin-bottom: 0.25rem;
        display: block;
        width: 100%;
        text-align: left;
        margin: 0.5rem;
    }

    .input {
        border-radius: 5px;
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: $colour-495057;
        background-color: $color-input-bg;
        background-clip: padding-box;
        border: 1px solid #f0f3ff;
        transition: border-color 0.15s;
    }

    .columnSmall {
        text-align: left;
        flex: 0 20%;
        padding: 0 15px;
    }

    .columnDate {
        flex: 0 50%;
        text-align: left;

        div {
            min-width: 15rem;
        }
    }

    .checkboxRow {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        @include tablet {
            margin-left: 0;
        }
    }

    .checkboxRowWrapper {
        display: flex;
        flex-direction: column;

        @include tablet {
            margin-left: 0;
            margin-right: 0;
        }

        .checkboxRowLeft {
            width: -webkit-fill-available;
        }
        .checkboxRowRight {
            width: -webkit-fill-available;
        }

        .checkboxList {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: left;
        }

        .checkbox {
            min-width: 8rem;
            max-width: 10rem;
        }
    }
}

.addButtonColumn {
    composes: column;
    flex: 0 85% !important;
    padding-right: 0 !important;
}

.rowJustifyLeft {
    composes: row;
    justify-content: flex-start !important;
}

.rowJustifyRight {
    composes: row;
    justify-content: flex-end !important;
    padding: 1rem;
}

.calendarIcon {
    @include desktop {
        margin-right: 0.6rem;
    }
}

.flexColumn {
    display: flex;
    flex-direction: column;
    flex: 0 90%;
}

.durationRow {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.durationValueContainer {
    padding: 0 !important;
    margin-bottom: 0.2rem;
    flex: 0 30% !important;

    .durationValue {
        min-width: 3.4rem;
    }
}

.durationRange {
    min-width: 6.2rem;
    flex: 0 65% !important;
}
