@import "../../../../global/stylesheets/colours";
@import "../../../../global/stylesheets/mediaConfig";

.page {
  overflow: hidden;

  :global {
    .e-acrdn-item {
      text-align: left;
      margin-bottom: 0;
    }
  }
}

.accordionButton {
  padding: .5rem;
  background-color: #f4f4f4;
  color: #444;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border: none;

  &:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(-45deg);
  }

  &[aria-expanded='true']::before, [aria-selected='true']::before {
    transform: rotate(45deg);
  }
}

.form {
  margin: 0 auto;
}

.row {
  display: flex;
  margin: 0 -15px;
  flex-wrap: wrap;

  .column {
    flex: 0 50%;
    padding: 0 15px;
  }
}

.label {
  text-align: left;
  font-size: 0.7rem;
  color: $colour-shade-3;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05rem;
  margin-bottom: 0.25rem;
  margin-top: 0.5rem;
  width: 100%;
}

p.text {
  color: $colour-shade-5 !important;
}

.landRegistryMatch {
  align-items: center;
  align-content: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
  margin: 0 auto;
}

.lrMatch {
  font-size: 1rem;
  align-self: center;
  color: $colour-shade-4;
  font-weight: 200;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.lrSuccess {
  svg {
    width: 35px;
    height: 35px;
  }
}

.input {
  border-radius: 0;
  background-color: $color-input-bg;
}

.addressFindButton:hover {
  cursor: pointer;
}

.button {
  background-color: $colour-blue-0;
  width: 100%;
  border-radius: 0;
  padding: 0.75rem;
  font-weight: 600;
  margin-top: 0.5rem;
}

.addPropertyButton {
  border: 0;
  border-radius: 1.5rem;
  z-index: 999 !important;
}

.text {
  text-align: center;
  margin: 0 auto;
  color: $colour-shade-2;
  font-size: 0.85rem;
  margin-top: 1rem;
  justify-content: center;
}

h1 {
  color: $colour-shade-5;
  margin-bottom: 1rem;
}

.error {
  padding: 0.65rem;
  border: 1px solid $colour-error;
  margin-bottom: 1rem;
  color: $colour-error;
}

.loggedIn {
  word-break: break-all;
  color: $colour-shade-5;
}

.formTitle {
  color: $colour-shade-3;
  text-align: left;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.link {
  color: $colour-blue-0;
}

.link:hover {
  cursor: pointer;
}

.emailExists {
  color: $colour-error;
  padding-top: 0.25rem;
}

.h1 {
  text-align: left;
  margin-bottom: 0;
}

.h2 {
  color: $colour-shade-3;
}

.addressToggle {
  text-align: right;
  color: $colour-blue-0;
  font-size: 0.75em;
  margin-bottom: 0;
}

.addressToggleOption {
  display: inline-block;
}

.addressToggleOption:hover {
  cursor: pointer;
  font-weight: bold;
}

.sliderInput {
  padding: 0.5rem;
}

.inputAddress {
  composes: input;
  margin: 0.2rem 0;
}

.inputYearsOfInvestment {
  composes: input;
}

.inputYearsOfInvestment::-webkit-inner-spin-button,
.inputYearsOfInvestment::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.findAddress {
  color: $colour-shade-5;
}

.text {
  padding-top: 2rem;
  color: $colour-shade-5;
}

.inputHolder {
  position: relative;
}

.dropdown {
  position: absolute;
  background-color: $colour-shade-0;
  color: $colour-shade-5;
  max-height: 14em;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 99999999999999999999;
}

.input,
.submit {
  display: inline-block;
  border-radius: 0;
  -webkit-appearance: none;
  border-radius: 0.35rem;
  background-color: $color-input-bg;
  border: 1px solid #f0f3ff;
}

.addressInput {
  composes: input;
  width: 80%;
  display: inline-block;
}

.selectedAddress {
  composes: input;
  width: 100%;
  display: inline-block;
}

.addressFindButton {
  composes: input;
  width: 20%;
  display: inline-block;
  background-color: $colour-2ecc71;
  color: $colour-shade-0;
  border: 0;
}

.addressResultsDropdown {
  composes: dropdown;
  color: $colour-shade-5;
  font-size: 0.8rem;
  text-align: left;
  overflow-y: scroll;
  box-shadow: 0 4px 8px 0 $colour-shade-5;
}

.addressResultsDropdown ul {
  list-style: none;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.addressResultsDropdown ul li {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.4rem;
  margin-left: 0;
  font-size: 0.8rem;
}

.addressResultsDropdown ul li:hover {
  background-color: $colour-shade-0;
  cursor: pointer;
}

.emailExists {
  color: $colour-error;
  padding-top: 0.25rem;
}

.close {
  position: absolute;
  top: -25px;
  right: 0px;
}

.sticky {
  position: sticky;
  bottom: 0;
  height: 30px;
  width: 50%;
  float: right;
  background-color: $colour-shade-0;
  z-index: 4;
}

.friendlyProperty {
  margin-top: -1rem;
}

.ownerOf {
  font: Medium 22px/27px Rubik;

  .yesNo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .option {
      display: flex;
      border: $colour-shade-2 2px solid;
      border-radius: 10px;
      width: 48%;
      padding: 0.2rem 2rem 0.2rem 0.5rem;
      cursor: pointer;

      @include mobileLandscape {
        padding: 0.2rem 2rem 0.2rem 1rem;
      }

      img {
        @include square(2.5rem);
        margin: 0 0.2rem;
      }

      .text {
        padding: 0;
        padding-left: 0.2rem;
        margin: auto 0;
        text-align: left;

        @include mobileLandscape {
          padding-left: 1rem;
        }
      }

      .title {
        font-weight: bold;
        font-size: 1rem;
      }

      .description {
        color: $colour-shade-2;
      }
    }

    .selected {
      border: #89a2ff 2px solid;
    }
  }
}

.ownerTesting {
  display: flex;
  flex-direction: row;
  max-height: 2rem;
  margin-bottom: 0.5rem;

  @include tablet {
  }
}

.labelReduced {
  width: 64%;
  margin-top: 1rem;
  margin-bottom: 0;

  @include tablet {
    margin-bottom: 0;
  }
}

.tenantInResidence {
  display: flex;
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
  color: $colour-shade-4;
  text-align: left;
  border-left: none !important;
  border-right: none !important;
  border-top: 1px solid $colour-shade-4 !important;
  border-bottom: 1px solid $colour-shade-4 !important;
  width: 100%;
  background: $colour-shade-0;
  padding: 0.25rem 0;

  :hover {
    cursor: pointer;
  }

  .tenancyDetailsIcon {
    flex: 0 5%;
    display: block;
    margin-right: 0.25rem;
  }

  .tenancyDetailsText {
    flex: 0 90%;
  }

  .tenancyDetailsArrow {
    flex: 0 5%;
    font-size: 1.75rem;
    margin: auto 0;
  }

  img {
    @include square(2.5rem);
  }

  p {
    color: $colour-shade-4;
    font-size: 0.85rem;
    line-height: 1rem;
    margin-bottom: 0;
  }
}

.yesNoInline {
  display: inline-flex;
  width: 36%;
  margin: auto 0;
  min-width: 10rem;

  @include tablet {
    margin: auto 0;
  }
}

.inputDatePicker {
  width: 100%;

  .calendarIcon {
    width: 1.2rem;
  }
}

.button {
  width: 45%;
  border-radius: 0;
  padding: 0.75rem;
  font-weight: 600;
  margin-top: 0.5rem;
}

.navButton {
  border: 0;
  border-radius: 1.5rem;
  z-index: 999 !important;
}

.backButton {
  composes: navButton;
  float: left;
  background-color: $colour-shade-0;
  color: $colour-blue-0;
  border: solid 2px $colour-blue-0;
}

.nextButton {
  composes: navButton;
  float: right;
  background-color: $colour-blue-0;
}

.takeTour {
  float: right;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  top: 0.5rem;
  right: 2.5rem;
  cursor: pointer;

  .takeTourIcon {
    padding-right: 0.2rem;
  }
}

.slidedown {
  overflow: hidden;
}

.manualAddressSlidedown {
  display: block;
  margin-bottom: 0.5rem;
}

.calculate {
  width: 100%;
  text-align: center;
  background-color: orange;
  color: white;
  border: none;
  border-radius: 1rem;
}

.infoIcon {
  @include square(1rem);
}

// This bad using the !important, will update once the RCCurrencyInput component is
// made more flexible to allow custom css
.rcCurrencyInput {
  input {
    width: 100% !important;
    height: calc(1.5em + 0.75rem + 2px) !important;
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #495057 !important;
    border-radius: 0px !important;
    display: inline-block !important;
    -webkit-appearance: none !important;
    border-radius: 0.35rem !important;
    background-color: #f9faff !important;
    border: 1px solid #f0f3ff !important;
  }
}

.spacer{
  height: 10px;
}