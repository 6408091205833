@import '../../../global/stylesheets/colours';
@import '../../../global/stylesheets/mediaConfig';
@import '../../../global/stylesheets/components/links/index.scss';

.dashboardNavigationPanel {
    overflow: auto;
    padding: 0;
    min-height: 8rem;
    height: calc(100vh - 3.875rem);

    @include desktop {
        z-index: 5;
        background: $colour-shade-0;
        overflow: hidden;
    }
}

.ul {
    padding-left: 0;
    list-style: none;
    text-align: left;
    -webkit-transition-duration: 0.8s;
    display: inline;

    &.sticky {
        position: fixed;
        left: 0;
        top: 2.8rem;
    }

    li {
        padding: 0 0.5rem;
        text-align: left;
        color: $colour-b0bac9;

        img {
            padding-right: 0.8rem;
            display: inline-block;
            margin-bottom: 0.1rem;
        }

        &.active {
            color: $colour-blue-0;
            border-left: 2.5px solid $colour-blue-0;
            background-color: $colour-cyan-0;
        }

        &:hover {
            color: $colour-blue-0;
            background-color: $colour-cyan-0;
            cursor: pointer;
        }
    }
}


@-webkit-keyframes slide {
    100% {
        left: 0;
    }
}

@keyframes slide {
    100% {
        left: 0;
    }
}

.buildNumber {
    bottom: 0;
    font-size: 0.75rem;
    color: $colour-b0bac9;
    @include link();
}

.childList {
    list-style: none;
    background-color: #f9f9f9;
    padding-inline-start: 0 !important;

    &.small {
        li {
            font-size: 0.65rem;
        }
    }

    li {
        font-size: 1rem;
        padding: 0.4rem;
        padding-left: 1rem;
    }
}

.showToggle {
    font-size: 0.8rem;
    color: #0085ca;

    img {
        padding-right: 1.3rem !important;
        display: inline-block;
        margin-bottom: 0.1rem;
    }

    &:hover {
        background-color: none;
    }
}

.anchor {
    &:hover {
      text-decoration: none;
    }
  }