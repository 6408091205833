@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/styling";

.showOwnPropertiesToggle {
  display: none;

  @media (min-width: $widthFullHD) {
    display: inline-block;
    width: auto;
    max-height: 1rem;
    margin-top: -1.25rem;
  }
}

.materialCheckbox {
  @include checkbox;
}
