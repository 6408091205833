@import "../../../../global/stylesheets/mediaConfig";
@import "../../../../global/stylesheets/colours";

.page {
  //margin: 0 auto;
  margin-bottom: 1rem;
}

.content {
  padding-bottom: 1rem;

  @media (min-width: $widthTablet) {
    //padding: 0 2rem;
  }
}

.routedPage {
  //background-color: $colour-shade-0;
  //box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  height: 100%;

  padding: 0;

  @media (min-width: $widthTablet) {
    //padding: 0 15px 0 15px;
  }
}

.mapWrapper {
  @include desktop {
    max-width: 25vw;
    margin-top: 0;
    margin-left: 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
    height: 100%;
  }
  margin-left: 0;
  padding: 0;
  margin-top: 1rem;
}

.mapContainer {
  .map {
    background-color: $colour-shade-0;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  }
}

.info {
  padding: 1rem;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: $colour-shade-0;
  min-height: 7.6rem;
  margin-bottom: 2rem;

  @media (max-width: $max-widthPhone) {
    margin-bottom: 1rem;
  }

  .statistic {
    color: $colour-shade-5;
    padding: 1rem;
    font-size: 2.2rem;
  }

  .roiHeader {
    background-color: #afc7a8;
    color: $colour-shade-0;
    padding: 0.6rem;
    font-size: 1.4rem;
  }

  .yieldHeader {
    background-color: #fcbd63;
    color: $colour-shade-0;
    padding: 0.6rem;
    font-size: 1.4rem;
  }

  .investmentHeader {
    background-color: #e06963;
    color: $colour-shade-0;
    padding: 0.6rem;
    font-size: 1.4rem;
  }

  .profitHeader {
    background-color: #7f9aff;
    color: $colour-shade-0;
    padding: 0.6rem;
    font-size: 1.4rem;
  }
}

.infoLarge {
  padding: 1rem;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: $colour-shade-0;
  min-height: 10rem;
  margin-bottom: 2rem;

  @media (max-width: $max-widthPhone) {
    margin-bottom: 1rem;
  }
}

.recentAddedPropertySection {
  .header {
    padding: 1rem;
    background-color: #00bfa5 !important;
    color: $colour-shade-0;
    text-align: left;
    font-size: 1.4rem;
    .date {
      font-size: 0.8rem;
    }
  }

  .body {
    background-color: $colour-shade-0;
    padding: 1rem;
  }
}

.yourPropertiesSelectSection {
  margin-top: 1rem;
  margin-bottom: 1rem;

  .header {
    padding: 1rem;
    background-color: #ff6e40 !important;
    color: $colour-shade-0;
    text-align: left;
    font-size: 1.4rem;
    .desc {
      font-size: 0.8rem;
    }
  }

  .body {
    background-color: $colour-shade-0;
    padding: 1rem;
  }
}

.marginTop {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.header {
  font-size: 0.75rem;
  font-weight: 500;
  text-align: left;
  text-transform: uppercase;
  color: $colour-shade-3;
  letter-spacing: 1px;
}

.value {
  display: block;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
}

.annualIncomeAndExpenditure,
.totalEquity {
  margin-top: 1rem;
  padding: 1rem;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: $colour-shade-0;

  @media (max-width: $max-widthSmallDesktop) {
    margin-top: 0px;
  }
}

.rowEqHeight {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.icon {
  padding: 0.65rem;
  background-color: red;
  border-radius: 10%;

  display: block;
  height: 60px;
  width: 60px;
  line-height: 60px;
}

.baseIcon {
  display: block;
  width: 40px;
  height: 40px;
  background: #e6e7ed;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.totalInvestedIcon {
  composes: baseIcon;
  background: $colour-8488f9;
}

.currentMonthlyIncomeIcon {
  composes: baseIcon;
  background: #23c76a;
}

.alignedColumn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;
  align-content: center;
  align-self: center;
  align-items: center;
}

.content {
  height: 100%;
}

.fullHeightRow {
  min-height: 5.5rem;
}

.barChart {
  margin-top: 1rem;
}

.tabDiv {
  @media (max-width: $max-widthPhone) {
    padding: 0;
    margin-top: -1.8rem;
    margin-bottom: 1.8rem;
  }
}

.tabs {
  padding: 0;

  @media (min-width: $widthTablet) {
    padding: 0 15px 0 15px;
  }

  @include tablet {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.propertyDetailItem {
  text-align: left;
  padding-top: 0.5rem;
  font-size: 0.9rem;
}

.detailsIcon {
  height: 1rem;
}

.Status {
  color: $colour-blue-0;
  font-weight: 700;
  font-size: 1rem;
  float: left;
}

.title {
  margin-left: 1rem;
  font-size: 0.9rem;
}

.itemCount {
  padding: 0 0.5rem 0 0.5rem;
}

.moreInformation {
  height: 10px;
  min-height: 10px;
}

.container {
  margin: 0 !important;
}

.loadingOverlay {
  position: absolute; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(
    255,
    255,
    255,
    0.6
  ); /* $colour-shade-5 background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */

  .container {
    height: 100%;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.form {
  margin: 0 auto;
  max-width: 25rem;
}

.label {
  float: left;
  font-size: 0.7rem;
  color: $colour-shade-2;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.05rem;
  margin-bottom: 0.25rem;
}

.input {
  border-radius: 0;
  background-color: $colour-shade-0;
}

.button {
  background-color: $colour-blue-0;
  width: 100%;
  border-radius: 0;
  padding: 0.75rem;
  font-weight: 600;
  margin-top: 0.5rem;
}

.text {
  text-align: center;
  margin: 0 auto;
  color: $colour-shade-2;
  font-size: 0.85rem;
  margin-top: 1rem;
  justify-content: center;
}

h1 {
  color: $colour-shade-5;
  margin-bottom: 1rem;
}

.loggedIn {
  word-break: break-all;
  color: $colour-shade-5;
}

.p {
  color: $colour-shade-5;
}

.link {
  color: $colour-blue-0;
}

.link:hover {
  cursor: pointer;
}

.searchBar {
  padding-bottom: 1rem;
}

.info {
  padding: 1rem;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: $colour-shade-0;
  // height: 100%;
  min-height: 7.6rem;

  .statistic {
    color: $colour-shade-5;
    padding: 1rem;
    font-size: 2.2rem;
  }

  .roiHeader {
    background-color: #afc7a8;
    color: $colour-shade-0;
    padding: 0.6rem;
    font-size: 1.4rem;
  }

  .yieldHeader {
    background-color: #fcbd63;
    color: $colour-shade-0;
    padding: 0.6rem;
    font-size: 1.4rem;
  }

  .investmentHeader {
    background-color: #e06963;
    color: $colour-shade-0;
    padding: 0.6rem;
    font-size: 1.4rem;
  }

  .profitHeader {
    background-color: #7f9aff;
    color: $colour-shade-0;
    padding: 0.6rem;
    font-size: 1.4rem;
  }
}

.recentAddedPropertySection {
  .header {
    padding: 1rem;
    background-color: #00bfa5 !important;
    color: $colour-shade-0;
    text-align: left;
    font-size: 1.4rem;
    margin-bottom: 1rem;

    .date {
      font-size: 0.8rem;
    }
  }

  .body {
    background-color: $colour-shade-0;
    padding: 1rem;
  }
}

.yourPropertiesSelectSection {
  margin-top: 1rem;
  margin-bottom: 1rem;

  .header {
    padding: 1rem;
    background-color: #ff6e40 !important;
    color: $colour-shade-0;
    text-align: left;
    font-size: 1.4rem;

    .desc {
      font-size: 0.8rem;
    }
  }

  .body {
    background-color: $colour-shade-0;
    padding: 1rem;
  }
}

.marginTop {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.header {
  font-size: 0.75rem;
  font-weight: 500;
  text-align: left;
  text-transform: uppercase;
  color: $colour-shade-3;
  letter-spacing: 1px;
}

.value {
  display: block;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
}

.annualIncomeAndExpenditure,
.totalEquity {
  margin-top: 1rem;
  padding: 1rem;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: $colour-shade-0;

  @media (max-width: $max-widthSmallDesktop) {
    margin-top: 0px;
  }
}

.rowEqHeight {
  // margin-bottom: 1rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  @media (max-width: $max-widthSmallDesktop) {
    margin: 0px;
  }
}

.icon {
  padding: 0.65rem;
  background-color: red;
  border-radius: 10%;

  display: block;
  height: 60px;
  width: 60px;
  line-height: 60px;
}

.baseIcon {
  display: block;
  width: 40px;
  height: 40px;
  background: #e6e7ed;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.totalInvestedIcon {
  composes: baseIcon;
  background: $colour-8488f9;
}

.currentMonthlyIncomeIcon {
  composes: baseIcon;
  background: #23c76a;
}

.alignedColumn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;
  align-content: center;
  align-self: center;
  align-items: center;
}

.content {
  height: 100%;
}

.fullHeightRow {
  min-height: 5.5rem;
}

.barChart {
  margin-top: 1rem;
}

.tabDiv {
  .annualIncomeAndExpenditure {
    .header {
      padding-bottom: 1rem;
    }
  }

  @media (max-width: $max-widthSmallDesktop) {
    padding: 0 15px 15px 15px;
  }

  @media (max-width: $max-widthSmallDesktop) {
    padding: $paddingMobileCards;
  }
}

.status {
  &:hover {
    cursor: pointer;
  }
}

.viewAll {
  color: $colour-blue-0;
  font-size: 0.85rem;

  &:hover {
    cursor: pointer;
  }
}

.error {
  margin-bottom: 1rem;
}

.zooplaLinks {
  background-color: $colour-shade-0;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  height: 100%;
  margin-left: 0.1rem;
  margin-bottom: 1rem;
  margin-right: 0;

  .links {
    margin-top: 1rem;
  }

  .zooplaLogo {
    max-width: 8rem;
    margin: 2rem;
    float: right;
  }
  a {
    padding-bottom: 1rem;
    text-decoration: underline;
  }
}

.auditHighlight {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}
