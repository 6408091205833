@import "../../../global/stylesheets/colours";

.formattedAddress {
  color: $colour-shade-5;
  font-size: 0.8rem !important;

  p {
    margin: 0;
    padding: 0;
    font-size: 0.9rem;
    color: $colour-shade-5;
    line-height: 1.2rem;
  }

  span {
    text-transform: uppercase;

    &:first-letter {
      text-transform: uppercase;
    }

    &.line1,
    &.postcode {
      text-transform: uppercase;
    }
  }
}
