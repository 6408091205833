@import "../../../../global/stylesheets/colours";
@import "../../../../global/stylesheets/mediaConfig";

.row {
  display: flex;
  .autoCol {
    flex: 0 0 auto;
  }

  .col {
    flex: 100%;
    justify-self: flex-end;
    margin-left: 0;
    text-align: right;
    //margin-left: 0;
  }
}

.anchor {
  &:hover {
    text-decoration: none;
  }
}

.li {
  display: none;
  padding: 1rem;
  text-align: left;
  color: $colour-b0bac9;

  @include tablet {
    display: flex;
    justify-content: left;
  }

  img {
    padding-right: 0.8rem;
    display: inline-block;
    margin-bottom: 0.1rem;
  }

  &.active {
    color: $colour-blue-0;
    border-left: 2.5px solid $colour-blue-0;
    background-color: $colour-cyan-0;
  }

  &:hover {
    color: $colour-blue-0;
    background-color: $colour-cyan-0;
    cursor: pointer;
  }

  .expand,
  .inline {
    display: inline-block;
  }

  .expand {
    padding-left: 0.4rem;

    img {
      max-width: 0.75rem;
      opacity: 0.5;
      padding: 0;
    }

    svg {
      fill: #b0bac9;

      &:hover {
        fill: #0085ca;
      }
    }
  }
}

.mobileLi {
  display: flex;
  justify-content: left;

  @include tablet {
    display: none;
  }

  &.active {
    color: $colour-blue-0;
    border-left: 2.5px solid $colour-blue-0;
    background-color: $colour-cyan-0;
  }
}

.circle {
  position: relative;
  border-radius: 100px;
  background-color: $color-2e5bff;
  text-align: right;
  padding: 0.1rem .6rem;
  font-size: .7rem;
  color: white;
}

.inline {
  padding: 0.5rem 0;
  min-width: 8rem;
}

.expand {
  position: absolute;
  left: 8.5rem;
  min-width: 2rem;
  min-height: 2.5rem;
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;

  img {
    margin: 0 auto;
  }
}

.isSmall {
  justify-content: center;

  .row {
    display: block;
  }
}

.isHidden {
  display: none !important;
}
