@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/_mediaConfig";

.filters {
  display: flex;
  padding-bottom: 1rem;

  .addButton {
    flex: 0%;
    justify-self: flex-end;
  }
}

.dropdown {
  display: block;
  width: 100%;
  margin-right: 1rem;

  @include tablet {
    flex: 0 10%;
  }
}

.container {
  display: flex;

  .list {
    flex: 0 34%;
    border-right: 1px solid #F0F2F6;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100vh;
  }

  .content {
    flex: 0%;
    //height: calc(100vh - 77.83px);
    overflow: scroll;
    overflow-x: hidden;
  }
}

.row {
  display: flex;

  .addButton {
    flex: 0%;
    align-self: center;

    button {
      width: 100%;
    }
  }

  .search {
    flex: 0 80%;
    padding:0 15px;
  }

  .filterButton {
    align-self: center;
  }
}

.filters1 {
  padding: 1rem;
}