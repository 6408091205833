@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.page {
  max-width: 100%;
  background-color: $colour-shade-0;
  text-align: left;
  position: relative;
  width: 100%;
}

.row {
  display: flex;

  .column {
    flex: 0%;
    align-self: center;
  }

  .fullColumn {
    flex: 100%;
    align-self: center;
  }

  .autoColumn {
    flex: 0 0 auto;
    align-self: start;
    padding-right: .5rem;
  }
}

.detailsColumn {
  composes: column;
  flex: 0 100% !important;
  @include tablet {
    flex: 0% !important;
  }
}

.showPopup {
  display: inline;
  margin-left: 0.2rem;
}

.popup {
  display: inline;
  margin-left: 0.2rem;

  @include tablet {
    display: none;
  }
}

.topRow {
  composes: row;
}

.actionsColumn {
  composes: column;
  justify-content: flex-end;
  display: flex;
  flex: 0 30% !important;

  @include tablet {
    padding: 0 15px;
  }
}

.dashboard {
  composes: page;
  box-shadow: none;
  border: 1px solid $colour-cyan-0;
  max-width: none;

  &:hover {
    cursor: pointer;
  }
}

.addButton {
  align-self: center;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;

  img {
    height: 1.6rem;
    align-self: center;
  }

  button {
    font-size: 0.9rem;
    margin: 0.2rem;
    float: right;
  }

  @media (max-width: $max-widthTablet) {
    display: none;
  }

  @media (min-width: $min-widthLargeDesktop) {
    margin: 0;
  }

  button {
    position: relative;
    right: 2rem;
  }
}

.image {
  background-color: $colour-e4e4e4;
  background-size: contain;
  background-position: center;
  padding: 1rem;
  height: 5rem;
  align-self: center;
  margin-right: 0.4rem;
  flex: 0 30%;
  max-width: none;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: $widthTablet) {
    display: inline;
  }
}

.title {
  color: $colour-blue-0;
  font-weight: 700;
  font-size: 0.8rem;
  text-transform: uppercase;

  display: block;

  @media (min-width: $widthTablet) {
    display: inline;
  }
}

.propertyName {
  color: $colour-8c8c8c;
  font-weight: 400;
  font-size: 0.8rem;
}

.propertyType {
  font-size: .8rem;
}

.details {
  flex: 0%;
}

.addressContainer {
  composes: column;
  padding: 0;
  display: none;

  @include tablet {
    display: block;
  }
}

.address {
  font-weight: 700;
  font-size: 12px !important;
}

.address p {
  font-weight: 700;
  font-size: 14px !important;
}

.address span {
  @media (min-width: $widthTablet) {
    font-size: 1rem !important;
  }
}

.detailsDiv {
  margin-left: 0px;
  margin-right: 0px;
}

.editIcon,
.actionIcon {
  height: 2rem;
}

.addIcon,
.actionIcon,
.editIcon {
  &:hover {
    cursor: pointer;
  }
  margin-right: 0.4rem;
}

.addIcon {
  composes: actionIcon;
  display: none;
  margin-right: 0;
  margin: 0.1rem;

  @media (max-width: $max-widthTablet) {
    display: inline;
  }
}

.actionButtons {
  align-self: center;
  max-width: 20% !important;
  margin-right: 12rem;
  margin-top: -0.5rem;
  @media (max-width: $max-widthPhone) {
    margin-top: 1rem;
  }

  @media (max-width: $max-widthTablet) {
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-right: 1rem;
    margin-top: -1rem;
  }
}

.desktopButtons {
  @media (max-width: $max-widthTablet) {
    display: none;
  }
  @media (min-width: $min-widthLargeDesktop) {
    margin: 0;
  }
}

.actionButton {
  position: absolute;
  right: 0px;
  top: 0;
}