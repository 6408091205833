@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/styling";

.breadcrumbs {
    ul {
        list-style: none;
        font-size: .8rem;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 0;

        @include tablet {
            font-size: 1rem;
        }

        li {
            font-weight: bold;
            text-transform: uppercase;
            color: $colour-blue-1;
            display: inline-block;

            .link {
                cursor: pointer;
            }

            .seperator {
                padding: 0 .75rem;
            }
        }
    }
}