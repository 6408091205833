@import "../../../../global/stylesheets/mediaConfig";
@import "../../../../global/stylesheets/colours";

.imageGallery {
  position: relative;

  .actions {
    position: absolute;
    z-index: 1;

    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    line-height: 1;
    padding: 10px 20px;
    left: 0;
    top: 0;
  }
}

.syncfusionDropdown {
  background-color: transparent !important;
  padding: 0 !important;
  margin: 0 !important;
  font: inherit;

  ul {
    background-color: white !important;
  }
}