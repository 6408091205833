@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/mediaConfig";

.page {
  margin: 0 auto;
  background-color: $colour-shade-0;
  padding: 1rem;
}

.list {
  width: 100%;
}

.infiniteScroll {
  width: 100%;
}

.SearchBarDiv {
  margin-left: 15px;
  padding-bottom: 0.5rem;
  padding-right: 2.5rem;
}

.filter {
  padding-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: 15px;

  .filterIcon {
    flex: 0 5%;
    align-self: center;
    display: none;

    @include tablet {
      display: inline-block;
    }
  }

  .dropdown {
    align-self: center;
    -webkit-flex-basis: 0;
    flex-basis: 0;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    max-width: 85%;
    display: block;
    margin-right: auto;
    margin-left: auto;
    flex: 0%;

    @include tablet {
      flex: 0 30%;
      margin-bottom: 0;
    }
  }

  .addDiv {
    width: 100%;
    margin-left: 1rem;

    @include tablet {
      width: auto;
      margin: 0 2rem 0 0;
    }
  }

  .showOnlyMyContacts {
    flex: 0 100%;
    display: none;

    @include fullHD {
      display: inline-block;
      width: auto;
      flex: 0 35%;
    }
  }
}

.rightFilter {
  display: flex;
  flex: 0 20%;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
  justify-items: flex-end;

  @include tablet {
    flex: 0%;
  }
}

.addProperty {
  color: $colour-shade-0;
  background-color: $colour-blue-0;
  border: none;
  padding: 0.5rem;
  border-radius: 6px;
  padding-left: 0rem;
  padding-right: 1rem;
  width: 10rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
}

.addPropertyText {
  color: $colour-shade-0;
  padding: 0.65rem;
  padding-right: 0rem;
}

.addUserIcon {
  margin-right: 0.5rem;
}
