@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/_mediaConfig";

.page {
  padding: 0 0rem;

  @include tablet {
    padding: 0;
  }


  .toggleChatList {
    width: 100%;
    display: block;
    flex: 0 100%;

    @include tablet {
      display: none;
    }
  }



  .row {
    padding: 1rem;
    display: flex;

    .startChat {
      flex: 0 20%;
      align-self: center;

      button {
        width: 100%;
        height: 100%;
      }
    }

    .search {
      flex: 0%;
      margin-right: 1rem;

      input {
        border: 1px solid #d5deff;
      }
    }
  }
}

.chatUl {
  list-style: none;
  color: black;
  text-align: left;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

.noChats {
  text-align: center;
}

.mobileHeader {
  p {
    color: black;
  }

  @include tablet {
    display: none;
  }
}

.noChatSelected {
  p {
    color: black;
    margin-top: 1rem;
  }
  img {
    margin-top: 1rem;
    display: inline-block;
    width: 4rem;
    max-width: 4rem;
    text-align: center;
  }
}

.toggle {
  flex: 0 5%;
  align-self: center;
  margin-right: 0.5rem;
}

.mobile {
  @include tablet {
    display: none;
  }
}

.desktop {
  display: none;

  @include tablet {
    display: block;
  }
}

.list {
  flex: 0 100% !important;

  @include mobileLandscape {
    flex: 0 36% !important;
  }

  @include fullHD {
    flex: 0 28% !important;
  }
}

.details {
  flex: 0%;
}