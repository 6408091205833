@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/styling";

.container {
  position: relative;

  .column {
    min-width: 2rem;
    max-width: 10%;
  }
}

.icon {
  margin-left: 0.5rem;
}

.row {
  display: flex;

  .col {
    flex: auto;
    align-self: center;
  }
}

.add {
  img {
    cursor: pointer;
  }
}

.viewAll {
  color: #0085ca;
  font-size: 0.6rem;
  margin-top: 0.6rem;
  margin-left: 0.1rem;
  display: block;

  @include tablet {
    margin: 0;
    display: inline-block;
    width: auto;
    font-size: 0.7rem;
  }
}

.noDocuments {
  h3 {
    font-size: 1.2rem;
    margin: 0;
  }

  p {
    font-size: 0.8rem;
    color: #8798ad;
    margin: 0;
  }
}

.topRight {
  display: block;
  position: absolute;
  right: 0;
  font-size: 0.8rem;
  color: #0085ca;
  margin-right: 1rem;
}

.addButton {
  position: relative;
  width: 100px;
  height: 30px;
  font-weight: bold;
  color: white;
  background: #0085CA 0% 0% no-repeat padding-box;
  border: 1px solid #0085CA;
  border-radius: 20px;
  margin: 0;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 0.5rem;
  border: none;
  align-items: stretch;
  //display: flex;
  font-size: 13px;
}