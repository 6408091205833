@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.sidebar {
  padding: 2rem;
  position: fixed !important;
  max-width: 30rem;
  width: 100%;
  z-index: 9999999999999999 !important;
}

.modalBody {
  color: $colour-shade-5;
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.4rem 1rem 0.4rem 1rem;
}

.modalBodyOutstandingChanges {
  color: $colour-shade-5;
  text-align: left;
}

.modalHeader {
  color: $colour-shade-2;
  font-weight: 500;
  margin-bottom: 0px !important;
}