@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/mediaConfig";

.form {
  flex: 0 75%;
  padding: 1rem !important;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  background-color: $colour-shade-0;
  padding: 0 10px;
  margin: 10px;

  @include desktop {
    flex: auto;
  }
}

.page {
  margin: 0 auto;

  .content {
    margin-top: 1rem;

    @include tablet {
      margin-top: 0;
    }
  }

  .row {
    //display: block;
    align-items: flex-start;
    flex-direction: column-reverse;
    display: flex;

    @include tablet {
      display: flex;
      margin: 0 10px;
      margin-bottom: .5rem;
      flex-direction: initial;
    }

    .col {
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
      background-color: $colour-shade-0;
      flex: auto;
      padding: 0 10px;
      margin: 10px;   
    }

    .imageCol {
      width: 100%;
      padding: 0;
      flex: 0 50%;

      @include tablet {
        display: flex;
        width: auto;
      }

      @include fullHD {
        flex: 0 25%;
      }
    }
  }
}

.imageGallery {
  width: 100%;
  z-index: 1;
}