@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.modal {
  max-width: 25rem;
  max-height: 50rem;
  border: 2px solid $colour-shade-5;
  color: $colour-shade-4;

  p {
    color: $colour-shade-4;
  }

  hr {
    margin: 0;
    width: 100%;
  }
}

.button {
  background-color: $colour-blue-0;
  width: 20%;
  border-radius: 0;
  padding: 0.75rem;
  font-weight: 600;
  margin-top: 0.5rem;
  border: none;
  margin-left: 1rem;
  border-radius: 6px;

  @media (max-width: $max-widthPhone) {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.cancelButton {
  composes: button;
  background-color: $colour-3f64e899;
}

.modalBody {
  p {
    color: $colour-shade-4;
  }

  color: $colour-shade-4;
  text-align: left;
  padding: 1rem;
  padding-top: 0;
  padding-bottom: 0;

  .title {
    color: $colour-shade-4;
    font-weight: bold;
    font-size: 0.8rem;
  }

  .sales {
    display: block;
    width: 100%;
    color: $colour-shade-4;
    font-weight: bold;
    font-size: 1rem;
    padding-bottom: 0;
    margin-bottom: 0.25rem;
  }

  .information {
    padding-top: 1rem;
    font-size: 0.75rem;
    margin-bottom: 0;
    width: 100%;

    p {
      padding: 0;
      margin: 0;
    }
  }

  .address {
    p {
      color: $colour-6161ac;
    }
  }

  .salesList {
    padding-top: 0.2rem;
    width: 100%;
    list-style: none;

    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;

    padding-bottom: 0.2rem;

    li {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid $colour-shade-0;

      &:last-child {
        border-bottom: none;
      }

      .price {
        color: $colour-shade-4;
        text-align: right;
      }

      .valuationStatus {
        font-weight: bold;
      }
    }

    .action {
      font-weight: bold;
    }
  }
}

.heading {
  font-size: 1rem;
  font-weight: bold;
  color: $colour-shade-4;
  padding-bottom: 0.2rem;
  margin-bottom: 0;
}

.buttonDiv {
  text-align: right;

  @media (min-width: $min-widthTablet) {
    width: 600px;
  }
}
