@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/mediaConfig";

.avatarLarge {
  margin: auto;
  width: 8rem !important;
  height: 8rem !important;
  background-color: $colour-c4d0f6 !important;
  font-size: 4rem !important;
  cursor: pointer;

  img {
    width: 8rem !important;
    height: 8rem !important;
  }
}

.avatar {
  margin: auto;
  width: 2.5rem !important;
  height: 2.5rem !important;
  background-color: $colour-c4d0f6 !important;
  font-size: 1rem !important;
  cursor: pointer;

  img {
    width: 2.5em !important;
    height: 2.5rem !important;
  }
}

.profileImageLarge {
  max-width: 8rem;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  margin: 0 auto;
  background-size: contain;
}

.profileImage {
  border-radius: 11rem;
  max-width: 2.5rem;
  height: 2.5rem;
  width: 2.5rem;
  background-size: contain;
}