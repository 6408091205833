@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.menu {
  width: 100%;
}

.disabled {
  opacity: .5;
  pointer-events: none;
}

.menu {
  @include tablet {
    left: -8rem !important;
    top: 2rem !important;
  }
}

.icon {
  text-align: left;
}

.row {
display: flex;

  .column {
    flex: 0 0 auto;
    align-self: center;
  }
}