@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";

.input {
  background-color: $colour-shade-0;
  border: 1px solid #d5deff;
  width: 85%;
  margin-bottom: 1rem;
  padding: 0.65rem;
  background: url("../../../images/icons/ic_search_24px.png") no-repeat scroll
    11px 11px;
  padding-left: 2.8rem;
  @media (max-width: $max-widthPhone) {
    width: 80%;
  }
}

.searchButton {
  width: 15%;
  padding: 0.72rem;
  margin-left: -1rem;
  display: inline-block;
  background-color: $colour-2ecc71;
  color: $colour-shade-0;
  border-radius: 0px 6px 6px 0px;
  border: 0px solid;

  @media (max-width: $max-widthPhone) {
    width: 20%;
    padding: 0.72rem 0 0.72rem 0;
  }
}

.searchBar {
  margin-right: 0.5rem;
}

.searchButtonDisabled {
  composes: searchButton;
  pointer-events: none;
  background-color: $colour-shade-0;
}

.image {
  padding: 0;
  background-color: $colour-e4e4e4;
  color: $colour-blue-1;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.8rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-direction: column;
  flex-direction: column;
  text-align: center;
  background-size: cover;
}

.resultsAmount {
  padding: 1rem;
  text-align: center;
  text-decoration: underline;
}
