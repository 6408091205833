@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/styling";

.circle {
  position: relative;
  border-radius: 100px;
  background-color: $color-2e5bff;
  text-align: right;
  padding: 0.1rem .6rem;
  font-size: .7rem;
  color: white;
}