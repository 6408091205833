@import "../../../../global/stylesheets/mediaConfig";
@import "../../../../global/stylesheets/colours";

.content {
  padding: 0 2rem 0 1rem;
}

.row {
  display: flex;
  justify-content: flex-end;
  .column {
    flex: 0%;
  }

  .iconColumn {
    justify-self: flex-end;
    flex: 0 0 auto;
    align-self: center;
  }
}

.tabRow {
  justify-content: flex-start !important;
  margin-bottom: 0.5rem;
}

.icon {
  margin-right: 1rem;
  display: inline-block;

  .text {
    display: none;

    @include tablet {
      display: inline-block;
    }
  }

  img {
    margin-right: 0.2rem;
  }

  &:hover {
    cursor: pointer;
  }
}

.grid {
  width: 100%;
  padding-top: 1rem;
  padding-right: 1rem;
}

.dropdown {
  display: flex;
  min-width: 5rem;
  flex-direction: row;
  justify-content: left;

  @include mobileLandscape {
    flex-direction: row;
    justify-content: left;
  }
}

.gridRow {
  margin-top: 0.25rem;
}

.gridColumn {
  width: 100%;
}

.tabNav {
  width: 100%;
  margin: 0 auto;

  @include mobileLandscape {
    margin: unset;
  }
}

.searchInput {
  display: flex;
  flex: 0 100%;
  margin-left: 1rem;

  @include mobileLandscape {
    flex: 0 50%;
    min-width: 12rem;
    max-width: 12rem;
  }

  @include tablet {
    min-width: 12rem;
    max-width: 15rem;
  }
}

.dateRangePicker {
  display: flex;
  flex: 0 100%;
  margin-left: 1rem;

  @include mobileLandscape {
    flex: 0 50%;
    min-width: 12rem;
    max-width: 12rem;
  }

  @include tablet {
    min-width: 12rem;
  }
}

.marginTop {
  margin-top: 1rem;
}
