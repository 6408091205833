@import "../../../../global/stylesheets/colours";
@import "../../../../global/stylesheets/mediaConfig";

.listItem {
  margin-bottom: 0.8rem;
  display: inline-block;
}

.propertyListItem {
  background-color: $colour-shade-0;
  border: 1px solid #DCDCDC;
  margin-bottom: 0.8rem;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  }

  @media (min-width: $max-widthPhone) {
    margin: 0;
  }
}

.heading {
  padding: 0.2rem;
  text-align: left;
}

.columns {
  text-align: left;
}

.address {
  &:hover {
    cursor: pointer;
  }

  img {
    display: inline;
  }
}

.label {
  color: $colour-b0bac9;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.8rem;
}

.item {
  padding: 0.4rem 0 0.4rem 0;
}

.addressItem {
  composes: item;
  min-width: 7rem;
  @media (max-width: $max-widthSmallDesktop) {
    padding: 0rem 0 0rem 0;
    display: none;
  }
}

.itemDesktop {
  composes: item;

  @media (max-width: 1199px) {
    padding: 0rem 0 0rem 0;
    display: none;
  }
}

.img {
  background-color: $colour-e4e4e4;
}

.title {
  color: $colour-blue-1;
  font-weight: 700;
}

.stats {
  text-align: left;
}

.buttons {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 2rem;
}

.paddedContent {
  padding: 0.75rem;

  @media (max-width: $max-widthPhone) {
    padding: 0;
  }
}

.statValue {
  font-size: 1.25rem;
  height: 40px;
  margin-top: 0.4rem;
  margin-left: -1.5rem;
  @media (max-width: 1536px) {
    width: 2.5rem !important;
    margin-left: -0.2rem;
    padding: 0;
    font-size: 1rem;
  }
}

.propertyName {
  color: $colour-8c8c8c;
  font-weight: 400;
  font-size: 0.85rem;
  margin-bottom: 0.5rem;
}

.bottomRight {
  position: absolute;
  bottom: 20px;
  right: 25px;
  font-size: 0.6rem;
  color: $colour-shade-5;

  @media (max-width: $max-widthPhone) {
    right: 2.3rem;
  }
}

.dropdown {
  padding-left: 15px;

  @media (max-width: $max-widthPhone) {
    padding-right: 0px;
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.2;
}

.actionIcon,
.pointer,
.deleteIcon {
  &:hover {
    cursor: pointer;
  }
}

.percentageReturns {
  width: 100%;

  @media (max-width: $max-widthPhone) {
    padding-bottom: 0;
    padding-top: 0;
  }
}

.imageDiv {
  margin: 0;

  @media (max-width: $max-widthTablet) {
    padding: 0;
    margin: 0;
  }
}

.gridDiv {
  margin: 0;
  padding-left: 5px;
  padding-right: 5px;
}

.edit {
  &:hover {
    cursor: pointer;
  }
}

.ItemButtons {
  padding-right: 1rem;

  @media (max-width: $max-widthPhone) {
    padding-right: 1.5rem;
    margin-left: -1.5rem;
  }

  @media (min-width: $min-widthPhoneLandscape) {
    padding-right: 1rem;
    margin-left: -1.5rem;
  }

  @media (min-width: $min-widthDesktop) {
    padding-right: 2.5rem;
    margin-left: -5rem;
  }

  @media (min-width: $min-widthLargeDesktop) {
    padding-right: 1rem;
    margin-left: -1rem;
  }
}

.returnIcon {
  &:hover {
    cursor: pointer;
  }
}

.createdBy {
  position: absolute;
  top: 5px;
  right: 25px;
  font-size: 0.75rem;
  color: $colour-shade-5;
  background-color: $colour-eeeeee4a;
}

.pointer {
  @media (min-width: $max-widthTablet) {
    margin-left: 3rem;
  }

  @media (min-width: 1536px) {
    margin-left: 0;
  }
}

.actionIcon {
  @media (min-width: $max-widthTablet) {
  }
}

.success {
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.58);
  color: $colour-shade-0;
  letter-spacing: 1px;
}

.propertyName {
  display: none;

  @media (min-width: $widthTablet) {
    display: inline-block;
  }
}

.propertyNameBtn {
  @media (min-width: $widthTablet) {
    display: none;
  }

  text-align: initial;

  label {
    margin-bottom: 0;
  }

  width: auto;
  font-weight: 600;
  display: block;
  margin: 0 auto;
  float: none;
  background-color: $colour-shade-0;
  border: none;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  border-radius: 300px;
  font-size: 0.8rem;
  padding: 0.4rem;
  margin-top: -0.75rem;

  color: $colour-8c8c8c;
  letter-spacing: 0.5px;
}
