@import '../../../global/stylesheets/colours';
@import '../../../global/stylesheets/mediaConfig';
@import '../../../global/stylesheets/styling';
@import '../../../global/stylesheets/functions';
@import '../../../global/stylesheets/components/button/index.scss';

.buttons {
    width: 100%;
    //margin-top: 1rem;
    display: flex;

    &.left {
        justify-content: flex-start;
    }

    &.right {
        justify-content: flex-end;
    }

    &.center {
        justify-content: center;
    }    
}
