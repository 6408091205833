@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/styling";

.mainTextRow {
  @include hoverOver();
  text-align: left;
}

.leftText {
  padding-right: 2px;
  font-size: 1.2rem;
  text-align: center;
}

.value {
  color: #2e384d;
  font-size: 1.2rem;
  padding-right: 2px;
  text-align: center;
}

.tooltip {
  padding-right: 2px;
}

.endingSoon {
  //float: left;
  color: white;
  background-color: #e74c3c;
  border-radius: 1rem;
  font-size: 1rem;
  width: 100px;
  height: 25px;
  text-align: center;
  margin: auto;
}
