@import "../../../global/stylesheets/mediaConfig";
@import "../../../global/stylesheets/colours";
@import "../../../global/stylesheets/styling";

.dropdownButton {
  border: none;
  padding: .4rem; 
  color: white;
  background-color: $colour-green-0;

  .icon {
    width: .75rem;
  }

  .text {
    display: none;

    @include fullHD {
      display: inline-block;
    }
  }
}