@import "../../../../../../global/stylesheets/mediaConfig";
@import "../../../../../../global/stylesheets/colours";

.categoryPill {
  background-color: #0085ca;
  padding: 0;
  color: white;
  border-radius: 2rem;
  display: inline-block;
  text-align: center;
  padding: 0 1rem;
  width: 100%;
  font-size: 0.8rem;
}